import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AssetBalance } from 'metaverse-js/lib/proto/model/asset';
import { UserEntity } from 'user-identity-js/lib/entities';
import { PaymentMethodEntity } from 'skychain-p2p-trading-js/lib/entities';
import { AccountBalance } from 'skychain-game-center-js/lib/dto/account.dto';
interface UserState {
	userAssetBalances: AssetBalance[];
	userProfile: UserEntity | undefined;
	paymentMethods: PaymentMethodEntity[];
	gameBalance: AccountBalance | null;
}

const initialState = {
	userAssetBalances: [],
	userProfile: undefined,
	paymentMethods: [],
	gameBalance: null,
} as UserState;

const userSlice = createSlice({
	name: 'authSlice',
	initialState,
	reducers: {
		setUserAssetBalances: (state, action: PayloadAction<AssetBalance[]>) => {
			state.userAssetBalances = action.payload;
		},
		updateAssetBalance: (state, action: PayloadAction<AssetBalance>) => {
			const assetBalance = action.payload;
			const index = state.userAssetBalances.findIndex(
				(token) => token.assetSymbol === assetBalance.assetSymbol
			);
			if (index !== -1) {
				state.userAssetBalances[index] = assetBalance;
			} else {
				state.userAssetBalances.push(assetBalance);
			}
		},
		setUserProfile: (state, action: PayloadAction<UserEntity>) => {
			state.userProfile = action.payload;
		},
		setPaymentMethods: (
			state,
			action: PayloadAction<PaymentMethodEntity[]>
		) => {
			state.paymentMethods = action.payload;
		},
		setGameBalance: (state, action: PayloadAction<AccountBalance>) => {
			state.gameBalance = action.payload;
		},
		resetUser: (state) => {
			state.userAssetBalances = [];
			state.userProfile = undefined;
			state.paymentMethods = [];
			state.gameBalance = null;
		},
	},
});

export const {
	setUserAssetBalances,
	updateAssetBalance,
	setUserProfile,
	setPaymentMethods,
	setGameBalance,
	resetUser,
} = userSlice.actions;
export default userSlice.reducer;
