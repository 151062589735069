import React, { useEffect, useState } from 'react';
import Authentication from '.';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useToast } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import useCaptcha from '../../hooks/useCaptcha';
import useGoogleRecaptcha from '../../hooks/useGoogleRecaptcha';

const formFields = [
	{
		key: 1,
		label: 'Email',
		name: 'email',
		type: 'text',
		placeholder: 'Enter your email...',
		required: true,
	},
];

const ForgotPassword = () => {
	const { register, handleSubmit, setValue, watch, getValues, reset } =
		useForm();
	const toast = useToast();
	const [loading, setLoading] = useState(false);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);

	const handleForgotPassword = async (value: any) => {
		if (!userIdentity || loading) return;
		try {
			setLoading(true);
			const res = await userIdentity.forgotPassword(
				value.email,
				value.captchaToken
			);
			if (res) {
				toast({
					title: 'Success',
					description: 'Please check your email to reset password',
					status: 'success',
				});
				reset();
			}
		} catch (error: any) {
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Unknown error',
				status: 'error',
			});
		} finally {
			setLoading(false);
		}
	};
	// const captchaToken = watch('captchaToken');
	// const { validateCaptcha } = useCaptcha(
	// 	captchaToken,
	// 	getValues(),
	// 	handleForgotPassword
	// );

	const { handleReCaptchaVerify } = useGoogleRecaptcha(handleForgotPassword);

	const onSubmit = async (value: any) => {
		handleReCaptchaVerify(value);
	};

	return (
		<Authentication
			title={'Forgot password'}
			titleButton={'Submit'}
			subtitle={
				<>
					Please enter the email you use to sign in to{' '}
					<Link style={{ color: '#FFBF1A', marginLeft: '4px' }} to="/">
						Krypto8
					</Link>
				</>
			}
			formFields={formFields}
			register={register}
			onSubmit={handleSubmit(onSubmit)}
			// setValue={setValue}
			// isCaptcha
			loading={loading}
		/>
	);
};

export default ForgotPassword;
