export const USER = 'user';

export const paginationDefault = {
	page: 1,
	pageSize: 10,
};

export const METAGAME_URL = 'https://game.krypto8.com/';

export const TELEGRAM_SUPPORT_URL = 'https://t.me/yeskrypto8';

export enum LANGUAGES {
	ENGLISH = 'English',
	VIETNAMESE = 'Tiếng Việt',
	KOREAN = '한국어',
}

export enum OrderDetailUI {
	SELL = 'Sell',
	BUY = 'Buy',
}

export const TRANSLATION = 'translation';
