import {
	Box,
	Flex,
	Show,
	Text,
	chakra,
	Img,
	Switch,
	useToast,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { ColumnsAdsTable, DataAdsTable } from './interface';
import BuySellTable from '../UI/BuySellTable';
import { CURRENCY } from '../../constants/cryptos';
import { AdOrderEntity } from 'skychain-p2p-trading-js/lib/entities/ad-order.entity';
import { convertOrderType } from '../../utils/ads';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes/config';
import styled from '@emotion/styled';
import EditIcon from '../Icons/EditIcon';
import { AdOrderStatus } from 'skychain-p2p-trading-js/lib/dto';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import LoadingUI from '../UI/LoadingUI';
import { formatCurrency } from '../../utils';
import BigNumber from 'bignumber.js';
export const PrimaryText = chakra(Text, {
	baseStyle: {
		fontSize: {
			base: '14px',
			lg: '16px',
		},
		color: '#ffffff',
		lineHeight: '16px',
	},
});

export const SecondaryText = chakra(Text, {
	baseStyle: {
		fontSize: {
			base: '12px',
			lg: '14px',
		},
		color: '#737283',
		lineHeight: '14px',
	},
});

const StyledEditIcon = styled(EditIcon)`
	&:hover {
		cursor: pointer;
		path {
			fill: #ffffff;
		}
	}
`;
const StyledSwitch = styled(Switch)`
	.chakra-switch__track[data-checked] {
		background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%) !important;
	}
`;
export default function AdsData({
	ads,
	refresh,
	loading,
}: {
	ads: AdOrderEntity[];
	refresh?: () => void;
	loading?: boolean;
}) {
	const p2pTradingClient = useSelector(
		(state: RootState) => state.system.p2pTradingClient
	);
	const toast = useToast();
	const columns: ColumnsAdsTable[] = [
		{
			key: 'cryptos',
			title: 'Cryptos',
			isNumeric: false,
			align: 'start',
			render: (data: any) => (
				<Flex gap="10px" alignItems={'center'}>
					<Img src={CURRENCY[data['currency']]?.icon} />
					<Flex direction={'column'} gap={'5px'}>
						<PrimaryText>{CURRENCY[data['currency']]?.symbol}</PrimaryText>
						<SecondaryText>{CURRENCY[data['currency']]?.name}</SecondaryText>
					</Flex>
				</Flex>
			),
		},
		{
			key: 'amount',
			title: 'Amount',
			isNumeric: false,
			align: 'start',
		},
		{
			key: 'limit',
			title: 'Limit',
			isNumeric: false,
			align: 'start',
		},
		{
			key: 'types',
			title: 'Types',
			isNumeric: false,
			align: 'start',
			render: (data: any) => (
				<Text
					color={data['type']?.toLowerCase() === 'buy' ? '#03A66D' : '#CF304A'}
					fontSize={{ base: '14px' }}
					textTransform={'capitalize'}
				>
					{data['type']}
				</Text>
			),
		},
		{
			key: 'status',
			title: 'Status',
			isNumeric: false,
			align: 'center',
			render: (data: any) => (
				<Flex gap={'10px'} justifyContent={'center'}>
					{data.status}{' '}
					<StyledSwitch
						isChecked={data.online}
						onChange={(event: any) => {
							updateStatus(
								data.key,
								event.target.checked
									? AdOrderStatus.ONLINE
									: AdOrderStatus.OFFLINE
							);
						}}
					/>
				</Flex>
			),
		},
		{
			key: 'action',
			title: 'Action',
			isNumeric: true,
			render: (data: any) => (
				<Link to={`${ROUTES.P2P_ADS_EDIT.replace(':id', data['key'])}`}>
					<StyledEditIcon />
				</Link>
			),
		},
	];

	const dataTable: DataAdsTable[] = useMemo(() => {
		const data = ads.map((item: AdOrderEntity) => ({
			...item,
			key: item.id,
			currency: item.assetSymbol || '',
			amount: `${item.totalAmount} ${item.assetSymbol}`,
			limit: `${formatCurrency(
				new BigNumber(item.minAmount || 0)
					.multipliedBy(item.priceValue || 0)
					.toNumber()
			)} ${item.fiatSymbol} - ${formatCurrency(
				new BigNumber(item.maxAmount || 0)
					.multipliedBy(item.priceValue || 0)
					.toNumber()
			)} ${item.fiatSymbol}`,
			type: convertOrderType(item.type) as any,
			status: item.status
				? item.status.charAt(0).toUpperCase() + item.status?.slice(1)
				: '',
			online: item.status === AdOrderStatus.ONLINE,
			bank: 'bank',
		}));
		return data;
	}, [ads]);

	const updateStatus = async (id: string, status: AdOrderStatus) => {
		if (!p2pTradingClient || !id || !status) return;
		try {
			const res = await p2pTradingClient.changeStatusAdOrder(id, status);
			if (res?.id) {
				toast({
					title: 'Success',
					description: 'Update status successfully',
					status: 'success',
					duration: 3000,
					isClosable: true,
					position: 'top',
				});
				refresh && refresh();
			}
		} catch (error) {
			toast({
				title: 'Error',
				description: 'Update status failed',
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top',
			});
		}
	};

	return (
		<>
			{loading && <LoadingUI h="100px" />}
			<Box py={{ base: '10px', lg: '40px' }}>
				<Show above="sm">
					<BuySellTable
						dataTable={dataTable}
						columns={columns}
						isBorder
						isCollapse
						loading={loading}
					/>
				</Show>

				<Show below="sm">
					{dataTable.map((data) => (
						<AdsBlock key={data.key} data={data} updateStatus={updateStatus} />
					))}
				</Show>
			</Box>
		</>
	);
}

export const AdsBlock = ({
	data,
	updateStatus,
}: {
	data: DataAdsTable;
	updateStatus?: (id: string, status: AdOrderStatus) => void;
}) => {
	return (
		<Flex
			direction={'column'}
			gap={'20px'}
			py="20px"
			borderBottom={'1px solid #FFFFFF1E'}
			w={'100%'}
		>
			<Flex justifyContent={'space-between'} alignItems={'center'}>
				<Flex gap="10px" alignItems={'center'}>
					<Img src={CURRENCY[data['currency']]?.icon} />
					<Flex direction={'column'} gap={'5px'}>
						<PrimaryText>{CURRENCY[data['currency']]?.symbol}</PrimaryText>
						<SecondaryText>{CURRENCY[data['currency']]?.name}</SecondaryText>
					</Flex>
				</Flex>
				<Flex gap={'10px'} justifyContent={'center'}>
					{data.status}{' '}
					{data.status.toLowerCase() !== 'cancel' && (
						<StyledSwitch
							isChecked={data.online}
							onChange={(event: any) => {
								updateStatus &&
									updateStatus(
										data.key as string,
										event.target.checked
											? AdOrderStatus.ONLINE
											: AdOrderStatus.OFFLINE
									);
							}}
						/>
					)}
				</Flex>
			</Flex>

			<Flex justifyContent={'space-between'} alignItems={'center'} w={'100%'}>
				<Flex
					gap="10px"
					alignItems={'center'}
					justifyContent={'space-between'}
					w={'35%'}
				>
					<SecondaryText>Types:</SecondaryText>
					<Text
						color={data['type'] === 'buy' ? '#03A66D' : '#CF304A'}
						fontSize={{ base: '14px' }}
						textTransform={'capitalize'}
					>
						{data['type']}
					</Text>
				</Flex>
				<Flex
					gap="10px"
					alignItems={'center'}
					justifyContent={'space-between'}
					w={'50%'}
				>
					<SecondaryText>Amount:</SecondaryText>
					<PrimaryText>{data['amount']}</PrimaryText>
				</Flex>
			</Flex>

			<Flex justifyContent={'space-between'} alignItems={'center'} w={'100%'}>
				{/* <Flex gap="5px" alignItems={'center'} w={'35%'}>
					<Box w={'6px'} h={'6px'} borderRadius={'100px'} bg={'#737283'} />
					<SecondaryText>{data['bank']}</SecondaryText>
				</Flex> */}
				<Flex
					gap="10px"
					alignItems={'center'}
					justifyContent={'space-between'}
					w={'35'}
				>
					<SecondaryText>Limit:</SecondaryText>
					<PrimaryText>{data['limit']}</PrimaryText>
				</Flex>
			</Flex>
		</Flex>
	);
};
