import { Flex } from '@chakra-ui/react';
import React from 'react';
import { P2PTradingClient } from 'skychain-p2p-trading-js';
import { AdOrderType } from 'skychain-p2p-trading-js/lib/dto';
import { AdOrderEntity } from 'skychain-p2p-trading-js/lib/entities';
import TableAdManagement from '../../components/Account/AdManagement/TableAdManagement';
import TabsAdManagement from '../../components/Account/AdManagement/TabsAdManagement';
import Pagination from '../../components/UI/Pagination';
import { useAppSelector } from '../../redux/store';

const paginationDefault = {
	page: 1,
	pageSize: 10,
};

const AdManagementPage = () => {
	const p2pTradingClient = useAppSelector(
		(state) => state.system.p2pTradingClient
	);
	const [paging, setPaging] = React.useState(paginationDefault);
	const [type, setType] = React.useState<AdOrderType>(AdOrderType.BUY);
	const [total, setTotal] = React.useState(0);
	const [ads, setAds] = React.useState<AdOrderEntity[]>([]);

	const getAds = async (
		p2pTradingClient: P2PTradingClient,
		type: AdOrderType,
		paging: { page: number; pageSize: number }
	) => {
		try {
			const res = await p2pTradingClient.getAdOrders({ type }, paging);
			setAds(res['data']);
			if (res && res.meta && res.meta.totalRecord) {
				setTotal(res.meta.totalRecord);
			}
		} catch (error) {
			console.log(error);
		}
	};

	React.useEffect(() => {
		if (p2pTradingClient && type && paging) {
			getAds(p2pTradingClient, type, paging);
		}
	}, [p2pTradingClient, type, paging]);
	return (
		<div>
			<TabsAdManagement setType={setType} />
			<TableAdManagement ads={ads} />
			{total > paginationDefault.pageSize && (
				<Flex justifyContent={'center'} my={'10px'}>
					<Pagination
						page={paging.page}
						pagination={{
							total,
							pageSize: paging.pageSize,
						}}
						handleChangePage={(page) => {
							setPaging({ ...paging, page });
						}}
					/>
				</Flex>
			)}
		</div>
	);
};

export default AdManagementPage;
