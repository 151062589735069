import { Box, Flex, Text } from '@chakra-ui/react';
import { format, fromUnixTime } from 'date-fns';
import React from 'react';
import { AdOrderEntity } from 'skychain-p2p-trading-js/lib/entities';
import { CURRENCY } from '../../../constants/cryptos';
import { shortenAddress } from '../../../lib/shortenAddress';
import TableUI from '../../UI/TableUI';

const columns = [
	{
		key: 'time',
		title: 'Time',
	},
	{
		key: 'id',
		title: 'ID',
	},
	{
		key: 'currency',
		title: 'Currency',
	},
	{
		key: 'price',
		title: 'Price',
	},
	{
		key: 'amount',
		title: 'Amount',
	},
	{
		key: 'status',
		title: 'Status',
		render: (value: boolean) =>
			value ? (
				<Box color="#21FF0E">Successful</Box>
			) : (
				<Box color={'#FF0E39'}>Failed</Box>
			),
	},
];

const TableAdManagement = ({ ads }: { ads: AdOrderEntity[] }) => {
	const dataTable = React.useMemo(() => {
		const data: { [key: string]: any }[] = ads.map((item) => ({
			time: format(fromUnixTime(item.createdAt), 'dd/MM/yyyy'),
			id: shortenAddress(item.id),
			currency: (item.assetSymbol && CURRENCY[item.assetSymbol]?.name) || '',
			amount: `${item.totalAmount} ${item.assetSymbol}`,
			price: `${item.priceValue} ${item.fiatSymbol}`,
			status: item.systemStatus,
		}));
		return data;
	}, [ads]);

	return (
		<>
			<Box display={{ base: 'none', md: 'block' }}>
				<TableUI isBorder={true} columns={columns} dataTable={dataTable} />
			</Box>

			<Box display={{ base: 'block', md: 'none' }}>
				{dataTable.map((data, index) => {
					return (
						<Flex
							justifyContent={'space-between'}
							key={index}
							borderTop={'1px solid #FFFFFF1E'}
							p={'20px'}
						>
							<Box>
								{columns.slice(0, 3).map((col) => (
									<Flex key={col.key}>
										<Box w={'60px'} color={'#737283'} fontSize={'12px'}>
											{col.title}:{' '}
										</Box>
										<Box fontSize={'14px'}>{data[col.key]}</Box>
									</Flex>
								))}
							</Box>
							<Box>
								{columns.slice(3, columns.length).map((col) => (
									<Flex key={col.key}>
										<Box w={'60px'} color={'#737283'} fontSize={'12px'}>
											{col.title}:{' '}
										</Box>
										<Box fontSize={'14px'}>
											{data[col.key] === 'success' ? (
												<Text color={'#21FF0E'}>Successful</Text>
											) : data[col.key] === 'failed' ? (
												<Text color={'#FF0E39'}>Failed</Text>
											) : (
												data[col.key]
											)}
										</Box>
									</Flex>
								))}
							</Box>
						</Flex>
					);
				})}
			</Box>
		</>
	);
};

export default React.memo(TableAdManagement);
