export default function BuySellCryptoIcon(props: any) {
	return (
		<svg
			{...props}
			width={18}
			height={20}
			viewBox="0 0 18 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_1223_5835)">
				<path
					d="M17.4574 17.312L16.0006 4.14244H12.702V3.7022C12.702 1.6608 11.0412 0 8.99976 0C6.95836 0 5.29756 1.6608 5.29756 3.7022V4.14244H1.99773L0.54216 17.312C0.466172 17.9987 0.684906 18.6855 1.14221 19.1962C1.59963 19.707 2.25835 20 2.94946 20H15.0501C15.7412 20 16.3999 19.707 16.8573 19.1962C17.3146 18.6855 17.5334 17.9987 17.4574 17.312ZM6.54756 3.7022C6.54756 2.35004 7.64761 1.25 8.99976 1.25C10.3519 1.25 11.452 2.35004 11.452 3.7022V4.14244H6.54756V3.7022ZM15.9261 18.3624C15.7022 18.6123 15.3911 18.75 15.0501 18.75H2.94946C2.60839 18.75 2.2973 18.6123 2.07345 18.3624C1.84968 18.1125 1.74711 17.7883 1.78461 17.4494L3.1172 5.39244H5.29756V6.61505H4.51627V7.86505H7.32885V6.61505H6.54756V5.39244H11.452V6.61505H10.6707V7.86505H13.4832V6.61505H12.702V5.39244H14.8813L16.2149 17.4495C16.2524 17.7883 16.1498 18.1125 15.9261 18.3624ZM8.23175 12.0768C8.23175 12.4368 8.30358 12.5225 9.12836 12.6959C9.50929 12.776 9.94104 12.8668 10.3014 13.1043C10.7767 13.4176 11.0177 13.9001 11.0177 14.5383C11.0177 15.3612 10.4324 16.0604 9.62476 16.303V17.152H8.37476V16.303C7.56709 16.0604 6.98175 15.3612 6.98175 14.5383H8.23175C8.23175 14.867 8.58346 15.1446 8.99976 15.1446C9.41606 15.1446 9.76774 14.867 9.76774 14.5383C9.76774 14.1782 9.69591 14.0926 8.87113 13.9192C8.4902 13.8391 8.05845 13.7483 7.69811 13.5108C7.22277 13.1975 6.98175 12.715 6.98175 12.0768C6.98175 11.2538 7.5671 10.5547 8.37476 10.312V9.46305H9.62476V10.312C10.4324 10.5547 11.0177 11.2538 11.0177 12.0768H9.76774C9.76774 11.7481 9.41606 11.4704 8.99976 11.4704C8.58346 11.4704 8.23175 11.7481 8.23175 12.0768Z"
					fill={props.color || '#737283'}
				/>
			</g>
			<defs>
				<clipPath id="clip0_1223_5835">
					<rect width={18} height={20} fill={props.color || '#737283'} />
				</clipPath>
			</defs>
		</svg>
	);
}
