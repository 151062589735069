import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import CardPaymentIcon from '../../components/Icons/CardPaymentIcon';
// import ShieldIcon from '../../components/Icons/ShieldIcon';
// import LineBox from '../../components/UI/LineBox';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/config';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { P2PTradingClient } from 'skychain-p2p-trading-js';
import { PaymentMethodEntity } from 'skychain-p2p-trading-js/lib/entities/payment-method.entity';
import PaymentMethod from '../../components/Account/AccountList/PaymentMethod';

const P2PSetting = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethodEntity[]>(
		[]
	);
	const p2pTradingClient = useSelector(
		(state: RootState) => state.system.p2pTradingClient
	);
	useEffect(() => {
		if (p2pTradingClient) {
			getPaymentMethods(p2pTradingClient);
		}
	}, [p2pTradingClient]);
	const getPaymentMethods = async (p2pTradingClient: P2PTradingClient) => {
		if (!p2pTradingClient) return;
		try {
			const res = await p2pTradingClient.getPaymentMethods();
			setPaymentMethods(res);
		} catch (error: any) {
			console.log('getPaymentMethods', error);
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Something went wrong',
			});
		}
	};
	const deletePaymentMethod = async (
		p2pTradingClient: P2PTradingClient,
		id: string
	) => {
		if (!p2pTradingClient) return;
		try {
			await p2pTradingClient.deletePaymentMethod(id);
			getPaymentMethods(p2pTradingClient);
			toast({
				title: 'Success',
				description: 'Payment method deleted',
				status: 'success',
			});
		} catch (error: any) {
			console.log('getPaymentMethods', error);
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Something went wrong',
			});
		}
	};
	const handleDelete = (id: string) => {
		if (p2pTradingClient) {
			deletePaymentMethod(p2pTradingClient, id);
		}
	};
	return (
		<Box p={'1rem'}>
			<Text fontSize={'20px'} my={'30px'} fontWeight={'500'}>
				Transaction Settings
			</Text>
			<Flex
				flexDir={{ base: 'column', md: 'row' }}
				justifyContent={'space-between'}
				alignItems={'flex-start'}
			>
				<Flex maxW={{ base: '100%', md: '80%' }}>
					<Box mr={'18px'}>
						<CardPaymentIcon />
					</Box>
					<Box>
						<Text
							fontSize={{ base: '14px', md: '20px' }}
							mb={'10px'}
							fontWeight={'400'}
						>
							Payment Method Setting
						</Text>
						<Button
							display={{ base: 'block', md: 'none' }}
							w={'100%'}
							fontSize={'14px'}
							lineHeight={'24px'}
							py={'0'}
							fontWeight={'500'}
							color={'black'}
							mb={'15px'}
							bg={'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)'}
							onClick={() => navigate(ROUTES.ACCOUNT.ADD_PAYMENT_METHOD)}
						>
							+ Add a payment method
						</Button>

						<Text color="#737283" fontSize={{ base: '12px', md: '16px' }}>
							Please use your real-name account. The activated payment method
							will be displayed to the buyers at transaction, You can activate
							up to three payment methods.
						</Text>
						<Flex direction={'column'} gap={'16px'} mt="20px">
							{paymentMethods.map((paymentMethod) => (
								<PaymentMethod
									paymentMethod={paymentMethod}
									key={paymentMethod.id}
									handleDelete={handleDelete}
								/>
							))}
						</Flex>
					</Box>
				</Flex>
				<Button
					display={{ base: 'none', md: 'block' }}
					fontWeight={'500'}
					color={'black'}
					bg={'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)'}
					onClick={() => navigate(ROUTES.ACCOUNT.ADD_PAYMENT_METHOD)}
				>
					+ Add a payment method
				</Button>
			</Flex>
			{/* <LineBox my={'30px'} /> */}
			{/* <Flex justifyContent={'space-between'} alignItems={'center'}>
				<Flex maxW={{ base: '100%', md: '80%' }}>
					<Box mr={'18px'}>
						<ShieldIcon />
					</Box>
					<Box>
						<Flex
							mb={'15px'}
							justifyContent={'space-between'}
							alignItems={'center'}
						>
							<Text
								fontSize={{ base: '14px', md: '20px' }}
								mb={'10px'}
								fontWeight={'400'}
							>
								Transaction Password
							</Text>
							<Button
								fontSize={'14px'}
								lineHeight={'24px'}
								py={'0'}
								fontWeight={'500'}
								color={'black'}
								display={{ base: 'block', md: 'none' }}
								bg={'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)'}
							>
								Settings
							</Button>
						</Flex>
						<Text color="#737283" fontSize={{ base: '12px', md: '16px' }}>
							Please set up the transaction password to ensure the security of
							your funds.
						</Text>
					</Box>
				</Flex>
				<Button
					display={{ base: 'none', md: 'block' }}
					fontWeight={'500'}
					color={'black'}
					bg={'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)'}
				>
					Settings
				</Button>
			</Flex>
			<LineBox my={'30px'} /> */}
		</Box>
	);
};

export default P2PSetting;
