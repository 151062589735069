import styled from '@emotion/styled';
import { format } from 'date-fns';
import { HTMLAttributes } from 'react';
import { shortenAddress } from '../../utils/address';
import NotFound from '../NotFound/NotFound';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../../utils';
import { SCAN_URL_CONFIG } from '../../config';
import { useTranslation } from 'react-i18next';
import { TRANSLATION } from '../../constants';
import { Flex, useToast } from '@chakra-ui/react';
import CopyIcon from '../Icons/CopyIcon';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
`;
const DetailItem = styled.div`
	display: grid;
	grid-template-columns: 2fr 3fr;
	column-gap: 10px;
	row-gap: 16px;
	padding: 16px 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	&:last-child {
		border-bottom: none;
	}
`;
const DetailBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 6px;
`;
const Label = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #737283;
`;
const Value = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	text-align: right;
	color: #ffffff;
	white-space: nowrap;
`;
const Status = styled.div<{ status: TransactionState }>(
	(props) => `
    color: ${
			props.status === 'COMPLETED'
				? '#21FF0E'
				: props.status === 'FAILED' ||
				  props.status === 'REJECTED' ||
				  props.status === 'INVALID'
				? '#FF0000'
				: props.status === 'PROCESSING'
				? '#FFA500'
				: '#737283'
		}
`
);
interface DepositHistoryDesktopProps extends HTMLAttributes<HTMLDivElement> {
	list: TransactionHistory[];
	type?: 'Deposit' | 'Withdraw';
}
const TransactionHistoryMobile: React.FC<DepositHistoryDesktopProps> = ({
	list,
	type = 'Deposit',
	...props
}: DepositHistoryDesktopProps) => {
	const { t } = useTranslation(TRANSLATION, {
		keyPrefix: 'Wallet',
	});
	const toast = useToast();
	const handleCopy = (text: string | undefined) => {
		if (!text) return;
		navigator.clipboard.writeText(text);
		toast({
			title: 'Copied!',
			status: 'info',
			duration: 2000,
			isClosable: true,
			position: 'top',
		});
	};
	return (
		<Wrapper {...props}>
			{list.length === 0 ? (
				<div style={{ width: '100%' }}>
					<NotFound />
				</div>
			) : (
				<>
					{list.map((item: TransactionHistory, index: number) => (
						<DetailItem key={index}>
							<DetailBlock>
								<Label>{t('Currency')}:</Label>
								<Value>{item.currency}</Value>
							</DetailBlock>
							<DetailBlock>
								<Label>{t('Network')}:</Label>
								<Value>{item.chain}</Value>
							</DetailBlock>
							<DetailBlock>
								<Label>{t('Time')}:</Label>
								<Value>{item.time}</Value>
							</DetailBlock>
							<DetailBlock>
								<Label>{t('Amount')}:</Label>
								<Value>{formatCurrency(item.amount)}</Value>
							</DetailBlock>
							<DetailBlock>
								<Label>{t(`${type} Address`)}:</Label>
								<Value>
									<Flex gap={'10px'}>
										{shortenAddress(item.address)}{' '}
										<CopyIcon
											style={{ cursor: 'pointer' }}
											onClick={() => handleCopy(item.address)}
										/>
									</Flex>
								</Value>
							</DetailBlock>
							<DetailBlock>
								<Label>{t('Blockchain Transaction Hash')}:</Label>
								<Value>
									{item.onchainHash ? (
										<Link
											style={{
												color: 'blue',
											}}
											to={`${SCAN_URL_CONFIG[item.chain || '']}${
												item.onchainHash || ''
											}`}
											target="_blank"
										>
											{shortenAddress(item.onchainHash || '')}
										</Link>
									) : (
										''
									)}
								</Value>
							</DetailBlock>
							<DetailBlock>
								<Label>{t('Status')}</Label>
								<Value>
									<Status status={item.state as TransactionState}>
										{item.state}
									</Status>
								</Value>
							</DetailBlock>
						</DetailItem>
					))}
				</>
			)}
		</Wrapper>
	);
};

export default TransactionHistoryMobile;
