import React, { useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	Img,
	Input,
	Radio,
	RadioGroup,
	Spinner,
	Stack,
	Text,
	Textarea,
	useToast,
} from '@chakra-ui/react';
import { P2PTradingClient } from 'skychain-p2p-trading-js';
import PostAndCloseAds from '../PostAndCloseAds';
import Container from '../../../layouts/Container';
import { OptionBlock, PriceInput } from '../PostAd/SetTypeAndPriceStep';
import {
	AdOrder,
	AdOrderAssetSymbol,
	AdOrderFiatSymbol,
	AdOrderPriceType,
	AdOrderRegion,
	AdOrderStatus,
	AdOrderType,
} from 'skychain-p2p-trading-js/lib/dto';
import PlusIcon from '../../Icons/PlusIcon';
import ListPayment from './ListPayment';
import OrderLimit from './OrderLimit';
import TotalAmount from './TotalAmount';
import QuestionIcon from '../../Icons/QuestionIcon';
import SelectCustom from '../../UI/SelectCustom';
import PrimaryButton from '../../UI/PrimaryButton';
import { useAppSelector } from '../../../redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import {
	AdOrderEntity,
	PaymentMethodEntity,
} from 'skychain-p2p-trading-js/lib/entities';
import { useForm } from 'react-hook-form';
import AddPaymentModal from './AddPaymentModal';
import { CURRENCY } from '../../../constants/cryptos';
import LineBox from '../../UI/LineBox';
import BigNumber from 'bignumber.js';
import { DeleteIcon } from '@chakra-ui/icons';
import CloseIcon from '../../Icons/CloseIcon';
import { formatCurrency } from '../../../utils';
import styled from '@emotion/styled';

interface SelectFields {
	payment_time_limit?: Option;
	reserved_fee?: Option;
	available_region?: Option;
}

interface FormData {
	type: AdOrderType;
	assetSymbol: AdOrderAssetSymbol;
	fiatSymbol: AdOrderFiatSymbol;
	priceType: AdOrderPriceType;
	priceValue: string;
	minAmount: number;
	maxAmount: number;
	totalAmount: number;
	paymentMethod: string;
	term: string;
	autoReply: string;
	status: string;
	timeLimit: number | string;
	regions: string | number;
}

const paymentTimeLimitList = [
	{
		key: 3,
		value: '3 mins',
	},
	{
		key: 10,
		value: '10 mins',
	},
	{
		key: 15,
		value: '15 mins',
	},
	{
		key: 30,
		value: '30 mins',
	},
];
const InputCustom = styled(Input)<any>`
	border: 1px solid #ffffff1e;
	color: ${(props) => props.textColor};
	::placeholder,
	::-webkit-input-placeholder {
		color: ${(props) => props.placeholderColor || props.textColor};
	}
	:-ms-input-placeholder {
		color: ${(props) => props.placeholderColor || props.textColor};
	}
`;
const Label = styled(Text)`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #737283;
	/* margin-bottom: 10px; */
`;

const reservedFeeList = [{ key: AdOrderRegion.ALL, value: 'All Region(s)' }];

const availableRegionFeeList = [
	{ key: AdOrderRegion.ALL, value: 'All Region(s)' },
];

export default function Body() {
	const [selectedFields, setSelectedFields] = useState<SelectFields>({
		payment_time_limit: paymentTimeLimitList[1],
		reserved_fee: reservedFeeList[0],
		available_region: availableRegionFeeList[0],
	});
	const navigate = useNavigate();
	const toast = useToast();
	const { id } = useParams();
	const [ad, setAd] = useState<AdOrderEntity>();
	const [isModalAddPayment, setIsModalAddPayment] = useState<boolean>(false);
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethodEntity[]>(
		[]
	);
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethodEntity>();
	const [selectedPayments, setSelectedPayments] = useState<
		PaymentMethodEntity[]
	>([]);

	const { p2pTradingClient } = useAppSelector((state) => ({
		p2pTradingClient: state.system.p2pTradingClient,
	}));
	const [loading, setLoading] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		watch,
		clearErrors,
	} = useForm<FormData>({
		defaultValues: {
			type: AdOrderType.BUY,
			priceType: AdOrderPriceType.FIXED,
			priceValue: '',
		},
	});

	const getAdsBuyId = async (
		p2pTradingClient: P2PTradingClient,
		id: string
	) => {
		try {
			const res = await p2pTradingClient.getAdOrder(id);
			setAd(res);
		} catch (error) {
			console.log(error);
		}
	};

	const getPaymentMethod = async (
		p2pTradingClient: P2PTradingClient,
		id: string
	) => {
		try {
			const res = await p2pTradingClient.getPaymentMethod(id);
			if (res) {
				setPaymentMethod(res);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getPaymentMethods = async (p2pTradingClient: P2PTradingClient) => {
		try {
			const res = await p2pTradingClient.getPaymentMethods();
			if (res) {
				setPaymentMethods(res);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const editAds = async (
		p2pTradingClient: P2PTradingClient,
		id: string,
		data: FormData
	) => {
		if (loading) return;
		const reqData = {
			...data,
			priceValue: data.priceValue ? Number(data.priceValue) : null,
		} as AdOrder;
		try {
			setLoading(true);
			await p2pTradingClient.editAdOrder(id, reqData);
			toast({
				title: 'Success',
				description: 'Ads order edited successfully',
				status: 'success',
			});
		} catch (error: any) {
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Something went wrong',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleSelect = (fieldSelect: string, option: Option) => {
		switch (fieldSelect) {
			case 'PAYMENT_TIME_LIMIT':
				setSelectedFields({
					...selectedFields,
					payment_time_limit: option,
				});
				setValue('timeLimit', option.key);
				break;

			case 'RESERVED_FEE':
				setSelectedFields({
					...selectedFields,
					reserved_fee: option,
				});
				break;

			case 'AVAILABLE_REGION':
				setSelectedFields({
					...selectedFields,
					available_region: option,
				});
				setValue('regions', option.key);
				break;

			default:
				break;
		}
	};

	const AdPaymentMethod = useMemo(() => {
		return paymentMethods.find((item) => item.id === ad?.paymentMethod);
	}, [paymentMethods, ad]);

	const handleDeletePayment = (payment: PaymentMethodEntity) => {
		setSelectedPayments((paymentMethods) =>
			paymentMethods.filter((item) => item.id !== payment.id)
		);
	};

	const onOpenModalAddPayment = () => {
		setIsModalAddPayment(true);
	};

	const onCloseModalAddPayment = () => {
		setIsModalAddPayment(false);
	};

	const onAddPayments = (payments: PaymentMethodEntity[]) => {
		setSelectedPayments(payments);
	};

	const onSubmit = (data: FormData) => {
		// can edit fields priceValue, minAmount, maxAmount, timeLimit, term,autoReply, regions
		const newData: any = {
			priceValue: new BigNumber(data.priceValue).toString(),
			minAmount: new BigNumber(data.minAmount).toNumber(),
			maxAmount: new BigNumber(data.maxAmount).toNumber(),
			timeLimit: data.timeLimit,
			term: data.term,
			autoReply: data.autoReply,
			regions: data.regions,
			status: data.status,
		};
		if (p2pTradingClient && id) {
			editAds(p2pTradingClient, id, newData);
		}
	};

	useEffect(() => {
		if (p2pTradingClient) {
			getPaymentMethods(p2pTradingClient);
		}
	}, [p2pTradingClient, id]);

	useEffect(() => {
		if (p2pTradingClient && id) {
			getAdsBuyId(p2pTradingClient, id);
		}
	}, [p2pTradingClient, id]);

	useEffect(() => {
		if (p2pTradingClient && ad?.paymentMethod) {
			getPaymentMethod(p2pTradingClient, ad.paymentMethod);
		}
	}, [p2pTradingClient, ad]);

	useEffect(() => {
		if (ad) {
			ad.type && setValue('type', ad.type);
			ad.priceType && setValue('priceType', ad.priceType);
			ad.priceValue && setValue('priceValue', ad.priceValue.toString());
			setValue('totalAmount', ad.totalAmount || 0);
			setValue('minAmount', ad.minAmount || 0);
			setValue('maxAmount', ad.maxAmount || 0);
			setValue('term', ad.term || '');
			setValue('autoReply', ad.autoReply || '');
			setValue('status', ad.status || '');
			setValue('timeLimit', ad.timeLimit || 0);
			setValue('regions', ad.regions || '');
			ad.assetSymbol && setValue('assetSymbol', ad.assetSymbol);
			selectedPayments &&
				selectedPayments[0] &&
				setValue('paymentMethod', selectedPayments[0].id || '');
			setSelectedFields({
				...selectedFields,
				payment_time_limit: paymentTimeLimitList.find(
					(item) => item.key === ad.timeLimit
				),
				available_region: availableRegionFeeList.find(
					(item) => item.key === ad.regions
				),
			});
		}
	}, [ad]);

	const closeAd = async () => {
		if (!p2pTradingClient || !id) return;
		try {
			const res = await p2pTradingClient.cancelAdOrder(id);
			if (res.id) {
				toast({
					title: 'Success',
					description: 'Ads order closed successfully',
					status: 'success',
				});
				navigate(-1);
			}
		} catch (error: any) {
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Something went wrong',
			});
		}
	};
	return (
		<Box>
			<AddPaymentModal
				onAddPayments={onAddPayments}
				selectedPayments={
					paymentMethod
						? [...selectedPayments, paymentMethod]
						: selectedPayments
				}
				listPayment={paymentMethods}
				isOpen={isModalAddPayment}
				onClose={onCloseModalAddPayment}
			/>
			<PostAndCloseAds />
			<Container my={'30px'}>
				<Flex alignItems={'center'} justifyContent={'space-between'}>
					<Flex alignItems={'center'}>
						<Text mr={'12px'}>Edit an Ad:</Text>
						<Button
							border={`1px solid ${
								watch('type') === AdOrderType.BUY ? `#03A66D` : '#CF304A'
							}`}
							color={watch('type') === AdOrderType.BUY ? `#03A66D` : '#CF304A'}
							variant={'outline'}
							fontSize={'14px'}
							w={'52px'}
							h={'26px'}
							mr={'10px'}
							pointerEvents={'none'}
						>
							{watch('type') === AdOrderType.BUY ? 'Buy' : 'Sell'}
						</Button>
						<Img
							w={'20px'}
							h={'20px'}
							src={`${CURRENCY[watch('assetSymbol')]?.icon}`}
							mr={'6px'}
						/>
						<Text mr={'4px'}>{ad?.assetSymbol}</Text>
						<Text mr={'4px'} fontSize={'14px'} color={'#737283'}>
							width
						</Text>
						<Text mr={'4px'} fontSize={'14px'}>
							{ad?.fiatSymbol}
						</Text>
					</Flex>
					{ad?.status && ad?.status !== AdOrderStatus.CANCELED && (
						<Button>
							<div style={{ marginLeft: '4px' }} onClick={closeAd}>
								Close Ad
							</div>
						</Button>
					)}
				</Flex>
				<LineBox my={'14px'} />
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid
						maxW={'531px'}
						templateColumns={'repeat(2, 1fr)'}
						gap={{ base: '10px', lg: '20px' }}
					>
						<GridItem colSpan={2}>
							<Label>Price Type</Label>
						</GridItem>
						<GridItem colSpan={1}>
							<OptionBlock
								isActive={ad?.priceType === AdOrderPriceType.FIXED}
								content="Fixed"
								disabled={ad?.status === AdOrderStatus.CANCELED}
							/>
						</GridItem>
						<GridItem colSpan={1}>
							<OptionBlock
								isActive={ad?.priceType === AdOrderPriceType.FLOATING}
								content="Floating"
							/>
						</GridItem>

						<GridItem colSpan={2}>
							<Label textTransform={'capitalize'}>
								{ad?.priceType?.toLowerCase()}
							</Label>
						</GridItem>
						<GridItem colSpan={2}>
							<PriceInput
								type={watch('priceType')}
								register={register('priceValue', {
									required: 'This field is required.',
									validate: (value) => {
										if (new BigNumber(value).lte(0)) {
											return 'Price must be greater than 0';
										}
										return true;
									},
								})}
								error={errors.priceValue?.message}
								increaseValue={() => {
									setValue(
										'priceValue',
										new BigNumber(watch('priceValue')).plus(1).toString()
									);
									clearErrors('priceValue');
								}}
								decreaseValue={() => {
									setValue(
										'priceValue',
										new BigNumber(watch('priceValue')).minus(1).toString()
									);
									clearErrors('priceValue');
								}}
								name={'priceValue'}
								setValue={setValue}
								inputProps={{
									value: watch('priceValue'),
									isDisabled: ad?.status === AdOrderStatus.CANCELED,
								}}
							/>
						</GridItem>
						<GridItem colSpan={2}>
							{watch('priceType') === AdOrderPriceType.FIXED ? (
								<Flex
									w={'100%'}
									justifyContent={'space-between'}
									alignItems={'center'}
								>
									<Flex alignItems={'center'} gap="5px" mt={{ base: '10px' }}>
										<Label>Highest Order Price</Label>
										<QuestionIcon width={13} height={13} />
									</Flex>
									<Label>$1,100</Label>
								</Flex>
							) : (
								<>
									<Flex
										w={'100%'}
										justifyContent={'space-between'}
										alignItems={'center'}
									>
										<Flex alignItems={'center'} gap="5px" mt={{ base: '10px' }}>
											<Label>Your Price</Label>
										</Flex>
										<Label>0</Label>
									</Flex>
									<Flex
										w={'100%'}
										justifyContent={'space-between'}
										alignItems={'center'}
									>
										<Flex alignItems={'center'} gap="5px" mt={{ base: '10px' }}>
											<Label>Lowest Order Price</Label>
											<QuestionIcon width={13} height={13} />
										</Flex>
										<Label>$1,100</Label>
									</Flex>
								</>
							)}
						</GridItem>
						<GridItem colSpan={2}>
							<Label mb={'10px'}>Total Amount</Label>
							<InputCustom value={ad?.totalAmount} isDisabled={true} />
						</GridItem>

						<GridItem colSpan={2}>
							<OrderLimit
								watch={watch}
								data={ad && ad}
								register={register}
								setValue={setValue}
								clearErrors={clearErrors}
								errors={errors}
								disabled={ad?.status === AdOrderStatus.CANCELED}
							/>
						</GridItem>

						<GridItem colSpan={1}>
							<Text color="#737283">Payment Method</Text>
							{/* <Text color="#737283">Select up to 5 methods</Text> */}
						</GridItem>
						{/* <GridItem textAlign={'right'} colSpan={1}>
							<Button
								onClick={onOpenModalAddPayment}
								p={6}
								border={'1px solid rgba(255, 255, 255, 0.08)'}
								borderRadius={'4px'}
								bg={'rgba(255, 255, 255, 0.05)'}
								leftIcon={<PlusIcon width={16} height={16} />}
								isDisabled={ad?.status === AdOrderStatus.CANCELED}
							>
								Add
							</Button>
						</GridItem> */}
						{AdPaymentMethod ? (
							<GridItem colSpan={2}>
								<Box
									p={'20px'}
									cursor={'pointer'}
									border={'1px solid rgba(255, 255, 255, 0.08)'}
								>
									<Text color="#737283">{AdPaymentMethod.bankName}</Text>
									<Text>{AdPaymentMethod.fullName}</Text>
									<Text color="#737283">
										{AdPaymentMethod.bankAccountNumber}
									</Text>
								</Box>
								{/* <ListPayment
								list={[
									paymentMethods.find(
										(item) => item.id === ad?.paymentMethod
									) || null,
								]}
								handleDeletePayment={handleDeletePayment}
							/> */}
							</GridItem>
						) : (
							<Text color="#737283">Payment Method Not Found</Text>
						)}

						<GridItem colSpan={2}>
							<Flex justifyContent={'space-between'} alignItems={'center'}>
								<Flex alignItems={'center'} gap="5px">
									<Label>Payment Time Limit</Label>
									<QuestionIcon width={13} height={13} />
								</Flex>
								<Box>
									<SelectCustom
										onSelect={(option) =>
											handleSelect('PAYMENT_TIME_LIMIT', option)
										}
										value={selectedFields.payment_time_limit}
										list={paymentTimeLimitList}
										isUnStyle
										isBorder={false}
										fontSize={'14px'}
										disabled={ad?.status === AdOrderStatus.CANCELED}
									/>
								</Box>
							</Flex>
							<Flex justifyContent={'space-between'} alignItems={'center'}>
								<Flex alignItems={'center'} gap="5px">
									<Label>Reserved Fee</Label>
									<QuestionIcon width={13} height={13} />
								</Flex>
								<Box>
									<SelectCustom
										onSelect={(option) => handleSelect('RESERVED_FEE', option)}
										value={selectedFields.reserved_fee}
										list={reservedFeeList}
										isUnStyle
										isBorder={false}
										fontSize={'14px'}
										disabled={ad?.status === AdOrderStatus.CANCELED}
									/>
								</Box>
							</Flex>
						</GridItem>

						<GridItem colSpan={2}>
							<Label mb={'10px'}>Terms (Optional)</Label>
							<Textarea
								{...register('term')}
								rows={5}
								placeholder="Terms will be displayed to the counterparty"
								isDisabled={ad?.status === AdOrderStatus.CANCELED}
							/>
						</GridItem>

						<GridItem colSpan={2}>
							<Label mb={'10px'}>Auto - Reply (Optional)</Label>
							<Textarea
								{...register('autoReply')}
								rows={5}
								placeholder="Terms will be displayed to the counterparty"
								isDisabled={ad?.status === AdOrderStatus.CANCELED}
							/>
						</GridItem>

						{/* <GridItem colSpan={2}>
							<Flex justifyContent={'space-between'} alignItems={'center'}>
								<Flex alignItems={'center'} gap="5px">
									<Label>Available Region(s)</Label>
									<QuestionIcon width={13} height={13} />
								</Flex>
								<Box>
									<SelectCustom
										onSelect={(option) =>
											handleSelect('AVAILABLE_REGION', option)
										}
										value={selectedFields.available_region}
										list={availableRegionFeeList}
										isUnStyle
										isBorder={false}
										fontSize={'14px'}
									/>
								</Box>
							</Flex>
						</GridItem> */}

						<GridItem colSpan={2}>
							<RadioGroup
								colorScheme={'yellow'}
								value={watch('status')}
								onChange={(e) => setValue('status', e)}
								isDisabled={ad?.status === AdOrderStatus.CANCELED}
							>
								<Stack spacing={5} direction="row">
									<Radio value="online">Online</Radio>
									<Radio value="offline">Offline</Radio>
								</Stack>
							</RadioGroup>
						</GridItem>

						{ad?.status && ad?.status !== AdOrderStatus.CANCELED && (
							<GridItem colSpan={2}>
								<Flex flexDir={{ base: 'column', md: 'row' }}>
									<Button
										onClick={() => navigate(-1)}
										w={'100%'}
										maxW={{ base: 'auto', md: '180px' }}
										mr={'30px'}
									>
										Cancel
									</Button>
									<PrimaryButton
										type={'submit'}
										maxW={{ base: 'auto', md: '280px' }}
										w={'100%'}
										borderRadius={'4px'}
									>
										{loading ? <Spinner /> : 'Save'}
									</PrimaryButton>
								</Flex>
							</GridItem>
						)}
					</Grid>
				</form>
			</Container>
		</Box>
	);
}
