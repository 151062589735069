import React from 'react';
import Login from '../components/Authentication/Login';
import GoogleRecaptcha from '../components/UI/GoogleRecaptcha';

const LoginPage = () => {
	return (
		<GoogleRecaptcha>
			<Login />
		</GoogleRecaptcha>
	);
};

export default LoginPage;
