import { Box, chakra, Flex, Input, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import PaperclipIcon from '../Icons/PaperclipIcon';
import SendIcon from '../Icons/SendIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { ChatRoomClient } from 'skychain-chat-js';
import { GroupChatRoom } from 'skychain-chat-js/lib/room/group.chat';
import { ChatMessage, ChatRoomState } from 'skychain-chat-js/lib/schema/Chat';
import { uniqBy, find } from 'lodash';
import { UserIdentityClient } from 'user-identity-js';
import FilerSaver from 'file-saver';
import styled from '@emotion/styled';
interface ItemChat {
	id: string;
	uid: string;
	nickName: string;
	email: string;
	message: string;
	timestamp: number;
	avatar: string;
	type: string;
}
const Header = chakra(Flex, {
	baseStyle: {
		justifyContent: 'space-between',
		alignItems: {
			base: 'flex-start',
			md: 'center',
		},
		flexDirection: {
			base: 'column',
			md: 'row',
		},
		bg: '#091229',
		p: '20px',
		borderRadius: '10px 10px 0px 0px',
	},
});

const Content = chakra(Flex, {
	baseStyle: {
		height: '450px',
		bg: '#070C1E',
		p: '20px',
		overflowY: 'auto',
	},
});

const ChatBox = chakra(Flex, {
	baseStyle: {
		bg: '#091229',
		alignItems: 'center',
		py: '14px',
		px: '20px',
		borderRadius: {
			base: '0px',
			md: '0px 0px 10px 10px',
		},
	},
});

const ReceiveContent = chakra(Box, {
	baseStyle: {
		p: '16px',
		bg: 'rgba(255, 195, 31, 0.1)',
		borderRadius: '8px',
		fontSize: '14px',
		lineHeight: '28px',
		cursor: 'pointer',
		w: '80%',
	},
});

const SendContent = chakra(Box, {
	baseStyle: {
		p: '16px',
		bg: '#1F2434',
		borderRadius: '8px',
		fontSize: '14px',
		lineHeight: '28px',
		cursor: 'pointer',
		w: '80%',
	},
});

const EmailOnRoom = styled('p')`
	margin: 0;
	font-size: 12px;
	color: #737283;
`;

interface Props {
	counterpart: string;
	status: string | undefined;
	groupId: string;
}
const Chat = ({ counterpart, status, groupId }: Props) => {
	const chatRoomClient = useSelector(
		(state: RootState) => state.system.chatRoomClient
	);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const userProfile = useSelector((state: RootState) => state.user.userProfile);
	const toast = useToast();
	const [chatRoom, setChatRoom] = useState<GroupChatRoom<ChatRoomState>>();
	const [chatList, setChatList] = useState<ItemChat[]>([]);
	const [chatIsReady, setChatIsReady] = useState(false);
	const inputRef = useRef<any>();
	const chatContainerRef = useRef<any>();

	useEffect(() => {
		if (chatRoomClient && groupId) {
			listenEventChat(chatRoomClient, groupId);
		}
	}, [chatRoomClient, groupId]);

	useEffect(() => {
		if (chatIsReady && chatRoom && userIdentity) {
			getHistory(chatRoom, userIdentity);
		}
	}, [chatIsReady, chatRoom, userIdentity]);

	const listenEventChat = async (
		chatRoomClient: ChatRoomClient,
		groupId: string
	) => {
		const chatRoom = chatRoomClient.getGroupChatRoom(groupId);
		await chatRoom.connect();
		setChatRoom(chatRoom);

		chatRoom.onError((code, error) => {
			toast({
				title: 'Error',
				description: error,
				status: 'error',
			});
		});

		chatRoom.onStateChange((state) => {
			setChatIsReady(state.isReady);
		});

		chatRoom.onChatMessage((message) => {
			parseChatMesage(message);
		});
	};

	useEffect(() => {
		return () => {
			chatRoom?.disconnect();
		};
	}, [chatRoom]);

	const parseChatMesage = async (message: ChatMessage) => {
		if (!userIdentity) return;
		try {
			const user = await userIdentity.getUser(message.uid);
			const newMessage = {
				id: message.id,
				uid: message.uid,
				nickName: user?.nickName || 'Hidden',
				email: user?.email || '',
				message: message.message,
				timestamp: message.timestamp * 1000,
				avatar: user.avatar || '/images/avatar.png',
				type: message.type,
			} as ItemChat;
			setChatList((prevChat) => {
				let newList = prevChat.concat([newMessage]);
				newList = uniqBy(newList, 'id');
				return newList;
			});
			scrollToBottomChat();
		} catch (error) {
			console.log('error', error);
		}
	};

	const getHistory = async (
		chatRoom: GroupChatRoom<ChatRoomState>,
		userIdentity: UserIdentityClient
	) => {
		try {
			const queryParams = {
				limit: 100,
				offset: 0,
			};
			const history = await chatRoom.getChatHistory(queryParams);
			if (history.length > 0) {
				const users = await userIdentity.getUsers({
					ids: history
						.reverse()
						.map((historyItem) => historyItem.uid)
						.toString(),
				});
				const newChatList = history.map((historyItem) => {
					return {
						id: historyItem.id,
						uid: historyItem.uid,
						nickName:
							find(users.data, { id: historyItem.uid })?.nickName || 'Hidden',
						email: find(users.data, { id: historyItem.uid })?.email || '',
						message: historyItem.message,
						timestamp: historyItem.timestamp * 1000,
						avatar:
							find(users.data, { id: historyItem.uid })?.avatar ||
							'/images/avatar.png',
						type: historyItem.type,
					};
				});
				setChatList(newChatList);
				scrollToBottomChat();
			}
		} catch (error) {
			console.log('error', error);
		}
	};

	const handleSendMessage = async () => {
		if (inputRef.current) {
			const message = inputRef.current?.value || '';
			if (chatRoom?.isReady() && message) {
				await chatRoom.sendTextMessage(message);
				inputRef.current.value = '';
			}
		}
	};
	const handleSendFile = async (files: FileList | null) => {
		if (files && files.length > 0) {
			await chatRoom?.sendFileMessage(files[0]);
		}
	};
	const scrollToBottomChat = async () => {
		const scroll = chatContainerRef.current?.scrollHeight;
		chatContainerRef.current?.scrollTo(0, scroll + 600);
	};
	const getFileToDisplay = async (
		fileId: string,
		groupId: string,
		messageId: string
	) => {
		const res = await chatRoom?.getFile(fileId, groupId);
		if (res) {
			const index = chatList.findIndex((item) => item.id === messageId);
			if (index !== -1) {
				FilerSaver.saveAs(res.url, fileId);
			}
		}
	};

	return (
		<Box w={{ md: '356px', base: '100%' }}>
			<Header>
				<Box>
					<Flex alignItems={'center'} gap={'8px'} mb={'10px'}>
						<img src={'/images/avatar.svg'} alt="avatar" />
						<Text color={'#1C84E4'} fontWeight={500}>
							{counterpart}
						</Text>
						<img src={'/images/tick.svg'} alt="tick" />
					</Flex>
					{/* <Flex fontSize={'14px'}>
						<Text color={'#737283'} mr={'4px'}>
							30D Trades:{' '}
						</Text>
						<Text>1336</Text>
					</Flex> */}
				</Box>

				<Box>
					<Flex alignItems={'center'} mb={'10px'} justifyContent={'flex-end'}>
						<Box
							w={'10px'}
							h={'10px'}
							bg={status?.toLowerCase() === 'online' ? '#03A66D' : 'grey'}
							borderRadius={'100%'}
							mr={'5px'}
						/>
						{status && (
							<Box
								fontSize={'14px'}
								color={status?.toLowerCase() === 'online' ? '#03A66D' : 'grey'}
							>
								{status?.charAt(0)?.toUpperCase() + status?.slice(1)}
							</Box>
						)}
					</Flex>
					{/* <Flex fontSize={'14px'}>
						<Text color={'#737283'} mr={'4px'}>
							30D Completion Rate:
						</Text>
						<Text>100%</Text>
					</Flex> */}
				</Box>
			</Header>
			<Content ref={chatContainerRef} direction={'column'} gap={'15px'}>
				{chatList.map((item) =>
					item.uid === userProfile?.id ? (
						<Flex key={item.id} justifyContent={'flex-end'}>
							<SendContent
								onClick={async () => {
									item.type === 'file' &&
										(await getFileToDisplay(item.message, groupId, item.id));
								}}
							>
								{item.message}
							</SendContent>
						</Flex>
					) : (
						<Flex key={item.id} alignItems={'flex-start'} direction={'column'}>
							<EmailOnRoom>{item.email}</EmailOnRoom>
							<ReceiveContent
								onClick={async () => {
									item.type === 'file' &&
										(await getFileToDisplay(item.message, groupId, item.id));
								}}
							>
								{item.message}
							</ReceiveContent>
						</Flex>
					)
				)}
			</Content>
			<ChatBox>
				<Input
					ref={inputRef}
					fontSize={'14px'}
					focusBorderColor={'transparent'}
					outline={'transparent'}
					border={'0px'}
					placeholder={'Write your message...'}
					_placeholder={{
						color: 'rgba(255, 255, 255, 0.3',
					}}
					onKeyDown={(event) => event.key === 'Enter' && handleSendMessage()}
				/>
				<Box
					mr={'16px'}
					w={'20px'}
					h={'20px'}
					position={'relative'}
					cursor={'pointer'}
				>
					<PaperclipIcon style={{ cursor: 'pointer' }} />
					<input
						style={{
							position: 'absolute',
							top: 0,
							right: 0,
							left: 0,
							bottom: 0,
							opacity: 0,
							zIndex: 1,
							cursor: 'pointer',
						}}
						width={20}
						height={20}
						type="file"
						onChange={(event) => handleSendFile(event.target.files)}
					/>
				</Box>
				<Box cursor={'pointer'} onClick={handleSendMessage}>
					<SendIcon />
				</Box>
			</ChatBox>
		</Box>
	);
};

export default Chat;
