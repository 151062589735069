export default function CloseCircleIcon(props: any) {
	return (
		<svg
			{...props}
			width={28}
			height={28}
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M23.9273 4.07273C21.28 1.42545 17.8182 0 14.0509 0C10.2836 0 6.72 1.42545 4.07273 4.07273C1.42545 6.72 0 10.1818 0 13.9491C0 16.6982 0.814545 19.4473 2.34182 21.6873C2.44364 21.7891 2.54545 21.8909 2.74909 21.9927C2.95273 21.9927 3.15636 21.9927 3.25818 21.8909C3.36 21.7891 3.46182 21.6873 3.56364 21.4836C3.56364 21.28 3.56364 21.0764 3.46182 20.9745C2.03636 18.8364 1.32364 16.4945 1.32364 13.9491C1.32364 7.02545 7.02545 1.32364 13.9491 1.32364C20.8727 1.32364 26.5745 7.02545 26.5745 13.9491C26.5745 20.8727 20.8727 26.5745 13.9491 26.5745C11.6073 26.5745 9.36727 25.9636 7.43273 24.7418C7.33091 24.64 7.22909 24.64 7.12727 24.64C7.02545 24.64 7.02545 24.64 6.92364 24.64C6.72 24.64 6.61818 24.7418 6.51636 24.9455C6.41455 25.1491 6.41455 25.2509 6.41455 25.4545C6.41455 25.6582 6.51636 25.76 6.72 25.8618C8.85818 27.1855 11.4036 27.8982 13.9491 27.8982C17.7164 27.8982 21.1782 26.4727 23.8255 23.8255C26.4727 21.1782 27.8982 17.7164 27.8982 13.9491C27.8982 10.1818 26.5745 6.72 23.9273 4.07273Z"
				fill="white"
			/>
			<path
				d="M18.1236 17.0036L15.069 13.949L18.1236 10.8945C18.429 10.589 18.429 10.08 18.1236 9.7745C17.8181 9.46905 17.309 9.46905 17.0036 9.7745L13.949 12.829L10.8945 9.7745C10.589 9.46905 10.08 9.46905 9.7745 9.7745C9.46905 10.08 9.46905 10.589 9.7745 10.8945L12.829 13.949L9.7745 17.0036C9.46905 17.309 9.46905 17.8181 9.7745 18.1236C10.08 18.429 10.589 18.429 10.8945 18.1236L13.949 15.069L17.0036 18.1236C17.309 18.429 17.8181 18.429 18.1236 18.1236C18.429 17.8181 18.429 17.309 18.1236 17.0036Z"
				fill="white"
			/>
		</svg>
	);
}
