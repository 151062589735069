import {
	Box,
	Table,
	TableContainer,
	TableProps,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	chakra,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { formatCurrency } from '../../../utils';
import { TRANSLATION } from '../../../constants';
import { useTranslation } from 'react-i18next';

interface FundTableDesktopProps extends TableProps {
	list: any;
}

const TokenWrapper = styled(Box)`
	display: flex;
	align-items: center;
	gap: 8px;
`;
const TokenImage = styled.img`
	width: 35px;
	height: 35px;
	object-fit: contain;
	border-radius: 100%;
`;
const TokenName = styled(Box)`
	color: #737283;
	font-size: 14px;
	line-height: 16px;
`;
const TokenSymbol = styled(Box)`
	color: #ffffff;
	font-size: 16px;
	line-height: 1;
`;

const TokenInfo = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 5px;
`;

const TitleWrapper = styled(Box)`
	display: flex;
	align-items: center;
	gap: 4px;
`;

const HeadCell = chakra(Th, {
	baseStyle: {
		borderBottom: '1px solid #FFFFFF1E',
		textTransform: 'capitalize',
		fontFamily: 'Roboto',
		color: '#737283',
		fontWeight: 400,
		fontSize: '14px',
		padding: '25px 0',
	},
});

const BodyCell = chakra(Td, {
	baseStyle: {
		borderBottom: '1px solid #FFFFFF1E',
		padding: '25px 0',
	},
});

const FundTableDesktop = ({ list, ...props }: FundTableDesktopProps) => {
	const { t } = useTranslation(TRANSLATION, {
		keyPrefix: 'Wallet',
	});
	return (
		<TableContainer style={{ width: '100%' }}>
			<Table {...props}>
				<Thead>
					<Tr>
						<HeadCell>{t('Token')}</HeadCell>
						<HeadCell>
							<TitleWrapper>{t('Total')} </TitleWrapper>
						</HeadCell>
						<HeadCell>
							<TitleWrapper>{t('Freeze')} </TitleWrapper>
						</HeadCell>
					</Tr>
				</Thead>
				<Tbody>
					{list.map((item: any, index: any) => (
						<Tr key={index}>
							<BodyCell>
								<TokenWrapper>
									<TokenImage
										src={item.currency.icon}
										alt={item.currency.name}
									/>
									<TokenInfo>
										<TokenSymbol>{item.currency.symbol}</TokenSymbol>
										<TokenName>{item.currency.name}</TokenName>
									</TokenInfo>
								</TokenWrapper>
							</BodyCell>
							<BodyCell>{formatCurrency(item.total)}</BodyCell>
							<BodyCell>{formatCurrency(item.freeze)}</BodyCell>
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};

export default FundTableDesktop;
