import React from 'react';
import P2PAccountBanner from '../../components/Wallets/P2PAccount/P2PAccountBanner';
import FundList from '../../components/Wallets/P2PAccount/FundList';

const P2PAccountPage = () => {
	return (
		<div>
			<P2PAccountBanner />
			<FundList />
		</div>
	);
};

export default P2PAccountPage;
