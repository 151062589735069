import { Box, Flex, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Container from '../../layouts/Container';

const Wrapper = styled(Box)`
	margin-top: 118px;
	background-color: #091229;
	padding-block: 100px;
`;

const listJourney = [
	{
		id: 1,
		title: '1. Create your account',
		description:
			'Create your account in just one simple step by linking your Google, Facebook, Apple ID, or Email account',
		link: '/login',
	},
	{
		id: 2,
		title: '2. Verify your account',
		description:
			'To enhance the security of your account and begin trading, please verify your Google Authenticator',
		link: '',
	},
	{
		id: 3,
		title: '3. Start trading crypto',
		description:
			'Purchase USDT and other cryptocurrencies with your local currency through your bank account',
		link: '',
	},
];

const StartYourJourney = () => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'Home.StartYourJourney',
	});
	return (
		<Wrapper>
			<Container>
				<Box
					fontSize={{ base: '20px', md: '30px' }}
					textAlign={'center'}
					data-aos="fade-up"
					data-aos-duration={'1500'}
				>
					{t('Start your journey')}
				</Box>
				<Box
					maxW={'889px'}
					mx={'auto'}
					textAlign={'center'}
					color={'#737283'}
					fontWeight={400}
					fontSize={{ base: '16px', md: '18px' }}
					mb={'20px'}
					data-aos="fade-up"
					data-aos-duration={'1500'}
				>
					{t('descriptionSection')}
				</Box>
				<Flex
					flexDir={{ base: 'column', md: 'row' }}
					justifyContent={'space-between'}
					alignItems={'center'}
				>
					{listJourney.map((journey, index) => (
						<Link to={journey.link} key={journey.id}>
							<Box
								key={journey.id}
								marginBottom={{ base: '60px', md: 0 }}
								data-aos="fade-up"
								data-aos-duration={`1${index + 1}00`}
							>
								<Image src={`/images/homepage/journey-${journey.id}.png`} />
								<Box
									textAlign={'center'}
									my={{ base: '10px', md: '20px' }}
									fontSize={{ base: '20px', md: '24px' }}
								>
									{t(`title${journey.id}`)}
								</Box>
								<Box maxW={'301px'} color={'#737283'} textAlign={'center'}>
									{t(`description${journey.id}`)}
								</Box>
							</Box>
						</Link>
					))}
				</Flex>
			</Container>
		</Wrapper>
	);
};

export default StartYourJourney;
