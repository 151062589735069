import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import Banner from '../Banner';
import { formatCurrency } from '../../../utils';
import { useTranslation } from 'react-i18next';

export default function P2PAccountBanner() {
	const actions = ['transfer'] as BannerAction[];
	const userAssetBalance = useSelector(
		(state: RootState) => state.user.userAssetBalances
	);
	const { t } = useTranslation();

	const usdtBalance = userAssetBalance?.find(
		(asset) => asset.assetSymbol === 'USDT' && asset.chain === 'FUNDING'
	);
	const valuation = {
		balance: formatCurrency(usdtBalance?.totalAssetAmount),
		currency: 'USDT',
	} as Valuation;

	return (
		<Banner
			title={'P2P Account'}
			valuation={valuation}
			actions={actions}
			isPayment
			chain="FUNDING"
		/>
	);
}
