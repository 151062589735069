export default function WalletIcon(props: any) {
	return (
		<svg
			{...props}
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_1223_5840"
				style={{ maskType: 'luminance' }}
				maskUnits="userSpaceOnUse"
				x={0}
				y={0}
				width={20}
				height={20}
			>
				<path
					d="M0 1.90735e-06H20V20H0V1.90735e-06Z"
					fill={props.color || '#B1B2B2'}
				/>
			</mask>
			<g mask="url(#mask0_1223_5840)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M19.9971 9.21741V6.8719C19.9971 5.57847 18.9485 4.52991 17.6551 4.52991H16.8721V3.7469C16.8721 2.45347 15.8235 1.40491 14.5301 1.40491H2.34082C1.04642 1.40491 -0.00292969 2.45426 -0.00292969 3.74866C-0.00292969 5.04306 1.04642 6.09241 2.34082 6.09241H17.6551C18.0856 6.09241 18.4346 6.44142 18.4346 6.8719V9.21741H19.9971ZM15.3096 4.52991H2.34082C1.90936 4.52991 1.55957 4.18011 1.55957 3.74866C1.55957 3.3172 1.90936 2.96741 2.34082 2.96741H14.5301C14.9606 2.96741 15.3096 3.31642 15.3096 3.7469V4.52991Z"
					fill={props.color || '#B1B2B2'}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M-0.00292969 16.2487C-0.00292969 17.5431 1.04643 18.5924 2.34082 18.5924H17.6551C18.9485 18.5924 19.9971 17.5438 19.9971 16.2504V13.9049H18.4346V16.2504C18.4346 16.6809 18.0856 17.0299 17.6551 17.0299H2.34082C1.90935 17.0299 1.55957 16.6801 1.55957 16.2487V3.74866H-0.00292969V16.2487Z"
					fill={props.color || '#B1B2B2'}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.1846 11.5612C12.1846 13.287 13.5837 14.6862 15.3096 14.6862H19.9971V8.43616H15.3096C13.5837 8.43616 12.1846 9.83527 12.1846 11.5612ZM15.3096 13.1237C14.4466 13.1237 13.7471 12.4241 13.7471 11.5612C13.7471 10.6982 14.4466 9.99866 15.3096 9.99866H18.4346V13.1237H15.3096Z"
					fill={props.color || '#B1B2B2'}
				/>
			</g>
		</svg>
	);
}
