import { Flex, Text } from '@chakra-ui/react';
import ArrowNormalIcon from '../Icons/ArrowNormalIcon';
import styled from '@emotion/styled';
import MobileMenu from '../../layouts/MainLayout/MobileMenu';

const BackIconWrapper = styled(Flex)`
	background: linear-gradient(
		101.21deg,
		rgba(255, 255, 255, 0) 3.26%,
		rgba(255, 255, 255, 0.0544) 100%
	);
	width: 31px;
	height: 31px;
	border-radius: 100px;
	border: 1px solid #ffffff1e;
	cursor: pointer;
`;

interface PropsType {
	title: string;
	handleBack: () => void;
}

export default function NavigateMobile({ title, handleBack }: PropsType) {
	return (
		<Flex
			backgroundColor={'#091229'}
			justifyContent={'space-between'}
			alignItems={'center'}
			p={'20px'}
		>
			<BackIconWrapper
				transform={'rotate(180deg)'}
				alignItems={'center'}
				justifyContent={'center'}
				onClick={handleBack}
			>
				<ArrowNormalIcon />
			</BackIconWrapper>

			<Text>{title}</Text>

			<MobileMenu />
		</Flex>
	);
}
