import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import FundTableDesktop from './FundTable';
import FundTableMobile from './FundTableMobile';
import { useAppSelector } from '../../../redux/store';
import { CURRENCY } from '../../../constants/cryptos';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATION } from '../../../constants';

const Wrapper = styled(Box)`
	margin-top: 18px;
`;
const Header = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const Title = styled(Box)`
	font-weight: 500;
	font-size: 20px;
	line-height: 48px;
	color: #ffffff;
	@media screen and (max-width: 567px) {
		font-size: 16px;
	}
`;
const LeftColumn = styled(Box)`
	display: flex;
	align-items: center;
	gap: 20px;
	@media screen and (max-width: 567px) {
		flex: 1;
		justify-content: space-between;
		margin-right: 10px;
	}
`;

const Retangle = styled(Box)`
	background: linear-gradient(
		100.78deg,
		rgba(255, 255, 255, 0.116) 0.27%,
		rgba(255, 255, 255, 0.024) 102.68%
	);
	height: 1px;
	width: 100%;
`;

const StyledFundTableDesktop = styled(FundTableDesktop)`
	display: table;
	@media screen and (max-width: 567px) {
		display: none;
	}
`;
const StyledFundTableMobile = styled(FundTableMobile)`
	display: none;
	@media screen and (max-width: 567px) {
		display: block;
	}
`;

const FundList = () => {
	const { t } = useTranslation(TRANSLATION, {
		keyPrefix: 'Wallet',
	});
	const supportedAssets = useAppSelector(
		(state) => state.system.supportedAssets
	);

	const myAssetBalances = useAppSelector(
		(state) => state.user.userAssetBalances
	);

	const dataTable = React.useMemo(() => {
		const data = supportedAssets.map((asset) => {
			const myAsset = myAssetBalances.find(
				(myAsset) =>
					myAsset.assetSymbol === asset.symbol && myAsset.chain === 'FUNDING'
			);
			return {
				currency: CURRENCY[asset?.symbol || ''],
				total: myAsset?.totalAssetAmount || 0.0,
				freeze: myAsset?.lockedAssetAmount || 0.0,
			};
		});
		return data;
	}, [supportedAssets, myAssetBalances]);

	return (
		<Wrapper>
			<Header>
				<LeftColumn>
					<Title>{t('Fund list')}</Title>
				</LeftColumn>
			</Header>
			<Retangle />
			<StyledFundTableDesktop list={dataTable} />
			<StyledFundTableMobile list={dataTable} />
		</Wrapper>
	);
};

export default FundList;
