export default function TelegramIcon(props: any) {
	return (
		<svg
			width={20}
			height={18}
			viewBox="0 0 20 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.6938 1.14984C19.4497 0.850143 19.0833 0.685059 18.6621 0.685059C18.4348 0.685059 18.194 0.732639 17.9464 0.826431L1.01394 7.2419C0.11363 7.58309 -0.0075458 8.09666 0.000345121 8.37241C0.00819697 8.6468 0.15707 9.14893 1.06328 9.43878L4.65423 10.7547L6.54676 15.0868C6.737 15.686 7.13452 16.1063 7.64188 16.2426C7.75884 16.2741 7.87825 16.2895 7.99853 16.2895C8.41058 16.2895 8.83247 16.1081 9.19682 15.7659L10.7648 14.2928L14.5905 16.926C14.935 17.1806 15.3099 17.3151 15.6755 17.315C16.4175 17.315 17.0093 16.7719 17.1831 15.9313L19.9524 2.53771C20.0662 1.98726 19.9744 1.49439 19.6938 1.14984ZM5.84431 10.5514L10.7309 7.93095L7.32526 11.0549C7.20401 11.1661 7.13647 11.3228 7.13627 11.486C7.13604 11.4921 7.1353 11.4979 7.13526 11.5039L7.1235 13.4795L5.84431 10.5514ZM8.39444 14.9117C8.35831 14.9457 8.3221 14.9755 8.28631 15.0015L8.30065 12.5967L9.77692 13.6128L8.39444 14.9117ZM18.8048 2.30039L16.0355 15.6939C16.0076 15.829 15.9166 16.1431 15.6754 16.1431C15.5628 16.1431 15.4231 16.0852 15.2821 15.9798C15.276 15.9753 15.2699 15.9709 15.2637 15.9667L11.046 13.0636C11.0454 13.0633 11.0448 13.0629 11.0443 13.0625L8.65965 11.4211L14.9589 5.64294C15.1766 5.44328 15.2116 5.11284 15.0406 4.87197C14.8697 4.6311 14.5462 4.55516 14.2859 4.69474L5.04553 9.65L1.4545 8.33405C1.45188 8.33308 1.44927 8.33214 1.44661 8.3312L18.3616 1.92233C18.5042 1.86831 18.6037 1.85698 18.6621 1.85698C18.691 1.85698 18.7609 1.86018 18.7851 1.88987C18.816 1.92788 18.855 2.05714 18.8048 2.30039Z"
				fill={props.color || '#B1B2B2'}
			/>
		</svg>
	);
}
