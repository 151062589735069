import { Box, Button, Flex, Img, Show, Text, chakra } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes/config';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import OutlineButton from '../../UI/OutlineButton';

const TitleWrapper = chakra(Box, {
	baseStyle: {
		padding: '10px 0 25px',
		borderBottom: '1px solid #FFFFFF1E',
	},
});

const PrimaryText = chakra(Text, {
	baseStyle: {
		color: '#ffffff',
	},
});

const SecondaryText = chakra(Text, {
	baseStyle: {
		fontSize: '14px',
		color: '#737283',
	},
});

const SettingsWrapper = chakra(Flex, {
	baseStyle: {
		paddingTop: '20px',
	},
});

const SettingItemWrapper = chakra(Flex, {
	baseStyle: {
		width: '100%',
		padding: { base: '10px 0px 15px 0px', lg: '10px 80px 15px 0px' },
		borderBottom: '1px solid #FFFFFF1E',
	},
});

const ActionText = styled(Text)`
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	font-weight: 500;
	line-height: 24px;
`;

const ActionButton = chakra(Button, {
	baseStyle: {
		width: { base: '92px', lg: '140px' },
		height: {
			base: '30px',
			lg: '38px',
		},
		borderRadius: '5px',
		background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
		color: '#050713',
		fontWeight: 500,
	},
});

export default function Settings() {
	const userProfile = useSelector((state: RootState) => state.user.userProfile);
	const navigate = useNavigate();
	const settings = [
		{
			title: 'Password',
			content: '*****************',
			icon: 'locked-icon',
			action: (
				<Action
					content={'Change'}
					onClick={() => navigate(ROUTES.CHANGE_PASSWORD)}
				/>
			),
		},
		{
			title: 'Email',
			subTitle: userProfile?.email,
			content:
				'Used when logging in, withdrawing and modifying security settings',
			icon: 'email-icon',
			action: <></>,
		},
		{
			title: 'Google Authenticator',
			content:
				'API Secure verification when withdrawing, retrieving passwords, modifying security settings and managing API',
			icon: 'google-authen-icon',
			action: (
				<Action
					onClick={() => navigate(ROUTES.ACCOUNT.GOOGLE_AUTHENTICATION)}
					isButton
					content={userProfile?.isEnableTwoFactor ? 'Disable' : 'Enable'}
					buttonType={userProfile?.isEnableTwoFactor ? 'outline' : 'primary'}
				/>
			),
		},
		// {
		// 	title: 'My Invitation Code',
		// 	content: 'Used to invite new users',
		// 	icon: 'invitation-code-icon',
		// 	action: <Action content={'View'} />,
		// },
		// {
		// 	title: "Add Superior's Invitation Code",
		// 	content: 'Used for Invitation Relationship',
		// 	icon: 'ticket-icon',
		// 	action: <Action content={'Add'} />,
		// },
		// {
		// 	title: 'Delete Account',
		// 	content:
		// 		'After deleting your account，you will never be able to re-register this account and its sub-account email, mobile phone number, and identity information.',
		// 	icon: 'delete-icon',
		// 	action: (
		// 		<Action
		// 			content={
		// 				<img src="/images/icons/arrow-right-icon.svg" alt="arrow right" />
		// 			}
		// 		/>
		// 	),
		// },
	];
	return (
		<Box px={{ base: '18px', lg: '0px' }}>
			<TitleWrapper>
				<PrimaryText fontWeight={500} fontSize={{ base: '16px', lg: '20px' }}>
					Security Settings
				</PrimaryText>
			</TitleWrapper>

			<SettingsWrapper gap={{ base: '20px' }} direction={{ base: 'column' }}>
				{settings.map((setting) => (
					<SettingItem key={setting.title} {...setting} />
				))}
			</SettingsWrapper>
		</Box>
	);
}

interface SettingItemProps {
	icon: string;
	title: string;
	content: string;
	subTitle?: string;
	action: React.ReactNode;
}

const SettingItem = ({
	icon,
	title,
	content,
	subTitle,
	action,
}: SettingItemProps) => {
	return (
		<SettingItemWrapper
			justifyContent={'space-between'}
			alignItems={{ lg: 'center', base: 'flex-start' }}
			w="100%"
		>
			<Flex gap={'10px'} alignItems={'flex-start'} w="100%">
				<Img src={`/images/icons/${icon}.svg`} alt={icon} />
				<Box w={'100%'}>
					<Flex
						justifyContent={'space-between'}
						w={{ base: '100%' }}
						pt={{ base: '2px' }}
						pb={{ base: '5px', lg: '0px' }}
					>
						<PrimaryText fontSize={{ base: '14px', lg: '18px' }}>
							{title}
						</PrimaryText>
						<Show below="md">{action}</Show>
					</Flex>
					<Show below="md">
						<SecondaryText fontSize={{ base: '12px', lg: '16px' }}>
							{subTitle}
						</SecondaryText>
					</Show>
					<SecondaryText fontSize={{ base: '12px', lg: '16px' }}>
						{content}
					</SecondaryText>
				</Box>
			</Flex>

			<Show above="sm">
				<Flex alignItems={'center'}>
					<SecondaryText fontSize={{ base: '12px', lg: '16px' }}>
						{subTitle}
					</SecondaryText>
					{action}
				</Flex>
			</Show>
		</SettingItemWrapper>
	);
};

const Action = ({
	isButton = false,
	content,
	onClick,
	buttonType = 'primary',
}: {
	isButton?: boolean;
	content: string | React.ReactNode;
	onClick?: () => void;
	buttonType?: 'primary' | 'outline';
}) => {
	return (
		<Box cursor={'pointer'}>
			{isButton ? (
				<>
					{buttonType === 'outline' ? (
						<OutlineButton onClick={onClick}>{content}</OutlineButton>
					) : (
						<ActionButton
							_hover={{
								background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
								opacity: 0.5,
							}}
							fontSize={{ base: '14px', lg: '16px' }}
							onClick={onClick}
						>
							{content}
						</ActionButton>
					)}
				</>
			) : typeof content === 'string' ? (
				<ActionText
					fontSize={{ base: '14px', lg: '16px' }}
					textAlign={'right'}
					width={{ base: '92px', lg: '140px' }}
					onClick={onClick}
				>
					{content}
				</ActionText>
			) : (
				<Flex justifyContent={'flex-end'} width={{ base: '92px', lg: '140px' }}>
					{content}
				</Flex>
			)}
		</Box>
	);
};
