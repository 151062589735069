import { Box, Flex, Text } from '@chakra-ui/layout';
import BigNumber from 'bignumber.js';
import React from 'react';
import { AdOrder } from 'skychain-p2p-trading-js/lib/dto';
import QuestionIcon from '../../Icons/QuestionIcon';
import AmountInput from '../../UI/AmountInput';
import { ErrorMessage } from '../../Wallets/TransferModal/styles';
import { Label } from '../PostAd/PostAdModal';

const OrderLimit = ({
	register,
	data,
	errors,
	watch,
	disabled,
	setValue,
}: {
	register: any;
	errors: any;
	data?: AdOrder;
	clearErrors: any;
	setValue: any;
	watch: any;
	disabled?: boolean;
}) => {
	return (
		<Box>
			<Flex w={'100%'} alignItems={'center'}>
				<Flex alignItems={'center'} gap="5px" mb={{ base: '10px' }}>
					<Label>Order Limit</Label>
					<QuestionIcon width={13} height={13} />
				</Flex>
			</Flex>
			<Flex alignItems={'center'}>
				<Box>
					<AmountInput
						step={'any'}
						expand={
							<Text fontSize={{ base: '14px' }} pr={{ base: '10px' }}>
								{data?.fiatSymbol || 'USD'}
							</Text>
						}
						value={watch('minAmount')}
						isDisabled={disabled}
						expandW={'auto'}
						textColor="#ffffff"
						register={register('minAmount', {
							required: 'This field is required',
							validate: (value: any) => {
								if (new BigNumber(value).gt(watch('maxAmount'))) {
									return 'Min amount must be smaller than max amount';
								}
								if (new BigNumber(value).lte(0)) {
									return 'Min amount must be greater than 0';
								}
								return true;
							},
						})}
						name={'minAmount'}
						setValue={setValue}
					/>

					<ErrorMessage style={{ marginTop: '10px', height: '12px' }}>
						{errors.minAmount?.message}
					</ErrorMessage>
				</Box>
				<Text px={{ base: '10px' }} mb={'20px'}>
					~
				</Text>
				<Box>
					<AmountInput
						step={'any'}
						expand={
							<Text fontSize={{ base: '14px' }} pr={{ base: '10px' }}>
								{data?.fiatSymbol || 'USD'}
							</Text>
						}
						expandW={'auto'}
						textColor="#ffffff"
						register={register('maxAmount', {
							required: 'This field is required',
							validate: (value: any) => {
								if (new BigNumber(value).lt(watch('minAmount'))) {
									return 'Min amount must be smaller than max amount';
								}
								if (new BigNumber(value).lt(0)) {
									return 'Min amount must not be less than 0';
								}
								return true;
							},
						})}
						value={watch('maxAmount')}
						name={'maxAmount'}
						setValue={setValue}
						isDisabled={disabled}
					/>

					<ErrorMessage style={{ marginTop: '10px', height: '12px' }}>
						{errors.maxAmount?.message}
					</ErrorMessage>
				</Box>
			</Flex>
		</Box>
	);
};

export default OrderLimit;
