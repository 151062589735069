import ForgotPassword from '../components/Authentication/ForgotPassword';
import GoogleRecaptcha from '../components/UI/GoogleRecaptcha';

export default function ForgotPasswordPage() {
	return (
		<GoogleRecaptcha>
			<ForgotPassword />
		</GoogleRecaptcha>
	);
}
