import BigNumber from 'bignumber.js';
import { AdOrderType } from 'skychain-p2p-trading-js/lib/dto/ad-order.dto';
import { AdOrderEntity } from 'skychain-p2p-trading-js/lib/entities/ad-order.entity';
import { DataBuySellTable } from '../components/P2PBuySell/interface';
import { UserEntity } from 'user-identity-js/lib/entities';
import { PaymentMethodEntity } from 'skychain-p2p-trading-js/lib/entities/payment-method.entity';
import { getUserName } from './user';
import { formatCurrency } from '.';
export const convertOrderType = (type: AdOrderType | undefined): string => {
	switch (type) {
		case AdOrderType.BUY:
			return 'Buy';
		case AdOrderType.SELL:
			return 'Sell';
		default:
			return 'Unknown';
	}
};
export const convertToDataTableAds = (
	data: AdOrderEntity,
	users: UserEntity[] | undefined,
	paymentMethods: PaymentMethodEntity[] | undefined
): DataBuySellTable => {
	const user =
		users !== undefined
			? users.find((user) => user.id === data.user)
			: undefined;
	const paymentMethod =
		paymentMethods !== undefined
			? paymentMethods.find(
					(paymentMethod) => paymentMethod.id === data.paymentMethod
			  )
			: undefined;
	return {
		...data,
		key: data.id,
		name: getUserName(user),
		price: data.priceValue ? data.priceValue.toString() : '',
		available: new BigNumber(data?.totalAmount || 0)
			.minus(data.executedAmount)
			.minus(data.pendingAmount)
			.toString(),

		limit: `${formatCurrency(
			new BigNumber(data.minAmount || 0)
				.multipliedBy(data.priceValue || 0)
				.toNumber(),
			2
		)} ${data.fiatSymbol} - ${formatCurrency(
			new BigNumber(data.maxAmount || 0)
				.multipliedBy(data.priceValue || 0)
				.toNumber(),
			2
		)} ${data.fiatSymbol}`,
		payment: 'Bank transfer',
		paymentType: 'Bank transfer',
		avatar: user?.avatar || '/images/avatar.svg',
		assetSymbol: data.assetSymbol || '',
		fiatSymbol: data.fiatSymbol || '',
		paymentTimeLimit: `${data.timeLimit?.toString() || '-'} minutes`,
		paymentMethod: paymentMethod?.bankName || '',
		termsAndConditions: [data?.term || ''],
		type: data.type,
	};
};
