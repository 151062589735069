import React, { useState } from 'react';
import { AdOrderType } from 'skychain-p2p-trading-js/lib/dto';
import TabsUI from '../../UI/TabsUI';

const TabsAdManagement = ({
	setType,
}: {
	setType: (type: AdOrderType) => void;
}) => {
	const tabs = ['Buy AD', 'Sell AD'];
	const [tabSelected, setTabSelected] = useState<string>(tabs[0]);
	const handleSelectTab = (tab: string) => {
		setTabSelected(tab);
		switch (tab) {
			case tabs[0]:
				setType(AdOrderType.BUY);
				break;
			case tabs[1]:
				setType(AdOrderType.SELL);
				break;

			default:
				break;
		}
	};
	return (
		<TabsUI
			selectedTab={tabSelected}
			tabs={tabs}
			onSelectedTab={handleSelectTab}
		/>
	);
};

export default TabsAdManagement;
