import {
	Box,
	Flex,
	Img,
	Modal,
	ModalCloseButton,
	ModalOverlay,
	Show,
	Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
	StyledModalBody,
	StyledModalContent,
	StyledModalHeader,
} from '../Wallets/DepositModal/styles';
import Filter from './Filter';
import { ColumnsAdsTable } from './interface';
import { DataAdsTable } from './interface';
import BuySellTable from '../UI/BuySellTable';
import { AdsBlock, PrimaryText, SecondaryText } from './AdsData';
import { CURRENCY } from '../../constants/cryptos';
import { PageOptions } from 'user-identity-js/lib/dto';
import { AdOrderStatus, QueryAdOrder } from 'skychain-p2p-trading-js/lib/dto';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { P2PTradingClient } from 'skychain-p2p-trading-js';
import { AdOrderEntity } from 'skychain-p2p-trading-js/lib/entities';
import Pagination from '../UI/Pagination';
import { convertOrderType } from '../../utils/ads';

export default function CloseAdsModal({
	isOpen,
	onClose,
}: {
	isOpen: boolean;
	onClose: () => void;
}) {
	const p2pTradingClient = useSelector(
		(state: RootState) => state.system.p2pTradingClient
	);
	const [dataTable, setDataTable] = React.useState<DataAdsTable[]>([]);
	const [params, setParams] = useState<PageOptions>({
		page: 1,
		pageSize: 10,
	});
	const [totalRecord, setTotalRecord] = React.useState(0);
	const [query, setQuery] = useState<QueryAdOrder>({
		statuses: AdOrderStatus.CANCELED,
	});
	useEffect(() => {
		getAds(p2pTradingClient, query, params);
	}, [p2pTradingClient, query, params]);

	const getAds = async (
		p2pTradingClient: P2PTradingClient,
		query?: QueryAdOrder,
		params?: PageOptions
	) => {
		if (!p2pTradingClient) return;
		try {
			const res = await p2pTradingClient.getAdOrders(query, params);
			const data = res.data.map((item: AdOrderEntity) => ({
				key: item.id,
				currency: item.assetSymbol || '',
				amount: `${item.totalAmount} ${item.assetSymbol}`,
				limit: `${item.minAmount} - ${item.maxAmount}`,
				type: convertOrderType(item.type) as any,
				status: item.status
					? item.status.charAt(0).toUpperCase() + item.status?.slice(1)
					: '',
				bank: 'bank',
			}));
			setDataTable(data);
			setTotalRecord(res.meta.totalRecord || 0);
		} catch (error) {
			console.log('getAds', error);
		}
	};

	const columns: ColumnsAdsTable[] = [
		{
			key: 'cryptos',
			title: 'Cryptos',
			isNumeric: false,
			align: 'start',
			render: (data: any) => (
				<Flex gap="10px" alignItems={'center'}>
					<Img src={CURRENCY[data['currency']]?.icon} />
					<Flex direction={'column'} gap={'5px'}>
						<PrimaryText>{CURRENCY[data['currency']]?.symbol}</PrimaryText>
						<SecondaryText>{CURRENCY[data['currency']]?.name}</SecondaryText>
					</Flex>
				</Flex>
			),
		},
		{
			key: 'amount',
			title: 'Amount',
			isNumeric: false,
			align: 'start',
		},
		{
			key: 'limit',
			title: 'Limit',
			isNumeric: false,
			align: 'start',
		},
		{
			key: 'types',
			title: 'Types',
			isNumeric: false,
			align: 'start',
			render: (data: any) => (
				<Text
					color={data['type'] === 'buy' ? '#03A66D' : '#CF304A'}
					fontSize={{ base: '14px' }}
					textTransform={'capitalize'}
				>
					{data['type']}
				</Text>
			),
		},
		{
			key: 'status',
			title: 'Status',
			isNumeric: true,
			align: 'center',
		},
	];

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<StyledModalContent>
					<StyledModalHeader>Close Ads</StyledModalHeader>
					<ModalCloseButton />
					<StyledModalBody maxW={'1212px'} w={'90vw'}>
						<Filter setQuery={setQuery} query={query} />
						<Box>
							<Show above="sm">
								<BuySellTable
									dataTable={dataTable}
									columns={columns}
									isBorder
									isCollapse
								/>
							</Show>

							<Show below="sm">
								{dataTable?.length > 0 ? (
									dataTable.map((data) => (
										<AdsBlock key={data.key} data={data} />
									))
								) : (
									<Box>No Data</Box>
								)}
							</Show>
						</Box>
						<Flex justifyContent={'center'} paddingBottom={'30px'}>
							<Pagination
								handleChangePage={(page) => {
									setParams({
										...params,
										page,
									});
								}}
								page={params.page || 1}
								pagination={{
									total: totalRecord,
									pageSize: params.pageSize || 10,
								}}
							/>
						</Flex>
					</StyledModalBody>
				</StyledModalContent>
			</Modal>
		</>
	);
}
