import React from 'react';
import {
	COInformation,
	FooterFlex,
	FooterHeading,
	FooterTitle,
	FooterWrapper,
	SecondaryText,
} from './styles';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes/config';
import { useTranslation } from 'react-i18next';

const socials = [
	{ name: 'facebook', href: '' },
	{ name: 'twitter', href: '' },
	{ name: 'instagram', href: '' },
	{ name: 'discord', href: '' },
];

export default function Footer() {
	const { t } = useTranslation('translation', { keyPrefix: 'Footer' });
	return (
		<Box>
			<FooterWrapper padding={{ base: '30px 18px', lg: '80px 20px' }}>
				<FooterFlex
					gap={{ lg: '130px', base: '10px' }}
					direction={{ lg: 'row', base: 'column' }}
					justifyContent={{ base: 'space-between' }}
				>
					<Box textAlign={{ base: 'center', lg: 'left' }}>
						<Image
							w={'120px'}
							src="/images/logo.svg"
							alt="logo"
							margin={{ base: 'auto', lg: 0 }}
						/>
						<FooterTitle margin={{ base: '10px 0', lg: '20px 0 0 0' }}>
							{t('Trade globally, securely, and instantly with P2P crypto.')}
						</FooterTitle>
						<SecondaryText>
							{t('Buy, trade, and hold 350+ cryptocurrencies on Krypto8')}
						</SecondaryText>
					</Box>

					<Flex
						gap={{ lg: '130px', base: '20px' }}
						justifyContent={{ lg: 'flex-start', base: 'space-between' }}
					>
						<Box>
							<FooterHeading>{t('Categories')}</FooterHeading>

							<Link to={ROUTES.P2P_BUY_SELL}>
								<SecondaryText>{t('Buy Sell')}</SecondaryText>
							</Link>
							{/* <SecondaryText>Metagames</SecondaryText> */}
							{/* <SecondaryText>Support</SecondaryText> */}
							{/* <SecondaryText>FAQs</SecondaryText> */}
						</Box>

						{/* <Box textAlign={{ base: 'right', lg: 'left' }}>
							<FooterHeading>Quick Links</FooterHeading>

							<SecondaryText>Marketplace</SecondaryText>
							<SecondaryText>Pitch deck</SecondaryText>
							<SecondaryText>Tokenomics</SecondaryText>
							<SecondaryText>Download</SecondaryText>
						</Box> */}
						<Box textAlign={{ base: 'right', lg: 'left' }}>
							<FooterHeading>{t('Quick Links')}</FooterHeading>

							<Link to={ROUTES.ABOUT_US}>
								<SecondaryText>{t('About us')}</SecondaryText>
							</Link>
							<Link to={ROUTES.PRIVACY_POLICY}>
								<SecondaryText>{t('Privacy policy')}</SecondaryText>
							</Link>
							<Link to={ROUTES.RISH_DISCLAIMER}>
								<SecondaryText>{t('Rish disclaimer')}</SecondaryText>
							</Link>
							<Link to={ROUTES.USER_AGREEMENT}>
								<SecondaryText>{t('User agreement')}</SecondaryText>
							</Link>
						</Box>
					</Flex>

					{/* <Box textAlign={{ base: 'center', lg: 'left' }}>
						<FooterHeading>Get in touch</FooterHeading>

						<SecondaryText>
							For product support or questions <br /> please join our Social:
						</SecondaryText>

						<Flex
							gap={'20px'}
							mt={'15px'}
							justifyContent={{ base: 'center', lg: 'flex-start' }}
						>
							{socials.map((social) => (
								<a
									key={social.name}
									href={social.href}
									target={'_blank'}
									rel="noreferrer"
								>
									<img alt="" src={`/images/socials/${social.name}.svg`} />
								</a>
							))}
						</Flex>
					</Box> */}
				</FooterFlex>
			</FooterWrapper>

			<COInformation>
				<Text fontSize={{ base: '12px', lg: '16px' }}>
					{t('Copyright © 2023 Krypto8. All rights reserved')}
				</Text>
			</COInformation>
		</Box>
	);
}
