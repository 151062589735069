import styled from '@emotion/styled';
import TextGradient from '../UI/TextGradient';
import StepArrow from '../Icons/StepArrow';
import { useTranslation } from 'react-i18next';
import { TRANSLATION } from '../../constants';

const StepsWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-around;
	gap: 20px;
`;
const StepWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 6px;
`;
const StepNumber = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	border: 1px solid #fcd535;
	width: 31px;
	height: 31px;
`;
const StepDescription = styled.div`
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	text-align: center;
	color: #737283;
	max-width: 170px;
`;
const StepArrowWrapper = styled.div`
	min-width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
`;
const Wrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-around;
	gap: 20px;
`;
interface StepsProps extends React.HTMLAttributes<HTMLDivElement> {
	steps: Step[];
}
const Steps: React.FC<StepsProps> = ({ steps, ...props }: StepsProps) => {
	const { t } = useTranslation(TRANSLATION, {
		keyPrefix: 'Wallet.deposit_step',
	});
	return (
		<StepsWrapper {...props}>
			{steps.map((step: Step, index: number) => (
				<Wrapper key={step.stepNumber}>
					<StepWrapper>
						<StepNumber>
							<TextGradient>{step.stepNumber}</TextGradient>
						</StepNumber>
						<TextGradient>{t(`title${step.stepNumber}`)}</TextGradient>
						<StepDescription>
							{t(`description${step.stepNumber}`)}
						</StepDescription>
					</StepWrapper>
					{index !== steps.length - 1 && (
						<StepArrowWrapper>
							<StepArrow />
						</StepArrowWrapper>
					)}
				</Wrapper>
			))}
		</StepsWrapper>
	);
};

export default Steps;
