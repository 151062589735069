import {
	Box,
	Flex,
	Modal,
	ModalCloseButton,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
	StyledModalBody,
	StyledModalContent,
	StyledModalHeader,
} from '../Wallets/DepositModal/styles';
import { PaymentMethodEntity } from 'skychain-p2p-trading-js/lib/entities/payment-method.entity';
import Checkbox from '../UI/Checkbox';
import { NextButton, PreviousButton } from '../P2PAds/PostAd/PostAdModal';

export default function ConfirmPaymentModal({
	isOpen,
	onClose,
	paymentMethod,
	counterpart,
	onConfirmPayment,
}: {
	isOpen: boolean;
	onClose: () => void;
	paymentMethod: PaymentMethodEntity | undefined;
	counterpart: string;
	onConfirmPayment: () => void;
}) {
	const [confirmPayment, setConfirmPayment] = useState(false);

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setConfirmPayment(event.target.checked);
	};
	return (
		<>
			{paymentMethod && (
				<Modal isCentered isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<StyledModalContent>
						<StyledModalHeader>Payment Confirmation</StyledModalHeader>
						<ModalCloseButton />
						<StyledModalBody maxW={'548px'} w={'90vw'}>
							<Flex direction={'column'} gap={'30px'}>
								<Text fontSize={'14px'} lineHeight={'24px'} color={'#737283'}>
									Please confirm that you have successfully transferred the
									money to the seller through the following payment method
									before clicking on the “Transferred, notifyy seller” button.
								</Text>
								<Flex direction={'column'}>
									<Text mb={'11px'}>Bank Transfer:</Text>
									<Flex alignItems={'center'} justifyContent={'space-between'}>
										<Text
											fontSize={'14px'}
											lineHeight={'24px'}
											color={'#737283'}
										>
											Name
										</Text>
										<Text fontSize={'14px'} lineHeight={'24px'}>
											{paymentMethod.fullName}
										</Text>
									</Flex>
									<Flex alignItems={'center'} justifyContent={'space-between'}>
										<Text
											fontSize={'14px'}
											lineHeight={'24px'}
											color={'#737283'}
										>
											Bank account number
										</Text>
										<Text fontSize={'14px'} lineHeight={'24px'}>
											{paymentMethod.bankAccountNumber}
										</Text>
									</Flex>
									<Flex alignItems={'center'} justifyContent={'space-between'}>
										<Text
											fontSize={'14px'}
											lineHeight={'24px'}
											color={'#737283'}
										>
											Bank name
										</Text>
										<Text fontSize={'14px'} lineHeight={'24px'}>
											{paymentMethod.bankName}
										</Text>
									</Flex>
									<Flex alignItems={'center'} justifyContent={'space-between'}>
										<Text
											fontSize={'14px'}
											lineHeight={'24px'}
											color={'#737283'}
										>
											Account opening branch
										</Text>
										<Text fontSize={'14px'} lineHeight={'24px'}>
											{counterpart}
										</Text>
									</Flex>
								</Flex>
								<Flex gap={'12px'}>
									<Checkbox
										defaultChecked={confirmPayment}
										onChange={onChange}
									/>
									<Text fontSize={14} color={'#737283'}>
										I have made payment from my real name verified payment
										account consistent with my registered name on Krypto8
									</Text>
								</Flex>
								<Flex
									w={'100%'}
									justifyContent={'space-between'}
									alignItems={'center'}
								>
									<Box w={'calc(35% - 10px)'}>
										<PreviousButton onClick={onClose}>Cancel</PreviousButton>
									</Box>
									<Box w={'calc(65% - 10px)'}>
										<NextButton
											isDisabled={!confirmPayment}
											onClick={onConfirmPayment}
										>
											Confirm payment
										</NextButton>
									</Box>
								</Flex>
							</Flex>
						</StyledModalBody>
					</StyledModalContent>
				</Modal>
			)}
		</>
	);
}
