import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InputLabel } from '../../UI/InputLabel';
import AmountInput from '../../UI/AmountInput';
import SelectCustom from '../../UI/SelectCustom';
import { IdentityCertificateType } from 'user-identity-js/lib/entities/kyc.entity';
import { ConnectButton } from './Body';
import styled from '@emotion/styled';

const ErrorMessage = styled.div`
	font-size: 12px;
	line-height: 12px;
	color: #cf304a;
	margin-top: 10px;
`;

type FormData = {
	firstName: string;
	lastName: string;
	countryOrRegion: string;
	idCertificateType: IdentityCertificateType;
	idNumber: string;
};

const IdTypes = [
	{
		key: IdentityCertificateType['ID_CARD'],
		value: 'Id Card',
	},
	{
		key: IdentityCertificateType['DRIVING_LICENSE'],
		value: 'Driving License',
	},
	{
		key: IdentityCertificateType['PASSPORT'],
		value: 'Passport',
	},
] as Option[];

export default function BasicInfo({
	handleNextStep,
	data,
	countryList,
}: {
	handleNextStep: (newData: any) => void;
	data: FormData | undefined;
	countryList: Option[];
}) {
	const [idCertificateType, setIdCertificateType] = useState<
		Option | undefined
	>(IdTypes[2]);
	const [selectedCountry, setSelectedCountry] = useState<Option | undefined>();

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
		clearErrors,
		reset,
		watch,
	} = useForm<FormData>();

	useEffect(() => {
		setValue('idCertificateType', IdTypes[2].key as IdentityCertificateType);
	}, []);

	useEffect(() => {
		if (data) {
			setValue('firstName', data.firstName);
			setValue('lastName', data.lastName);
			setValue('countryOrRegion', data.countryOrRegion);
			setValue('idCertificateType', data.idCertificateType);
			setValue('idNumber', data.idNumber);
			const country = countryList.find(
				(item) => item.key === data.countryOrRegion
			);
			if (country) {
				setSelectedCountry(country);
			}
			const idType = IdTypes.find(
				(item) => item.key === data.idCertificateType
			);
			if (idType) {
				setIdCertificateType(idType);
			}
		}
	}, [data, countryList, IdTypes]);

	const onSubmit = handleSubmit((data) => {
		handleNextStep(data);
		reset();
	});

	const onSelectIdType = (option: Option) => {
		setIdCertificateType(option);
		setValue('idCertificateType', option.key as IdentityCertificateType);
		clearErrors('idCertificateType');
	};

	const onSelectCountry = (option: Option) => {
		setSelectedCountry(option);
		setValue('countryOrRegion', option.key as string);
		clearErrors('countryOrRegion');
	};

	return (
		<form onSubmit={onSubmit}>
			<Flex
				direction={'column'}
				gap={{ base: '20px' }}
				maxW={{ lg: '458px' }}
				mx={'auto'}
			>
				<Box>
					<InputLabel mb={{ base: '10px' }}>Country or Region</InputLabel>
					<SelectCustom
						{...register('countryOrRegion', {
							required: 'This field is required',
						})}
						value={selectedCountry}
						list={countryList}
						onSelect={onSelectCountry}
					/>
					{errors.countryOrRegion?.message && (
						<ErrorMessage>{errors.countryOrRegion.message}</ErrorMessage>
					)}
				</Box>

				<Box>
					<InputLabel mb={{ base: '10px' }}>Certified ID Type</InputLabel>
					<SelectCustom
						{...register('idCertificateType', {
							required: 'This field is required',
						})}
						value={idCertificateType}
						list={IdTypes}
						onSelect={onSelectIdType}
					/>
					{errors.idCertificateType?.message && (
						<ErrorMessage>{errors.idCertificateType.message}</ErrorMessage>
					)}
				</Box>

				<Box>
					<InputLabel mb={{ base: '10px' }}>First Name</InputLabel>
					<AmountInput
						placeHolder={'Enter your first name...'}
						register={register('firstName', {
							required: 'This field is required',
						})}
						expandW={'7.5rem'}
						placeholderColor="#959595"
						textColor="#ffffff"
					/>
					{errors.firstName?.message && (
						<ErrorMessage>{errors.firstName.message}</ErrorMessage>
					)}
				</Box>

				<Box>
					<InputLabel mb={{ base: '10px' }}>Last Name</InputLabel>
					<AmountInput
						placeHolder={'Enter your last name...'}
						register={register('lastName', {
							required: 'This field is required',
						})}
						expandW={'7.5rem'}
						placeholderColor="#959595"
						textColor="#ffffff"
					/>
					{errors.lastName?.message && (
						<ErrorMessage>{errors.lastName.message}</ErrorMessage>
					)}
				</Box>

				<Box>
					<InputLabel mb={{ base: '10px' }}>ID No</InputLabel>
					<AmountInput
						placeHolder={'Enter your ID'}
						register={register('idNumber', {
							required: 'This field is required',
						})}
						expandW={'7.5rem'}
						placeholderColor="#959595"
						textColor="#ffffff"
						value={watch('idNumber')}
						name={'idNumber'}
						setValue={setValue}
					/>
					{errors.idNumber?.message && (
						<ErrorMessage>{errors.idNumber.message}</ErrorMessage>
					)}
				</Box>
			</Flex>
			<Text
				textAlign={'center'}
				color={'#a2a1af'}
				fontSize={{ base: '14px', lg: '16px' }}
				// maxW={'600px'}
				// mx={'auto'}
				my={{ base: '20px' }}
				whiteSpace={{ lg: 'pre' }}
				lineHeight={{ base: '28px' }}
			>
				{`The information can‘t be modified once it has been certified, please provide real information.\n Authenticated real-name information cannot be authenticated repeatedly`}
			</Text>

			<Flex justifyContent={'center'}>
				<ConnectButton mx={'auto'} type="submit">
					Next
				</ConnectButton>
			</Flex>
		</form>
	);
}
