import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Authentication from '.';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {
	setAccount,
	setIsExecuteCaptcha,
} from '../../redux/reducers/auth.slice';
import { useToast } from '@chakra-ui/react';
import { ROUTES } from '../../routes/config';
import { USER } from '../../constants';
import { setTokenId } from '../../redux/reducers/system.slice';
import useCaptcha from '../../hooks/useCaptcha';
import GoogleRecaptcha from '../UI/GoogleRecaptcha';
import useGoogleRecaptcha from '../../hooks/useGoogleRecaptcha';
import { useTranslation } from 'react-i18next';

const Login = () => {
	const { register, handleSubmit, setValue, watch, getValues } = useForm();
	const { t } = useTranslation();

	const navigate = useNavigate();
	const toast = useToast();
	const dispatch = useDispatch();
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const [loading, setLoading] = useState(false);

	const formFields = [
		{
			key: 1,
			label: t('Email or Phone number'),
			name: 'emailOrPhone',
			type: 'text',
			placeholder: t('Enter your Email or Phone number...'),
			required: true,
		},
		{
			key: 2,
			label: t('Password'),
			name: 'password',
			type: 'password',
			placeholder: t('Enter your password...'),
			required: true,
		},
	];

	const handleLogin = async (value: any) => {
		if (loading) return;
		try {
			setLoading(true);
			const res = await userIdentity?.loginByEmail(
				value.emailOrPhone,
				value.password,
				value.captchaToken
			);
			if (res) {
				dispatch(setAccount(res));
				if (res.idToken) dispatch(setTokenId(res.idToken));
				localStorage.setItem(USER, JSON.stringify(res));
				navigate(ROUTES.WALLET.ASSET_OVERVIEW);
				return;
			}
			dispatch(setAccount(null));
		} catch (error: any) {
			dispatch(setAccount(null));
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Unknown error',
				status: 'error',
			});
		} finally {
			setLoading(false);
		}
	};
	const { handleReCaptchaVerify } = useGoogleRecaptcha(handleLogin);

	// const captchaToken = watch('captchaToken');
	// const { validateCaptcha } = useCaptcha(
	// 	captchaToken,
	// 	getValues(),
	// 	handleLogin
	// );

	const onSubmit = async (value: any) => {
		handleReCaptchaVerify(value);
	};

	return (
		<Authentication
			title={t('login')}
			titleButton={t('login')}
			subtitle={
				<>
					{t('No Account')}?{' '}
					<Link
						style={{ color: '#FFBF1A', marginLeft: '4px' }}
						to={'/register'}
					>
						{t('signup')}
					</Link>
				</>
			}
			formFields={formFields}
			register={register}
			onSubmit={handleSubmit(onSubmit)}
			forgotPassword
			loading={loading}
		/>
	);
};

export default Login;
