import {
	Input,
	InputGroup,
	InputProps,
	InputRightElement,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import { UseFormSetValue, useForm } from 'react-hook-form';
import { validateInputNumber } from '../../utils';

interface InputCustomProps extends InputProps {
	textColor?: string;
	placeholderColor?: string;
}

const InputCustom = styled(Input)<InputCustomProps>`
	border: 1px solid #ffffff1e;
	color: ${(props) => props.textColor};
	::placeholder,
	::-webkit-input-placeholder {
		color: ${(props) => props.placeholderColor || props.textColor};
	}
	:-ms-input-placeholder {
		color: ${(props) => props.placeholderColor || props.textColor};
	}
`;

interface PropsType extends InputProps {
	placeHolder?: string;
	register?: any;
	expand?: React.ReactNode;
	isReadOnly?: boolean;
	expandW?: string | number;
	value?: string | number;
	defaultValue?: string | number;
	textColor?: string;
	placeholderColor?: string;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	type?: React.HTMLInputTypeAttribute;
	name?: string;
	setValue?: UseFormSetValue<any>;
}

export default function AmountInput({
	placeHolder = '',
	register,
	expand,
	isReadOnly = false,
	expandW = '7.5rem',
	defaultValue,
	textColor = '#ffffff',
	placeholderColor = '#737283',
	setValue,
	name,
	...props
}: PropsType) {
	return (
		<InputGroup size="md">
			<InputCustom
				pr="7.5rem"
				placeholder={placeHolder}
				focusBorderColor="#FFFFFF1E"
				size={'md'}
				{...register}
				isReadOnly={isReadOnly}
				defaultValue={defaultValue}
				placeholderColor={placeholderColor}
				textColor={textColor}
				onChange={(e) => {
					if (!setValue) {
						register.onChange(e);
						return;
					}
					const value = e.target?.value;

					if (validateInputNumber(value)) {
						setValue(name || '', value);
					}
				}}
				{...props}
			/>
			{expand && (
				<InputRightElement width={expandW}>{expand}</InputRightElement>
			)}
		</InputGroup>
	);
}
