import { ExecuteOrderType } from 'skychain-p2p-trading-js/lib/dto/execute-order.dto';
import { ExecuteOrderStatus } from 'skychain-p2p-trading-js/lib/dto/execute-order.dto';

export const convertExecuteOrderType = (
	type: ExecuteOrderType | undefined,
	userCreatedOrder: boolean
) => {
	switch (type) {
		case ExecuteOrderType.BUY:
			return userCreatedOrder ? 'Buy' : 'Sell';
		case ExecuteOrderType.SELL:
			return userCreatedOrder ? 'Sell' : 'Buy';
		default:
			return 'Unknown';
	}
};

export const convertExecuteOrderStatus = (
	status: ExecuteOrderStatus | undefined
) => {
	switch (status) {
		case ExecuteOrderStatus.PENDING:
			return 'Pending';
		case ExecuteOrderStatus.WAITING:
			return 'Waiting';
		case ExecuteOrderStatus.CANCEL:
			return 'Cancel';
		case ExecuteOrderStatus.CONFIRM_PAYMENT:
			return 'Confirm Payment';
		case ExecuteOrderStatus.CONFIRM_SUCCESS:
			return 'Success';
		default:
			return 'Unknown';
	}
};
