import { configureStore } from '@reduxjs/toolkit';
import auth from './reducers/auth.slice';
import system from './reducers/system.slice';
import user from './reducers/user.slice';
import createSagaMiddleware from 'redux-saga';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
	reducer: {
		system,
		auth,
		user,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const getMetaverseJs = (state: RootState) => state.system.metaverseJs;
export const getUserIdentity = (state: RootState) => state.system.userIdentity;
export const getP2pTradingClient = (state: RootState) =>
	state.system.p2pTradingClient;
export const getGameCenterClient = (state: RootState) =>
	state.system.gameCenterClient;
export default store;
