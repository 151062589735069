import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
	AdOrderAssetSymbol,
	AdOrderFiatSymbol,
	AdOrderType,
	QueryAdOrder,
} from 'skychain-p2p-trading-js/lib/dto';
import Container from '../../layouts/Container';
import CryptoTabs from './CryptoTabs';
import FilterAndRefresh from './FilterAndRefresh';
import TradeData from './TradeData';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export default function Body() {
	const userProfile = useSelector((state: RootState) => state.user.userProfile);
	const [filterParams, setFilterParams] = useState<QueryAdOrder>({
		type: AdOrderType.SELL,
		assetSymbol: AdOrderAssetSymbol.USDT,
	});

	useEffect(() => {
		if (userProfile) {
			setFilterParams({ ...filterParams, notUsers: userProfile.id });
		}
	}, [userProfile]);

	const handleUpdateFilter = (params: QueryAdOrder) => {
		setFilterParams(params);
	};
	return (
		<div>
			<CryptoTabs
				handleUpdateFilter={handleUpdateFilter}
				filterParams={filterParams}
			/>
			<Box py={{ base: '0px', lg: '30px' }}>
				<FilterAndRefresh
					handleUpdateFilter={handleUpdateFilter}
					filterParams={filterParams}
				/>
				<Container>
					<TradeData filterParams={filterParams} />
				</Container>
			</Box>
		</div>
	);
}
