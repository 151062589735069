import { Box, Flex, Show, Text } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/config';
import ArrowNormalIcon from '../../components/Icons/ArrowNormalIcon';
import styled from '@emotion/styled';
import AccountListIcon from '../../components/Icons/Account/AccountListIcon';
import VerifyIcon from '../../components/Icons/Account/VerifyIcon';
import P2PSettingsIcon from '../../components/Icons/Account/P2PSettingsIcon';
import AdManagementIcon from '../../components/Icons/Account/AdManagementIcon';

const menus = [
	{
		key: 'account-list',
		name: 'Account List',
		icon: <AccountListIcon />,
		link: ROUTES.ACCOUNT.ACCOUNT_LIST,
	},

	{
		key: 'personal-verification',
		name: 'Personal Verification',
		icon: <VerifyIcon />,
		link: ROUTES.ACCOUNT.PERSONAL_VERIFICATION,
	},
	{
		key: 'P2P-settings',
		name: 'P2P settings',
		icon: <P2PSettingsIcon />,
		link: ROUTES.ACCOUNT.P2P_SETTINGS,
	},
	{
		key: 'ad-management',
		name: 'Ad Management',
		icon: <AdManagementIcon />,
		link: ROUTES.ACCOUNT.AD_MANAGEMENT,
	},
	// {
	// 	key: 'blacklist',
	// 	name: 'Blacklist',
	// 	icon: <BlackListIcon />,
	// 	link: ROUTES.ACCOUNT.BLACKLIST,
	// },
];

export default function AccountMenu() {
	const location = useLocation();
	return (
		<Box bg={'#09122980'} height={'100%'} width={{ base: '100%', md: '336px' }}>
			{menus.map((menu) => (
				<MenuItem
					key={menu.key}
					icon={menu.icon}
					name={menu.name}
					link={menu.link}
					isActive={location.pathname === menu.link}
				/>
			))}
		</Box>
	);
}

interface MenuItemProps {
	icon: React.ReactNode;
	name: string;
	link: string;
	isActive: boolean;
}

const ItemIcon = styled.div<any>(
	(props) => `svg {
		path {
			fill: ${!props.isActive ? '#737283' : 'white'};
		}
	}`
);

const IconWrapper = styled.div<any>(
	(props) => `svg {
		path {
			fill: ${!props.isActive ? '#737283' : 'white'};
		}
	}`
);

const MenuItem = ({ icon, name, link, isActive }: MenuItemProps) => {
	const navigate = useNavigate();
	return (
		<Box
			p={{ base: '25px 25px 25px 50px', lg: '25px 70px 25px 50px' }}
			bg={isActive ? '#091229' : 'transparent'}
			cursor={'pointer'}
			onClick={() => {
				navigate(link);
			}}
			_hover={{ background: '#091229' }}
		>
			<Flex justifyContent={'space-between'}>
				<Flex alignItems={'center'} gap={'15px'}>
					<ItemIcon isActive={isActive}>{icon}</ItemIcon>

					{/* <ItemIcon
						isActive={isActive}
						src={`/images/icons/${icon}.svg`}
						alt={icon}
					/> */}
					<Text color={isActive ? '#FFFFFF' : '#737283'}>{name}</Text>
				</Flex>
				<Show below="md">
					<IconWrapper isActive={isActive}>
						<ArrowNormalIcon />
					</IconWrapper>
				</Show>
			</Flex>
		</Box>
	);
};
