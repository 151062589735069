export default function HomeIcon(props: any) {
	return (
		<svg
			{...props}
			width={16}
			height={16}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.2866 16H10.0892C9.7155 16 9.40977 15.6935 9.40977 15.3188V12.083C9.40977 11.2656 8.76433 10.6184 7.94905 10.6184C7.13376 10.6184 6.48832 11.2656 6.48832 12.083V15.3188C6.48832 15.6935 6.18259 16 5.80892 16H0.679406C0.305732 16 0 15.6935 0 15.3188V6.59926C0 5.98616 0.305732 5.40713 0.781316 5.06652L7.60934 0.127728C7.84713 -0.0425758 8.15287 -0.0425758 8.39066 0.127728L15.2187 5.06652C15.6943 5.40713 16 5.98616 16 6.59926V15.3528C15.966 15.6935 15.6603 16 15.2866 16ZM10.7686 14.6716H14.6412V6.59926C14.6412 6.42895 14.5393 6.25865 14.4034 6.15647L7.98302 1.49015L1.56263 6.12241C1.42675 6.22459 1.32484 6.39489 1.32484 6.56519V14.6376H5.19745V12.083C5.19745 10.5503 6.45435 9.29005 7.98302 9.29005C9.51168 9.29005 10.7686 10.5503 10.7686 12.083V14.6716Z"
				fill={props.color || '#737283'}
			/>
		</svg>
	);
}
