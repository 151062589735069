import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	chakra,
	Flex,
	Img,
	Input,
	InputGroup,
	InputRightElement,
	Spinner,
	Text,
	useToast,
} from '@chakra-ui/react';
import Container from '../../layouts/Container';
import { QRCodeSVG } from 'qrcode.react';
import CopyIcon from '../Icons/CopyIcon';
import styled from '@emotion/styled';
import WarningIcon from '../Icons/WarningIcon';
import { useForm } from 'react-hook-form';
import { RootState, useAppDispatch } from '../../redux/store';
import { useSelector } from 'react-redux';
import { Password2fa } from 'user-identity-js/lib/dto';
import { ErrorMessage } from '../Wallets/TransferModal/styles';
import { fetchUserProfileRequest } from '../../redux/actions/user.action';

const CHPLAY_GG_AUTHENTICATOR =
	'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&pli=1';

const APP_STORE_GG_AUTHENTICATOR =
	'https://apps.apple.com/us/app/google-authenticator/id388497605';

const LineBox = chakra(Box, {
	baseStyle: {
		h: '1px',
		bg: 'linear-gradient(100.78deg, rgba(255, 255, 255, 0.116) 0.27%, rgba(255, 255, 255, 0.024) 102.68%)',
	},
});

const LinkRefresh = styled(Box)`
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	margin-top: 6.88px;
	padding-left: 20px;
	cursor: pointer;
	&:hover {
		filter: brightness(1.2);
	}
`;

const VerificationGoogle = () => {
	const toast = useToast();
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const dispatch = useAppDispatch();
	const account = useSelector((state: RootState) => state.auth.account);
	const userProfile = useSelector((state: RootState) => state.user.userProfile);
	const [secretKey, setSecretKey] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<Password2fa>();

	useEffect(() => {
		getSecretKey();
	}, []);
	const getSerectKeyString = (secret: string) => {
		return `otpauth://totp/${account?.email}?secret=${secret}&issuer=Krypto8`;
	};
	const getSecretKey = async () => {
		if (!userIdentity) return;
		try {
			const res = await userIdentity.createQrCodeAndSecretKey();
			setSecretKey(res.secret || '');
		} catch (error) {
			console.log('getSecretKey', error);
		}
	};
	const handleCopy = (text: string | undefined) => {
		if (!text) return;
		navigator.clipboard.writeText(text);
		toast({
			title: 'Copied!',
			status: 'info',
			duration: 2000,
			isClosable: true,
			position: 'top',
		});
	};

	const turnOn2Fa = async (data: Password2fa) => {
		if (!userIdentity || !account || loading) return;
		try {
			setLoading(true);
			const res = await userIdentity.turnOn2fa(data);
			if (res) {
				toast({
					title: 'Success!',
					description: 'Turn on 2FA successfully',
					status: 'success',
					position: 'top',
				});
				reset();
				dispatch(fetchUserProfileRequest({ userId: account.id }));
			}
		} catch (error: any) {
			console.log('onSubmit', error);
			toast({
				title: 'Error!',
				description: error?.response?.data?.message || 'Something went wrong',
				position: 'top',
			});
		} finally {
			setLoading(false);
		}
	};
	const turnOff2Fa = async (data: Password2fa) => {
		if (!userIdentity || !account || loading) return;
		try {
			setLoading(true);
			const res = await userIdentity.turnOff2fa(data);
			if (res) {
				toast({
					title: 'Success!',
					description: 'Turn off 2FA successfully',
					status: 'success',
					position: 'top',
				});
				reset();
				dispatch(fetchUserProfileRequest({ userId: account.id }));
			}
		} catch (error: any) {
			console.log('onSubmit', error);
			toast({
				title: 'Error!',
				description: error?.response?.data?.message || 'Something went wrong',
				position: 'top',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box mb={'100px'}>
			{!userProfile?.isEnableTwoFactor ? (
				<Container>
					<Text
						fontSize={'20px'}
						fontWeight={'500'}
						lineHeight={'48px'}
						mb={'14px'}
					>
						Link Google authentication
					</Text>

					<LineBox />

					<Text fontSize={'14px'} lineHeight={'28px'} mb={'12px'} mt={'20px'}>
						1. Download Google Authentication App
					</Text>
					<Text
						fontSize={'14px'}
						lineHeight={'28px'}
						mb={'12px'}
						color="#737283"
					>
						- To IOS user: Login the App Store and search for Google
						Authentication. Then, download it and follow the instruction. <br />
						- To Android user: Access the app store or mobile phone browser and
						search for “Google Authentication” app. Then, download it and follow
						the instruction.
					</Text>

					<Flex>
						<Flex
							py={{ base: '5.5px', md: '10px' }}
							px={{ base: '9.27px', md: '25.77px' }}
							bg="#091229"
							maxW="219px"
							mr={'20px'}
							onClick={() => {
								window?.open(APP_STORE_GG_AUTHENTICATOR);
							}}
						>
							<Img src="/images/icons/apple-icon.svg" mr={'19.27px'} />
							<Box>
								<Text fontSize={{ base: '12px', md: '14px' }}>
									Download on the
								</Text>
								<Text fontSize={{ base: '14px', md: '18px' }}>App Store</Text>
							</Box>
						</Flex>

						<Flex
							py={{ base: '5.5px', md: '10px' }}
							px={{ base: '9.27px', md: '25.77px' }}
							bg="#091229"
							maxW="219px"
							onClick={() => {
								window?.open(CHPLAY_GG_AUTHENTICATOR);
							}}
						>
							<Img src="/images/icons/googlePlay-icon.svg" mr={'19.27px'} />
							<Box>
								<Text fontSize={{ base: '12px', md: '14px' }}>Get it on</Text>
								<Text fontSize={{ base: '14px', md: '18px' }}>Google Play</Text>
							</Box>
						</Flex>
					</Flex>

					<LineBox mt={'30px'} mb={'20px'} />

					<Text fontSize={'14px'} lineHeight={'28px'} mb={'12px'}>
						2. Scan a QR code with Google Authenticator or enter the key
						manually
					</Text>
					<Text
						fontSize={'14px'}
						lineHeight={'28px'}
						mb={'12px'}
						color="#737283"
					>
						Open Google Authenticator, scan the QR code below or enter the
						following key manually to add a verification token
					</Text>

					<Flex>
						<Box
							bg={'white'}
							p={'8.33px'}
							maxW={'100px'}
							borderRadius={'5px'}
							mr={'20px'}
						>
							<QRCodeSVG
								style={{ width: '83.33px', height: '83.33px' }}
								value={getSerectKeyString(secretKey)}
							/>
						</Box>
						<Box>
							<InputGroup minW={{ md: '338px' }} bg="rgba(255, 255, 255, 0.05)">
								<Input color="#737283" value={secretKey} pr="3rem" />
								<InputRightElement width="4.5rem">
									<Button variant="link" onClick={() => handleCopy(secretKey)}>
										<CopyIcon />
									</Button>
								</InputRightElement>
							</InputGroup>
							<LinkRefresh onClick={() => getSecretKey()}>Refresh</LinkRefresh>
						</Box>
					</Flex>

					<LineBox mt={'30px'} mb={'20px'} />

					<Text fontSize={'14px'} lineHeight={'28px'} mb={'12px'}>
						3. Complete linking
					</Text>
					<Text
						fontSize={'14px'}
						lineHeight={'28px'}
						mb={'12px'}
						color="#737283"
						display={'flex'}
						alignItems={{ base: 'start', md: 'center' }}
					>
						<Box mr={'4px'}>
							<WarningIcon />
						</Box>{' '}
						The key is used to retrieve the Google Authenticator when the phone
						is replaced or lost. Be sure to save the above key backup before
						binding.
					</Text>

					<Box maxW={'458px'}>
						<form onSubmit={handleSubmit(turnOn2Fa)}>
							<Text mb={'11px'} color={'#737283'} mt={'20px'}>
								Password
							</Text>
							<Input
								type="password"
								bg="rgba(255, 255, 255, 0.05)"
								color="#737283"
								mb={'20px'}
								{...register('password', {
									required: 'Password is required',
								})}
							/>
							{errors?.password?.message && (
								<ErrorMessage>{errors.password.message}</ErrorMessage>
							)}
							<Text mb={'11px'} color={'#737283'}>
								Google authentication code
							</Text>
							<Input
								bg="rgba(255, 255, 255, 0.05)"
								color="#fff"
								mb={'20px'}
								{...register('twoFactorCode', {
									required: 'Two factor code is required',
								})}
								type="number"
							/>
							{errors?.twoFactorCode?.message && (
								<ErrorMessage>{errors.twoFactorCode.message}</ErrorMessage>
							)}
							<Button
								w={'100%'}
								py={'14px'}
								color={'black'}
								bg={'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)'}
								borderRadius={'36px'}
								type="submit"
								mt={'20px'}
							>
								{loading ? <Spinner /> : 'Turn on 2FA'}
							</Button>
						</form>
					</Box>
				</Container>
			) : (
				<Box padding={'20px'}>
					<Text fontSize={'20px'} my={'30px'} fontWeight={'500'}>
						Disable Google authentication
					</Text>
					<LineBox mb={'30px'} />
					<form onSubmit={handleSubmit(turnOff2Fa)}>
						<Text mb={'11px'} color={'#737283'} mt={'20px'}>
							Password
						</Text>
						<Input
							type="password"
							bg="rgba(255, 255, 255, 0.05)"
							color="#737283"
							mb={'20px'}
							{...register('password', {
								required: 'Password is required',
							})}
							placeholder="Enter your password"
						/>
						{errors?.password?.message && (
							<ErrorMessage>{errors.password.message}</ErrorMessage>
						)}
						<Text mb={'11px'} color={'#737283'}>
							Google authentication code
						</Text>
						<Input
							bg="rgba(255, 255, 255, 0.05)"
							color="#737283"
							mb={'20px'}
							{...register('twoFactorCode', {
								required: 'Two factor code is required',
							})}
							type="number"
							placeholder="Enter your Google authentication code"
						/>
						{errors?.twoFactorCode?.message && (
							<ErrorMessage>{errors.twoFactorCode.message}</ErrorMessage>
						)}
						<Button
							w={'100%'}
							py={'14px'}
							color={'black'}
							bg={'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)'}
							borderRadius={'36px'}
							type="submit"
							mt={'20px'}
						>
							{loading ? <Spinner /> : 'Turn off 2FA'}
						</Button>
					</form>
				</Box>
			)}
		</Box>
	);
};

export default VerificationGoogle;
