import { useSelector } from 'react-redux';
import Banner from '../Banner';
import { RootState } from '../../../redux/store';
import { BigNumber } from 'bignumber.js';
import { formatCurrency } from '../../../utils';
import { useTranslation } from 'react-i18next';

export default function AssetBanner() {
	const { t } = useTranslation();
	const actions = ['deposit', 'withdraw', 'transfer'] as BannerAction[];
	const userAssetBalances = useSelector(
		(state: RootState) => state.user.userAssetBalances
	);
	const gameBalance = useSelector((state: RootState) => state.user.gameBalance);

	const totalBalance = userAssetBalances
		?.filter((asset) => asset.chain !== 'GAMING')
		.reduce(
			(acc, asset) =>
				new BigNumber(acc).plus(asset.totalAssetAmount || 0).toNumber(),
			0
		);
	const valuation = {
		balance: formatCurrency(
			new BigNumber(totalBalance)
				.plus(gameBalance?.availableBalance || 0)
				.toNumber()
		),
		currency: 'USDT',
	} as Valuation;

	return (
		<Banner title={'Asset Overview'} valuation={valuation} actions={actions} />
	);
}
