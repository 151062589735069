import React from 'react';
import Container from '../../layouts/Container';
import { Box, Flex, Image, Img, List, ListItem } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const Wrapper = styled(Box)`
	margin-top: 118px;
`;

const listDesc = [
	{
		key: 'usd',
		title: 'Easy asset switching',
	},
	{
		key: 'cart',
		title: 'Buy Crypto from USD',
	},
	{
		key: 'time',
		title: 'Commit to complete your transactions within 15 minutes',
	},
];

const EasyToInvest = () => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'Home.EasyToInvest',
	});
	return (
		<Wrapper>
			<Container>
				<Flex
					flexDirection={{ base: 'column', md: 'row' }}
					justifyContent={'space-between'}
				>
					<Box data-aos="fade-right" data-aos-duration={'1500'}>
						<Img src="/images/homepage/invest.png" />
					</Box>
					<Box maxW={'548px'} textAlign={{ base: 'center', md: 'left' }}>
						<Box
							fontSize={'30px'}
							lineHeight={'48px'}
							mb={'8px'}
							data-aos="fade-left"
							data-aos-duration={'1500'}
						>
							{t('Easy to invest')}
						</Box>
						<Box
							color="#737283"
							data-aos="fade-left"
							data-aos-duration={'1500'}
						>
							{t('desc')}
						</Box>

						<List>
							{listDesc.map((desc, index) => (
								<ListItem
									key={desc.key}
									display={'flex'}
									alignItems={'center'}
									my={'34px'}
									textAlign={'left'}
									data-aos="fade-left"
									data-aos-duration={`2000`}
								>
									<Box mr={'30px'} w={'20%'}>
										<Image src={`/images/homepage/${desc.key}-icon.svg`} />
									</Box>
									<Box
										w={'80%'}
										fontSize={{ base: '18px', md: '24px' }}
										maxW={'412px'}
									>
										{t(`title${index + 1}`)}
									</Box>
								</ListItem>
							))}
						</List>
					</Box>
				</Flex>
			</Container>
		</Wrapper>
	);
};

export default EasyToInvest;
