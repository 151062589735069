import { createAction } from '@reduxjs/toolkit';

export const fetchUserAssetBalancesRequest = createAction<any>(
	'user/fetchUserAssetBalances'
);

export const fetchUserProfileRequest = createAction<any>(
	'user/fetchUserProfile'
);

export const fetchPaymentMethodsRequest = createAction<any>(
	'user/fetchPaymentMethods'
);

export const fetchGameBalanceRequest = createAction<any>(
	'user/fetchGameBalance'
);
