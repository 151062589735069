import React from 'react';
import Banner from './Banner';
import { Outlet } from 'react-router-dom';
import Type from './Type';
import { Box } from '@chakra-ui/react';

export default function BuySellLayout() {
	return (
		<Box mt={'80px'}>
			<Banner />
			<Type />
			<Outlet />
		</Box>
	);
}
