import {
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import React from 'react';

export interface Columns {
	key: string;
	title: string;
	render?: (value: any, data: any) => React.ReactNode;
	align?: 'start' | 'end' | 'center';
	isNumeric?: boolean;
}

const TableUI = ({
	dataTable,
	columns,
	isBorder = true,
}: {
	dataTable: any;
	columns: Columns[];
	isBorder: boolean;
}) => {
	return (
		<TableContainer>
			<Table variant={'simple'} colorScheme={'blackAlpha'}>
				<Thead>
					<Tr>
						{columns.map((col) => (
							<Th
								color={'#737283'}
								textTransform={'uppercase'}
								key={col.key}
								py={'32px'}
								fontSize={'14px'}
								isNumeric={col.isNumeric}
								border={'none'}
								textAlign={col.align || 'start'}
							>
								{col.title}
							</Th>
						))}
					</Tr>
				</Thead>
				<Tbody>
					{dataTable.length > 0 &&
						dataTable.map((data: any) => (
							<Tr key={data.key}>
								{columns.map((col) => (
									<Td
										key={col.key}
										borderBottom={'none'}
										borderTop={'1px solid'}
										borderColor={isBorder ? '#FFFFFF1E' : '#ffffff00'}
										isNumeric={col.isNumeric}
									>
										{col.render
											? col.render(data[col.key], data)
											: data[col.key]}
									</Td>
								))}
							</Tr>
						))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};

export default TableUI;
