import { Box, Button, chakra, Flex, Text, useToast } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { useEffect, useMemo, useState } from 'react';
import CopyIcon from '../Icons/CopyIcon';
import PrimaryButton from '../UI/PrimaryButton';
import { AdOrderEntity } from 'skychain-p2p-trading-js/lib/entities/ad-order.entity';
import { ExecuteOrderEntity } from 'skychain-p2p-trading-js/lib/entities/execute-order.entity';
import BigNumber from 'bignumber.js';
import { PaymentMethodEntity } from 'skychain-p2p-trading-js/lib/entities/payment-method.entity';
import { P2PTradingClient } from 'skychain-p2p-trading-js';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import ConfirmPaymentModal from './ConfirmPaymentModal';
import { ExecuteOrderStatus } from 'skychain-p2p-trading-js/lib/dto/execute-order.dto';
import CancelPaymentModal from './CancelPaymentModal';
import { ExecuteOrderType } from 'skychain-p2p-trading-js/lib/dto/execute-order.dto';
import ConfirmReleaseModal from './ConfirmReleaseModal';
import SercurityVerificationModal from './SercurityVerificationModal';
import { formatCurrency } from '../../utils';
import { OrderDetailUI } from '../../constants';

const Title = styled.div`
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 28px;
	margin-bottom: 10px;
`;

const TitleDeactive = styled.div`
	font-size: 16px;
	line-height: 28px;
	margin-bottom: 10px;
	color: '#fff';
`;

const DotAnchor = chakra(Box, {
	baseStyle: {
		w: '7px',
		h: '7px',
		bg: 'yellow',
		borderRadius: '100%',
	},
});

const Line = chakra(Box, {
	baseStyle: {
		borderRight: '1px dashed #737283',
		h: '100%',
	},
});

interface Props {
	adOrderDetail: AdOrderEntity;
	executeOrder: ExecuteOrderEntity;
	counterpart: string;
	refresh: () => void;
	uiType: OrderDetailUI;
}
const Information = ({
	adOrderDetail,
	executeOrder,
	counterpart,
	refresh,
	uiType,
}: Props) => {
	const toast = useToast();
	const p2pTradingClient = useSelector(
		(state: RootState) => state.system.p2pTradingClient
	);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const userProfile = useSelector((state: RootState) => state.user.userProfile);

	const [openConfirmPayment, setOpenConfirmPayment] = useState(false);
	const [openCancelPayment, setOpenCancelPayment] = useState(false);
	const [openConfirmRelease, setOpenConfirmRelease] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethodEntity>();
	const [openSecurityVerification, setOpenSecurityVerification] =
		useState(false);
	const [loading, setLoading] = useState(false);
	const listConfirmOrderInfo = useMemo(() => {
		const list = [
			{
				title: 'Amount',
				value: `${formatCurrency(
					new BigNumber(executeOrder.amount)
						.multipliedBy(executeOrder.price)
						.toFixed(4)
				)} ${adOrderDetail.fiatSymbol}`,
			},
			{
				title: 'Price',
				value: `${executeOrder.price} ${adOrderDetail.fiatSymbol}`,
			},
			{
				title: 'Quality',
				value: `${formatCurrency(executeOrder.amount)} ${
					adOrderDetail.assetSymbol
				}`,
			},
		];
		return list;
	}, [adOrderDetail]);
	useEffect(() => {
		if (p2pTradingClient && executeOrder?.paymentMethod) {
			getPaymentMethod(p2pTradingClient, executeOrder.paymentMethod);
		}
	}, [executeOrder, p2pTradingClient]);

	const getPaymentMethod = async (
		p2pTradingClient: P2PTradingClient,
		paymentMethodId: string
	) => {
		try {
			const res = await p2pTradingClient.filterPublicMethods(paymentMethodId);
			if (res?.length > 0) {
				setPaymentMethod(res[0]);
			}
		} catch (error: any) {
			console.log('getPaymentMethod', error);
		}
	};

	const confirmTransfered = async () => {
		if (!p2pTradingClient || !executeOrder || loading) return;
		try {
			setLoading(true);
			await p2pTradingClient.confirmPaymentExecutedOrder(executeOrder.id);
			setOpenConfirmPayment(false);
			refresh();
		} catch (error: any) {
			console.log('confirmTransfered', error);
			toast({
				title: 'Error!',
				description: error?.response?.data?.message || 'transfer error',
				status: 'error',
				duration: 2000,
				isClosable: true,
				position: 'top',
			});
		} finally {
			setLoading(false);
		}
	};

	const cancelOrder = async () => {
		if (!p2pTradingClient || !executeOrder || loading) return;
		try {
			setLoading(true);
			await p2pTradingClient.cancelExecutedOrder(executeOrder.id, 'reject');
			setOpenCancelPayment(false);
			refresh();
		} catch (error: any) {
			console.log('cancelOrder', error);
			toast({
				title: 'Error!',
				description: error?.response?.data?.message || 'cancel error',
				status: 'error',
				duration: 2000,
				isClosable: true,
				position: 'top',
			});
		} finally {
			setLoading(false);
		}
	};

	const confirmRelease = async (otp: string) => {
		if (!p2pTradingClient || !executeOrder || !userIdentity || loading) return;
		try {
			setLoading(true);
			const res = await userIdentity.getTokenTwoFactor({ tfaCode: otp });
			if (res) {
				await p2pTradingClient.confirmSuccessExecutedOrder(
					executeOrder.id,
					res.data.idToken
				);
			}
			refresh();
			setOpenSecurityVerification(false);
		} catch (error: any) {
			console.log('confirmRelease', error);
			toast({
				title: 'Error!',
				description: error?.response?.data?.message || 'release error',
				status: 'error',
				duration: 2000,
				isClosable: true,
				position: 'top',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleCopy = (text: string | undefined) => {
		if (!text) return;
		navigator.clipboard.writeText(text);
		toast({
			title: 'Copied!',
			status: 'info',
			duration: 2000,
			isClosable: true,
			position: 'top',
		});
	};
	return (
		<Box p={'10px'}>
			<StepLayout status={executeOrder?.status} title={'Confirm Order Info'}>
				<Flex justifyContent={'space-between'}>
					{listConfirmOrderInfo.map((item) => (
						<Box key={item.title}>
							<Text color={'#737283'} lineHeight={'28px'} fontSize={'14px'}>
								{item.title}
							</Text>
							<Text fontSize={{ base: '16px', md: '18px' }} lineHeight={'28px'}>
								{item.value}
							</Text>
						</Box>
					))}
				</Flex>
			</StepLayout>
			{executeOrder.status !== ExecuteOrderStatus.CANCEL ? (
				<>
					<StepLayout
						title={
							uiType == OrderDetailUI.BUY
								? 'Payment Method'
								: `Confirm that the payment is made using the buyer’s real name (${counterpart})`
						}
					>
						<Text fontSize={'14px'} color={'#737283'}>
							Reference Message
						</Text>
						<Flex alignItems={'center'} mb={'1rem'}>
							<Text mr={'4px'} fontSize={'18px'}>
								{executeOrder?.referenceMessage}
							</Text>
							<CopyIcon
								style={{ cursor: 'pointer' }}
								onClick={() => handleCopy(executeOrder?.referenceMessage)}
							/>
						</Flex>
						{paymentMethod && <PaymentMethods paymentMethod={paymentMethod} />}
					</StepLayout>
					<StepLayout
						isLine={false}
						title={
							uiType === OrderDetailUI.BUY
								? 'After transferring the funds, click on the “Transferred, notify seller” button'
								: 'After confirming the payment, be sure to click the “Payment received” button.'
						}
					>
						<></>
					</StepLayout>
				</>
			) : (
				<>
					<StepLayout
						isLine={false}
						status={executeOrder?.status}
						title="Payment Method"
					>
						<Text fontSize={'14px'} color={'#737283'}>
							{`Payment method can't be displayed for this order.`}
						</Text>
					</StepLayout>
				</>
			)}
			{uiType === OrderDetailUI.BUY ? (
				<>
					<Flex
						maxW={'516px'}
						justifyContent={'space-between'}
						flexDir={{ base: 'column', md: 'row' }}
					>
						{executeOrder?.status === ExecuteOrderStatus.WAITING && (
							<>
								<PrimaryButton
									borderRadius={'4px'}
									maxW={{ base: '100%', md: '314px' }}
									fontWeight={'500'}
									w={'100%'}
									mr={'30px'}
									mb={{ base: '10px', md: '0' }}
									onClick={() => setOpenConfirmPayment(true)}
								>
									Transferred, notify seller
								</PrimaryButton>
								<Button
									w={'100%'}
									maxW={{ base: '100%', md: '202px' }}
									borderRadius={'4px'}
									fontWeight={'500'}
									onClick={() => setOpenCancelPayment(true)}
								>
									Cancel Order
								</Button>
							</>
						)}
					</Flex>
				</>
			) : (
				<>
					{executeOrder.status === ExecuteOrderStatus.CONFIRM_PAYMENT && (
						<Flex
							maxW={'516px'}
							justifyContent={'space-between'}
							flexDir={{ base: 'column', md: 'row' }}
						>
							<PrimaryButton
								borderRadius={'4px'}
								maxW={{ base: '100%', md: '314px' }}
								fontWeight={'500'}
								w={'100%'}
								mr={'30px'}
								mb={{ base: '10px', md: '0' }}
								onClick={() => setOpenConfirmRelease(true)}
							>
								Payment received
							</PrimaryButton>
						</Flex>
					)}
				</>
			)}

			<ConfirmPaymentModal
				onConfirmPayment={confirmTransfered}
				isOpen={openConfirmPayment}
				onClose={() => setOpenConfirmPayment(false)}
				paymentMethod={paymentMethod}
				counterpart={counterpart}
			/>
			<CancelPaymentModal
				isOpen={openCancelPayment}
				onClose={() => setOpenCancelPayment(false)}
				onCancelPayment={cancelOrder}
			/>
			<ConfirmReleaseModal
				isOpen={openConfirmRelease}
				onClose={() => setOpenConfirmRelease(false)}
				onConfirmRelease={() => {
					setOpenSecurityVerification(true);
					setOpenConfirmRelease(false);
				}}
			/>
			<SercurityVerificationModal
				isOpen={openSecurityVerification}
				onClose={() => setOpenSecurityVerification(false)}
				onSubmit={confirmRelease}
			/>
		</Box>
	);
};

const StepLayout = ({
	children,
	title,
	isLine = true,
	status,
}: {
	children: React.ReactNode;
	title: string;
	isLine?: boolean;
	status?: ExecuteOrderStatus;
}) => {
	return (
		<Flex h={'auto'} w={'100%'}>
			<Flex flexDir={'column'} alignItems={'center'} h={'auto'} mr={'21px'}>
				<DotAnchor
					bg={status === ExecuteOrderStatus.CANCEL ? 'white' : 'yellow'}
				/>
				{isLine && <Line />}
			</Flex>
			<Box mt={'-10px'} mb={'20px'} flexGrow={1} maxWidth={'516px'}>
				{status !== ExecuteOrderStatus.CANCEL ? (
					<Title>{title}</Title>
				) : (
					<TitleDeactive>{title}</TitleDeactive>
				)}
				{children}
			</Box>
		</Flex>
	);
};

interface PaymentMethodsProps {
	paymentMethod: PaymentMethodEntity;
}
const PaymentMethods = ({ paymentMethod }: PaymentMethodsProps) => {
	const toast = useToast();

	const handleCopy = (text: string | undefined) => {
		if (!text) return;
		navigator.clipboard.writeText(text);
		toast({
			title: 'Copied!',
			status: 'info',
			duration: 2000,
			isClosable: true,
			position: 'top',
		});
	};
	return (
		<>
			{paymentMethod && (
				<Box p={'10px'} bg={'#070D1E'} mb={'10px'}>
					<Flex>
						<Box
							w={'6px'}
							h={'6px'}
							border={'1px solid white'}
							borderRadius={'100%'}
							mr={'7px'}
							mt={'10px'}
						/>
						<Box flexGrow={1}>
							<Text fontSize={'14px'} lineHeight={'28px'}>
								Bank Transfer
							</Text>
							<Flex
								flexDir={{ base: 'column', md: 'row' }}
								justifyContent={'space-between'}
								w={'100%'}
							>
								<Box>
									<Box>
										<Text
											color={'#737283'}
											fontSize={'12px'}
											lineHeight={'28px'}
										>
											Full name
										</Text>
										<Text
											display={'flex'}
											fontSize={'14px'}
											lineHeight={'28px'}
										>
											<Box mr={'4px'}>{paymentMethod.fullName}</Box>
											<Box mt={'2px'}>
												<CopyIcon
													style={{ cursor: 'pointer' }}
													onClick={() => handleCopy(paymentMethod.fullName)}
												/>
											</Box>
										</Text>
									</Box>
									<Box>
										<Text
											color={'#737283'}
											fontSize={'12px'}
											lineHeight={'28px'}
										>
											Account Number
										</Text>
										<Text
											display={'flex'}
											fontSize={'14px'}
											lineHeight={'28px'}
										>
											<Box mr={'4px'}>{paymentMethod.bankAccountNumber}</Box>
											<Box mt={'2px'}>
												<CopyIcon
													style={{ cursor: 'pointer' }}
													onClick={() =>
														handleCopy(paymentMethod.bankAccountNumber)
													}
												/>
											</Box>
										</Text>
									</Box>
								</Box>
								<Flex
									flexDir={'column'}
									alignItems={{ base: 'flex-start', md: 'flex-end' }}
								>
									<Box>
										<Text
											color={'#737283'}
											fontSize={'12px'}
											lineHeight={'28px'}
											textAlign={{ base: 'left', md: 'right' }}
										>
											Bank Name
										</Text>
										<Text
											display={'flex'}
											fontSize={'14px'}
											lineHeight={'28px'}
										>
											<Box mr={'4px'}>{paymentMethod.bankName}</Box>
											<Box mt={'2px'}>
												<CopyIcon
													style={{ cursor: 'pointer' }}
													onClick={() => handleCopy(paymentMethod.bankName)}
												/>
											</Box>
										</Text>
									</Box>
								</Flex>
							</Flex>
						</Box>
					</Flex>
				</Box>
			)}
		</>
	);
};

export default Information;
