import React, { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Authentication from '.';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { ROUTES } from '../../routes/config';
import useCaptcha from '../../hooks/useCaptcha';
import useGoogleRecaptcha from '../../hooks/useGoogleRecaptcha';
import { useTranslation } from 'react-i18next';

const Register = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const ref = searchParams.get('ref');
	const [loading, setLoading] = React.useState(false);
	const { register, handleSubmit, setValue, watch, getValues, reset } =
		useForm();
	const { t } = useTranslation();

	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);

	const formFields = [
		{
			key: 1,
			label: t('Email or Phone number'),
			name: 'emailOrPhone',
			type: 'email',
			placeholder: t('Enter your Email or Phone number...'),
			required: true,
		},
		{
			key: 2,
			label: t('Password'),
			name: 'password',
			type: 'password',
			placeholder: t('Enter your password...'),
			required: true,
		},
		{
			key: 3,
			label: t('Invitation code (Optional)'),
			name: 'refCode',
			type: 'text',
			placeholder: t('Enter your code...'),
		},
		{
			key: 4,
			label: (
				<>
					{t('I have read & agreed Krypto8')},
					<Link style={{ color: '#FFBF1A', marginLeft: '4px' }} to="/">
						{t('Terms of Service')}
					</Link>
				</>
			),
			name: 'agreeTerms',
			type: 'checkbox',
			required: true,
		},
	];

	const handleSignUp = async (value: any) => {
		if (!userIdentity || loading) return;
		try {
			setLoading(true);
			const res = await userIdentity.registerByEmail(
				value.emailOrPhone,
				value.password,
				value.refCode,
				value.captchaToken
			);
			if (res && !!res.id) {
				toast({
					title: 'Register successfully',
					status: 'success',
				});
				reset();
				navigate(`${ROUTES.SECURITY_SETTING}?email=${res.email}`);
			}
		} catch (error: any) {
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Register failed',
				status: 'error',
			});
		} finally {
			setLoading(false);
		}
	};
	const { handleReCaptchaVerify } = useGoogleRecaptcha(handleSignUp);

	// const captchaToken = watch('captchaToken');
	// const { validateCaptcha } = useCaptcha(
	// 	captchaToken,
	// 	getValues(),
	// 	handleSignUp
	// );

	const onSubmit = async (value: any) => {
		handleReCaptchaVerify(value);
	};

	useEffect(() => {
		if (ref) {
			reset({ refCode: ref });
		}
	}, [ref]);
	return (
		<Authentication
			title={'Welcome to register'}
			titleButton={'Sign up'}
			subtitle={
				<>
					{t('Already have an account')}?
					<Link style={{ color: '#FFBF1A', marginLeft: '4px' }} to="/login">
						{t('login')}
					</Link>
				</>
			}
			onSubmit={handleSubmit(onSubmit)}
			register={register}
			formFields={formFields}
			// isCaptcha
			// setValue={setValue}
			loading={loading}
		/>
	);
};

export default Register;
