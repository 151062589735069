import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserAuthEntity } from 'user-identity-js/lib/entities/user.entity';
import { USER } from '../../constants';
interface AuthState {
	account: UserAuthEntity | null;
	isExecuteCaptcha: boolean;
}

const initialState = {
	account: {},
	isExecuteCaptcha: false,
} as AuthState;

const authSlice = createSlice({
	name: 'authSlice',
	initialState,
	reducers: {
		setAccount: (state, action: PayloadAction<UserAuthEntity | null>) => {
			state.account = action.payload;
		},
		logout: (state) => {
			state.account = null;
			localStorage.removeItem(USER);
		},
		setIsExecuteCaptcha: (state, action: PayloadAction<boolean>) => {
			state.isExecuteCaptcha = action.payload;
		},
	},
});

export const { setAccount, logout, setIsExecuteCaptcha } = authSlice.actions;
export default authSlice.reducer;
