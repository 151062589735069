import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const GoogleRecaptcha = ({ children }: { children: React.ReactNode }) => {
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA || ''}
			scriptProps={{
				async: false,
				defer: false,
				appendTo: 'head',
				nonce: undefined,
			}}
		>
			{children}
		</GoogleReCaptchaProvider>
	);
};

export default GoogleRecaptcha;
