import {
	Box,
	Flex,
	Modal,
	ModalCloseButton,
	ModalOverlay,
	useToast,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setModal } from '../../../redux/reducers/system.slice';
import Steps from '../Steps';
import QuestionIcon from '../../Icons/QuestionIcon';
import SelectCustom from '../../UI/SelectCustom';
import { useEffect, useMemo, useState } from 'react';
import WarningIcon from '../../Icons/WarningIcon';
import { QRCodeSVG } from 'qrcode.react';
import TextGradient from '../../UI/TextGradient';
import MetaverseClient from 'metaverse-js';
import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import { Responsive } from 'metaverse-js/lib/index';
import { format } from 'date-fns';

import {
	ChainItem,
	ChainListWrapper,
	DepositAddressInput,
	DepositAddressWrapper,
	DepositContent,
	DepositHistory,
	Header,
	Label,
	LeftColumn,
	QRCodeWrapper,
	Retangle,
	RightColumn,
	Row,
	StepPanel,
	StyledCopyIcon,
	StyledDepositHistoryDesktop,
	StyledDepositHistoryMobile,
	StyledModalBody,
	StyledModalContent,
	StyledModalHeader,
	Title,
	Warning,
	WarningContent,
} from './styles';
import { DEPOSIT_STEPS } from '../../../constants/deposit';
import { mapAssetsToOptions, mapChainsToOptions } from '../../../utils/asset';
import useWalletAddress from '../../../hooks/useWalletAddress';
import { TRANSLATION, paginationDefault } from '../../../constants';
import LoadingUI from '../../UI/LoadingUI';
import Pagination from '../../UI/Pagination';
import { formatCurrency } from '../../../utils';
import { useTranslation } from 'react-i18next';

const DepositModal: React.FC = () => {
	const toast = useToast();
	const modal = useSelector((state: RootState) => state.system.modal);
	const chain = useSelector((state: RootState) => state.system.chain);
	const myAssetBalances = useSelector(
		(state: RootState) => state.user.userAssetBalances
	);
	const { t } = useTranslation(TRANSLATION, {
		keyPrefix: 'Wallet',
	});
	const dispatch = useDispatch();
	const metaverseJs = useSelector(
		(state: RootState) => state.system.metaverseJs
	);
	const supportedChains = useSelector(
		(state: RootState) => state.system.supportedChains
	);
	const supportedAssets = useSelector(
		(state: RootState) => state.system.supportedAssets
	);
	const [selectedToken, setSelectedToken] = useState<Option | undefined>();
	const [selectedChain, setSelectedChain] = useState<Option | undefined>();
	const [transactions, setTransactions] = useState<TransactionHistory[]>([]);
	const [total, setTotal] = useState(0);
	const [paging, setPaging] = useState(paginationDefault);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { address: depositAddress, loading } = useWalletAddress(
		selectedChain?.value || ''
	);

	const assetBalance = useMemo(() => {
		if (!selectedToken || !myAssetBalances || !chain) return;
		const res = myAssetBalances.find(
			(asset) =>
				asset.assetSymbol === selectedToken.value && asset.chain === chain
		);
		return res;
	}, [myAssetBalances, selectedToken, chain]);

	const supportedAssetsOptions = useMemo(
		() => mapAssetsToOptions(supportedAssets),
		[supportedAssets]
	);

	const supportedChainOptions = useMemo(
		() => mapChainsToOptions(supportedChains),
		[supportedChains]
	);

	useEffect(() => {
		if (supportedAssetsOptions.length > 0)
			setSelectedToken(supportedAssetsOptions[0]);
	}, [supportedAssetsOptions]);

	useEffect(() => {
		if (supportedChainOptions.length > 0) {
			const selectedChain =
				supportedChainOptions.find((chain) => chain.key === 'BSC') ||
				supportedChainOptions[0];
			setSelectedChain(selectedChain);
		}
	}, [supportedChainOptions]);

	useEffect(() => {
		if (modal === 'deposit' && metaverseJs) {
			getTransactions(metaverseJs, paging);
		}
	}, [metaverseJs, modal, paging]);

	const getTransactions = async (
		metaverseJs: MetaverseClient,
		paging: { page: number; pageSize: number }
	) => {
		setIsLoading(true);
		try {
			const res: Responsive<Transaction[]> =
				await metaverseJs.getMyTransactions({
					type: 'DEPOSIT',
					limit: paging.pageSize,
					offset: paging.pageSize * paging.page - paging.pageSize,
				});
			const transactions: Transaction[] = res.data;
			setTransactions(
				transactions.map((item) => ({
					currency: item.assetSymbol || '',
					time: item.createdAt
						? format(new Date(item.createdAt * 1000), 'yyyy-MM-dd')
						: '',
					amount: item.assetAmount || '',
					address: item.fromAccount || '',
					transactionId: item.id || '',
					state: item.state || '',
					onchainHash: item.onchainHash || '',
					chain: item.fromChain || '',
				}))
			);
			if (res && res.meta) {
				setTotal(res.meta.total);
			}
			setIsLoading(false);
		} catch (error) {
			console.log('getTransactions', error);
			setIsLoading(false);
		}
	};
	const handleClose = () => {
		dispatch(setModal(null));
	};

	const handleCopy = (text: string | undefined) => {
		if (!text) return;
		navigator.clipboard.writeText(text);
		toast({
			title: t('Copied'),
			status: 'info',
			duration: 2000,
			isClosable: true,
			position: 'top',
		});
	};

	return (
		<Modal isOpen={modal === 'deposit'} onClose={handleClose} isCentered>
			<ModalOverlay />
			<StyledModalContent>
				<StyledModalHeader>{t('Deposit')}</StyledModalHeader>
				<ModalCloseButton />
				<StyledModalBody position={'relative'}>
					{loading && <LoadingUI />}
					<StepPanel>
						<Steps steps={DEPOSIT_STEPS} />
					</StepPanel>
					<DepositContent>
						<LeftColumn>
							<Label>{t('Tokens')}</Label>
							<SelectCustom
								list={supportedAssetsOptions}
								value={selectedToken}
								onSelect={(item) => setSelectedToken(item)}
							/>
							<Row>
								<div>{t('Balances')}</div>
								<div>{formatCurrency(assetBalance?.totalAssetAmount)}</div>
							</Row>
							<Row>
								<div>{t('Available')}</div>
								<div>
									{formatCurrency(assetBalance?.availableAssetAmount || 0)}
								</div>
							</Row>
							<Row>
								<div>{t('Freeze')}</div>
								<div>
									{formatCurrency(assetBalance?.lockedAssetAmount || 0)}
								</div>
							</Row>
							<Row>{t('Tips')}</Row>
							<Row>
								<Label>{t('Minimum deposit')}</Label>
								<div>0.01 {selectedToken?.value}</div>
							</Row>
							<Row>
								<Label>{t('Deposit arrival')}</Label>
								<div>{t('After 10 network confirmations')}</div>
							</Row>
							<Warning>
								<WarningIcon />
								<WarningContent>
									{t(
										'Do not deposit any of the above mentioned addresses in non local currency assets, otherwise the assets will not be retrievable'
									)}
								</WarningContent>
							</Warning>
						</LeftColumn>
						<RightColumn>
							<Label>
								{t('Chain name')} <QuestionIcon />
							</Label>
							<ChainListWrapper>
								{supportedChainOptions.map((chain) => (
									<ChainItem
										selected={selectedChain?.key === chain.key}
										key={chain.key}
										onClick={() => setSelectedChain(chain)}
									>
										{chain.value}
									</ChainItem>
								))}
							</ChainListWrapper>
							<Label>{t('Deposit address')}</Label>
							<DepositAddressWrapper>
								<DepositAddressInput readOnly value={depositAddress} />
								<StyledCopyIcon onClick={() => handleCopy(depositAddress)} />
							</DepositAddressWrapper>
							<Label>USDT {t('Deposit address')}</Label>
							{depositAddress && (
								<QRCodeWrapper>
									<QRCodeSVG value={depositAddress} />
								</QRCodeWrapper>
							)}
						</RightColumn>
					</DepositContent>
					<DepositHistory>
						<Header>
							<Title>{t('Recent')}</Title>
							<TextGradient>{t('All')}</TextGradient>
						</Header>
						<Retangle />
						{!isLoading ? (
							<>
								<StyledDepositHistoryDesktop list={transactions} />
								<StyledDepositHistoryMobile list={transactions} />
							</>
						) : (
							<Box w={'100%'} textAlign={'center'}>
								<LoadingUI />
							</Box>
						)}
						<Flex justifyContent={'center'} my={'10px'} w={'100%'}>
							<Pagination
								page={paging.page}
								pagination={{
									total,
									pageSize: paging.pageSize,
								}}
								handleChangePage={(page) => {
									setPaging({ ...paging, page });
								}}
							/>
						</Flex>
					</DepositHistory>
				</StyledModalBody>
			</StyledModalContent>
		</Modal>
	);
};

export default DepositModal;
