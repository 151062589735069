import React from 'react';
import Register from '../components/Authentication/Register';
import GoogleRecaptcha from '../components/UI/GoogleRecaptcha';

const RegisterPage = () => {
	return (
		<GoogleRecaptcha>
			<Register />
		</GoogleRecaptcha>
	);
};

export default RegisterPage;
