import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import { CURRENCY } from '../../constants/cryptos';
import DatePickerCustom from '../UI/DatePicker';
import SelectCustom from '../UI/SelectCustom';
import { FilterOption } from './P2POrders';

const Label = styled(Text)`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #737283;
	margin-bottom: 10px;
`;

const cryptos = [
	{ key: 'all', value: 'All Cryptos' },
	...Object.keys(CURRENCY).map((item) => ({ key: item, value: item })),
];
const types = [
	{ key: 'all', value: 'All Types' },
	{ key: 'exorder_buy', value: 'Buy' },
	{ key: 'exorder_sell', value: 'Sell' },
];
const statuses = [
	{ key: 'all', value: 'All Status' },
	{ key: 'exorder_pending', value: 'Pending' },
	{ key: 'exorder_waiting', value: 'Waiting' },
	{ key: 'exorder_cancel', value: 'Cancel' },
	{ key: 'exorder_confirm_payment', value: 'Confirm payment' },
	{ key: 'exorder_confirm_success', value: 'Confirm success' },
];

export default function Filter({
	onSelect,
	filterOption,
}: {
	onSelect: (select: string, option: Option) => void;
	filterOption: FilterOption;
}) {
	return (
		<Flex
			w={{ base: '100%' }}
			justifyContent={{ base: 'flex-start' }}
			gap={{ base: '10px', lg: '20px' }}
			py={{ base: '20px' }}
			wrap={{ base: 'wrap', lg: 'nowrap' }}
		>
			<Box
				w={{ base: 'calc(100% / 3 - 10px)', lg: 'calc(100% / 5)' }}
				minW={{ base: '100px', lg: 'auto' }}
			>
				<Label>Cryptos</Label>
				<SelectCustom
					value={
						cryptos.find((item) => item.key === filterOption.cryptos.key) ||
						cryptos[0]
					}
					list={cryptos}
					onSelect={(op) => onSelect('cryptos', op)}
				/>
			</Box>

			<Box
				w={{ base: 'calc(100% / 3 - 10px)', lg: 'calc(100% / 5)' }}
				minW={{ base: '100px', lg: 'auto' }}
			>
				<Label>Types</Label>
				<SelectCustom
					value={
						types.find((item) => item.key === filterOption.types.key) ||
						types[0]
					}
					list={types}
					onSelect={(op) => onSelect('types', op)}
				/>
			</Box>

			<Box
				w={{ base: 'calc(100% / 3 - 10px)', lg: 'calc(100% / 5)' }}
				minW={{ base: '100px', lg: 'auto' }}
			>
				<Label>Status</Label>
				<SelectCustom
					value={
						statuses.find((item) => item.key === filterOption.statuses.key) ||
						statuses[0]
					}
					list={statuses}
					onSelect={(op) => onSelect('statuses', op)}
				/>
			</Box>
			{/* <Box
				w={{ base: 'calc(100%)', lg: 'calc(100% / 2)' }}
				minW={{ base: '100px', lg: 'auto' }}
			>
				<Label>Date</Label>
				<Flex gap={{ base: '10px', lg: '20px' }}>
					<DatePickerCustom
						selectedDate={new Date()}
						onChange={() => {
							console.log('date');
						}}
					/>

					<DatePickerCustom
						selectedDate={new Date()}
						onChange={() => {
							console.log('date');
						}}
					/>
				</Flex>
			</Box> */}
		</Flex>
	);
}
