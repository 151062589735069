import {
	Box,
	Table,
	TableContainer,
	TableProps,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	chakra,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import TextGradient from '../../UI/TextGradient';
import { useDispatch } from 'react-redux';
import { setModal } from '../../../redux/reducers/system.slice';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes/config';
import { formatCurrency } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { TRANSLATION } from '../../../constants';

interface FundTableDesktopProps extends TableProps {
	list: any;
}
const Actions = styled(Box)`
	display: flex;
	align-items: center;
	gap: 16px;
	width: 100%;
	justify-content: flex-end;
`;
const StyledTextGradient = styled(TextGradient)`
	cursor: pointer;
	&:hover {
		filter: brightness(1.2);
	}
`;
const TokenWrapper = styled(Box)`
	display: flex;
	align-items: center;
	gap: 8px;
`;
const TokenImage = styled.img`
	width: 35px;
	height: 35px;
	object-fit: contain;
	border-radius: 100%;
`;
const TokenName = styled(Box)`
	color: #737283;
	font-size: 14px;
	line-height: 16px;
`;
const TokenSymbol = styled(Box)`
	color: #ffffff;
	font-size: 16px;
	line-height: 1;
`;

const TokenInfo = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 5px;
`;

const TitleWrapper = styled(Box)`
	display: flex;
	align-items: center;
	gap: 4px;
`;

const HeadCell = chakra(Th, {
	baseStyle: {
		borderBottom: '1px solid #FFFFFF1E',
		textTransform: 'capitalize',
		fontFamily: 'Roboto',
		color: '#737283',
		fontWeight: 400,
		fontSize: '14px',
		padding: '25px 0',
	},
});

const BodyCell = chakra(Td, {
	baseStyle: {
		borderBottom: '1px solid #FFFFFF1E',
		padding: '25px 0',
	},
});

const FundTableDesktop = ({ list, ...props }: FundTableDesktopProps) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation(TRANSLATION, {
		keyPrefix: 'Wallet',
	});
	return (
		<TableContainer style={{ width: '100%' }}>
			<Table {...props}>
				<Thead>
					<Tr>
						<HeadCell>{t('Token')}</HeadCell>
						<HeadCell>
							<TitleWrapper>{t('Total')} </TitleWrapper>
						</HeadCell>
						<HeadCell>
							<TitleWrapper>{t('Available')} </TitleWrapper>
						</HeadCell>
						<HeadCell isNumeric>{t('Action')}</HeadCell>
					</Tr>
				</Thead>
				<Tbody>
					{list.map((item: any, index: any) => (
						<Tr key={index}>
							<BodyCell>
								<TokenWrapper>
									<TokenImage
										src={item.currency.icon}
										alt={item.currency.name}
									/>
									<TokenInfo>
										<TokenSymbol>{item.currency.symbol}</TokenSymbol>
										<TokenName>{item.currency.name}</TokenName>
									</TokenInfo>
								</TokenWrapper>
							</BodyCell>
							<BodyCell>{formatCurrency(item.total)}</BodyCell>
							<BodyCell>{formatCurrency(item.available)}</BodyCell>
							<BodyCell isNumeric>
								<Actions>
									<StyledTextGradient
										onClick={() => dispatch(setModal('deposit'))}
									>
										{t('Deposit')}
									</StyledTextGradient>
									<StyledTextGradient
										onClick={() => dispatch(setModal('withdraw'))}
									>
										{t('Withdraw')}
									</StyledTextGradient>
									<StyledTextGradient
										onClick={() => navigate(ROUTES.P2P_BUY_SELL)}
									>
										{t('Trade')}
									</StyledTextGradient>
								</Actions>
							</BodyCell>
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};

export default FundTableDesktop;
