import { Box, Button, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes/config';
import { KYCState } from 'user-identity-js/lib/entities/kyc.entity';
interface Props {
	status: KYCState | undefined;
}
const NotificationDoc = ({ status }: Props) => {
	const navigate = useNavigate();
	return (
		<Box
			background={'rgba(9, 18, 41, 0.5)'}
			py={{ base: '16px', md: '10px' }}
			px={{ base: '16px', md: '30px' }}
			mt={{ base: '1rem', md: '30px' }}
		>
			<Text
				fontSize={{ base: '14px', md: '18px' }}
				fontWeight={'500'}
				lineHeight={{ base: 'auto', md: '48px' }}
			>
				{status === KYCState.REJECTED
					? 'We could not upgrade your verification to Verified Plus'
					: status === KYCState.SUBMITTED || status === KYCState.VERIFYING
					? 'Verify processing...'
					: 'Verify Now'}
			</Text>
			{status === KYCState.INIT && (
				<Text color={'#737283'} fontSize={'14px'}>
					Your proof of address cannot be verified as the document you have used
					does not meet our requirements. Please submit a bank account statement
					or utility bill (electricity, water, internet, etc) with your name,
					address, and issue date within 3 months) You may submit a picture of a
					hard copy bill or a PDF of a paperless bill.
				</Text>
			)}
			{(!status ||
				(status &&
					status !== KYCState.VERIFIED &&
					status !== KYCState.SUBMITTED)) && (
				<Button
					mt={'20px'}
					bg={'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)'}
					color={'black'}
					borderRadius={'36px'}
					fontWeight={'500'}
					w={{ base: '100%', md: '384px' }}
					onClick={() => navigate(ROUTES.ACCOUNT.ID_VERIFICATION)}
				>
					{(!status || status === KYCState.INIT) && 'Verify Now'}
					{status === KYCState.REJECTED && 'Try again'}
				</Button>
			)}
		</Box>
	);
};

export default NotificationDoc;
