import { Box, Flex, Hide, Img, Show, Text } from '@chakra-ui/react';
import { CURRENCY } from '../../constants/cryptos';
import { PrimaryText, SecondaryText } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { P2PTradingClient } from 'skychain-p2p-trading-js';
import { useEffect, useMemo, useState } from 'react';
import { ExecuteOrderEntity } from 'skychain-p2p-trading-js/lib/entities/execute-order.entity';
import BigNumber from 'bignumber.js';
import {
	convertExecuteOrderStatus,
	convertExecuteOrderType,
} from '../../utils/order';
import { format } from 'date-fns';
import BuySellTable from '../UI/BuySellTable';
import {
	ExecuteOrderStatus,
	QueryExecuteOrder,
} from 'skychain-p2p-trading-js/lib/dto/execute-order.dto';
import Container from '../../layouts/Container';
import { UserIdentityClient } from 'user-identity-js';
import { UserEntity } from 'user-identity-js/lib/entities';
import { AdOrderEntity } from 'skychain-p2p-trading-js/lib/entities/ad-order.entity';
import { getUserName } from '../../utils/user';
import { ROUTES } from '../../routes/config';
import Filter from './Filter';
import Pagination from '../UI/Pagination';
import { PageOptions } from 'skychain-p2p-trading-js/lib/dto';
import LoadingUI from '../UI/LoadingUI';
import { formatCurrency } from '../../utils';
import { Link } from 'react-router-dom';

interface Order extends ExecuteOrderEntity {
	targetUserInfo: UserEntity | undefined;
	adOrder: AdOrderEntity | undefined;
}

export interface FilterOption {
	types: Option;
	cryptos: Option;
	statuses: Option;
}

const paginationDefault = {
	page: 1,
	pageSize: 10,
};

const optionState: Option = {
	key: 'all',
	value: 'All',
};
const P2POrders = () => {
	const p2pTradingClient = useSelector(
		(state: RootState) => state.system.p2pTradingClient
	);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const [total, setTotal] = useState(0);
	const [paging, setPaging] = useState(paginationDefault);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const userProfile = useSelector((state: RootState) => state.user.userProfile);
	const [orders, setOrders] = useState<Order[]>([]);
	const [filterOption, setFilterOption] = useState<FilterOption>({
		types: optionState,
		cryptos: optionState,
		statuses: optionState,
	});
	const columns: ColumnsOrdersTable[] = [
		{
			key: 'cryptos',
			title: 'Cryptos',
			isNumeric: false,
			align: 'start',
			render: (data: any) => (
				<Flex gap="10px" alignItems={'center'}>
					<Img src={CURRENCY[data['currency']]?.icon} />
					<Flex direction={'column'} gap={'5px'}>
						<PrimaryText>{CURRENCY[data['currency']]?.symbol}</PrimaryText>
						<SecondaryText>{CURRENCY[data['currency']]?.name}</SecondaryText>
					</Flex>
				</Flex>
			),
		},
		{
			key: 'fiatAmount',
			title: 'Fiat Amount',
			isNumeric: false,
			align: 'center',
		},
		{
			key: 'price',
			title: 'Price',
			isNumeric: true,
			align: 'start',
		},
		{
			key: 'cryptoAmount',
			title: 'Crypto Amount',
			isNumeric: false,
			align: 'center',
		},
		{
			key: 'date',
			title: 'Date',
			isNumeric: false,
			align: 'center',
		},
		{
			key: 'counterparty',
			title: 'Counterparty',
			isNumeric: false,
			align: 'start',
		},
		{
			key: 'types',
			title: 'Types',
			isNumeric: false,
			align: 'start',
			render: (data: any) => (
				<Text
					color={data['type']?.toLowerCase() === 'buy' ? '#03A66D' : '#CF304A'}
					fontSize={{ base: '14px' }}
					textTransform={'capitalize'}
				>
					{data['type']}
				</Text>
			),
		},
		{
			key: 'status',
			title: 'Status',
			isNumeric: true,
			align: 'center',
			render: (data: any) => (
				<Text
					color={
						data['status'] === ExecuteOrderStatus.CONFIRM_SUCCESS
							? '#03A66D'
							: data['status'] === ExecuteOrderStatus.CANCEL
							? '#CF304A'
							: 'yellow'
					}
					fontSize={{ base: '14px' }}
					textTransform={'capitalize'}
				>
					{convertExecuteOrderStatus(data['status'])}
				</Text>
			),
		},
	];
	const dataTable: DataOrdersTable[] = useMemo(() => {
		const data = orders.map((item) => ({
			key: item.id,
			currency: item.adOrder?.assetSymbol || '',
			fiatAmount: `${formatCurrency(
				new BigNumber(item.amount).multipliedBy(item.price).toFixed(4)
			)} ${item.adOrder?.fiatSymbol}`,
			type: convertExecuteOrderType(
				item.type,
				item.user === userProfile?.id
			) as any,
			cryptoAmount: `${formatCurrency(item.amount)} ${
				item.adOrder?.assetSymbol
			}`,
			status: item.status,
			date: format(new Date(item.createdAt * 1000), 'dd/MM/yyyy hh:mm a'),
			counterparty: getUserName(item.targetUserInfo),
			price: `${formatCurrency(item.price)}  ${item.adOrder?.fiatSymbol} `,
			fiatSymbol: item.adOrder?.fiatSymbol || '',
		}));
		return data;
	}, [orders]);

	const handleFilterSelect = (select: string, option: Option) => {
		setFilterOption({
			...filterOption,
			[select]: option,
		});
	};

	useEffect(() => {
		const query = {
			...filterOption,
		};
		if (p2pTradingClient && userIdentity && paging) {
			getExecuteOrders(p2pTradingClient, userIdentity, query, paging);
		}
	}, [p2pTradingClient, userIdentity, filterOption, paging]);

	const getCounterparty = (order: ExecuteOrderEntity) => {
		return order.user === userProfile?.id ? order.targetUser : order.user;
	};
	const getExecuteOrders = async (
		p2pTradingClient: P2PTradingClient,
		userIdenty: UserIdentityClient,
		query: FilterOption,
		paging: PageOptions
	) => {
		const queryReq = {
			statuses: query.statuses.key !== 'all' ? query.statuses.key : '',
			assetSymbol: query.cryptos.key !== 'all' ? query.cryptos.key : '',
			types: query.types.key !== 'all' ? query.types.key : '',
		} as QueryExecuteOrder;
		setIsLoading(true);
		try {
			const res = await p2pTradingClient.getExecutedOrders(queryReq, paging);
			const ids = res.data.map((item: ExecuteOrderEntity) =>
				getCounterparty(item)
			) as string[];

			const users = await userIdenty.getUsers({
				ids,
			});
			const adOrders = await p2pTradingClient.getAllAdOrders({
				ids: Array.from(new Set(res.data.map((item) => item.adOrderId))),
			});
			setOrders(
				res.data.map((item: ExecuteOrderEntity) => ({
					...item,
					targetUserInfo: users.data.find(
						(user: UserEntity) => user.id === getCounterparty(item)
					),
					adOrder: adOrders.data.find(
						(adOrder: AdOrderEntity) => adOrder.id === item.adOrderId
					),
				}))
			);
			if (res && res.meta && res.meta.totalRecord) {
				setTotal(res.meta.totalRecord);
			}
		} catch (error) {
			console.log('getExecuteOrders', error);
		} finally {
			setIsLoading(false);
		}
	};
	return (
		<Container>
			<Filter onSelect={handleFilterSelect} filterOption={filterOption} />
			{isLoading && <LoadingUI h={'100px'} />}
			<Box py={{ base: '10px', lg: '20px' }}>
				<Show above="sm">
					<BuySellTable
						dataTable={dataTable}
						columns={columns}
						isBorder
						isCollapse
						redirectUrl={ROUTES.BUY_SELL_DETAIL}
						loading={isLoading}
					/>
				</Show>
				<Hide above="sm">
					<Box>
						{dataTable.map((data) => {
							return (
								<Link
									to={ROUTES.BUY_SELL_DETAIL.replace(':id', data.key as string)}
									key={data.key}
								>
									<Box my={'20px'}>
										<Flex justifyContent={'space-between'}>
											<Flex color="white" fontSize={'14px'}>
												<Text
													mr={'4px'}
													color={
														data['type']?.toLowerCase() === 'buy'
															? '#03A66D'
															: '#CF304A'
													}
													fontSize={{ base: '14px' }}
													textTransform={'capitalize'}
												>
													{data['type']}
												</Text>{' '}
												<Text>
													{CURRENCY[data['currency']]?.symbol} with{' '}
													{data['fiatSymbol']}
												</Text>
											</Flex>
											<Box fontSize={'14px'} color={'#737283'}>
												{data['date']}
											</Box>
										</Flex>
										{columns.map((col) => (
											<Box key={col.key}>
												{col.key !== 'cryptos' &&
													col.key !== 'date' &&
													col.key !== 'types' && (
														<Flex justifyContent={'space-between'}>
															<Box color="#737283" fontSize={'14px'}>
																{col.title}
															</Box>
															<Box fontSize={'14px'}>
																{col.render ? col.render(data) : data[col.key]}
															</Box>
														</Flex>
													)}
											</Box>
										))}
									</Box>
								</Link>
							);
						})}
					</Box>
				</Hide>
			</Box>

			<Flex justifyContent={'center'} my={'10px'} paddingBottom={'30px'}>
				<Pagination
					page={paging.page}
					pagination={{
						total,
						pageSize: paging.pageSize,
					}}
					handleChangePage={(page) => {
						setPaging({ ...paging, page });
					}}
				/>
			</Flex>
		</Container>
	);
};

export default P2POrders;
