import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setModal } from '../../../redux/reducers/system.slice';
import {
	Modal,
	ModalCloseButton,
	ModalOverlay,
	Spinner,
	useToast,
} from '@chakra-ui/react';
import SelectCustom from '../../UI/SelectCustom';
import { useEffect, useMemo, useState } from 'react';
import TransferIcon from '../../Icons/TransferIcon';
import PrimaryButton from '../../UI/PrimaryButton';
import {
	StyledModalContent,
	StyledModalHeader,
	StyledModalBody,
	TransferSection,
	LeftColumn,
	Label,
	Balance,
	RightColumn,
	TokenSection,
	AmountWrapper,
	AmountInput,
	StyledTextGradient,
	Description,
	StyledForm,
	ErrorMessage,
	TransferIconWrapper,
} from './styles';
import { useForm } from 'react-hook-form';
import BigNumber from 'bignumber.js';
import { mapAssetsToOptions } from '../../../utils/asset';
import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import { fetchUserAssetBalancesRequest } from '../../../redux/actions/user.action';
import { formatCurrency, validateInputNumber } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { TRANSLATION } from '../../../constants';

const WatlletOptions: Option[] = [
	{
		value: 'Spot Wallet',
		key: 'SPOT',
	},
	{
		value: 'P2P Wallet',
		key: 'FUNDING',
	},
];

interface TransferForm {
	from_user: string;
	to_user: string;
	from_chain: string;
	to_chain: string;
	amount: string;
	token: string;
}
const TransferModal = () => {
	const dispatch = useDispatch();
	const toast = useToast();
	const modal = useSelector((state: RootState) => state.system.modal);
	const metaverseJs = useSelector(
		(state: RootState) => state.system.metaverseJs
	);
	const { t } = useTranslation(TRANSLATION, {
		keyPrefix: 'Wallet',
	});

	const supportedAssets = useSelector(
		(state: RootState) => state.system.supportedAssets
	);
	const userAccount = useSelector((state: RootState) => state.auth.account);
	const [fromWallet, setFromWallet] = useState<Option>(WatlletOptions[0]);
	const [toWallet, setToWallet] = useState<Option>(WatlletOptions[1]);
	const [transfering, setTransfering] = useState(false);

	const myAssetBalances = useSelector(
		(state: RootState) => state.user.userAssetBalances
	);
	const [selectedToken, setSelectedToken] = useState<Option | undefined>();

	const assetBalanceFromChain = useMemo(() => {
		if (!selectedToken || !myAssetBalances || !fromWallet?.key) return;
		const res = myAssetBalances.find(
			(asset) =>
				asset.assetSymbol === selectedToken.value &&
				asset.chain === fromWallet.key
		);
		return res;
	}, [myAssetBalances, selectedToken, fromWallet]);

	const assetBalanceToChain = useMemo(() => {
		if (!selectedToken || !myAssetBalances || !toWallet?.key) return;
		const res = myAssetBalances.find(
			(asset) =>
				asset.assetSymbol === selectedToken.value &&
				asset.chain === toWallet?.key
		);
		return res;
	}, [myAssetBalances, selectedToken, toWallet]);

	const supportedAssetsOptions = useMemo(
		() => mapAssetsToOptions(supportedAssets),
		[supportedAssets]
	);
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
		clearErrors,
		watch,
	} = useForm<TransferForm>({
		defaultValues: {
			from_chain: fromWallet.key as string,
			to_chain: toWallet.key as string,
		},
	});

	useEffect(() => {
		if (supportedAssetsOptions.length > 0 && modal === 'transfer') {
			setSelectedToken(supportedAssetsOptions[0]);
			setValue('token', supportedAssetsOptions[0].value);
		}
	}, [supportedAssetsOptions, modal]);

	useEffect(() => {
		if (modal === 'transfer') {
			setValue('from_user', userAccount?.id || '');
			setValue('to_user', userAccount?.id || '');
		}
	}, [userAccount, modal]);

	const handleClose = () => {
		dispatch(setModal(null));
		reset();
	};

	const onSelectFromWallet = (option: Option) => {
		setFromWallet(option);
		setValue('from_chain', option.key as string);
	};
	const onSelectToWallet = (option: Option) => {
		setToWallet(option);
		setValue('to_chain', option.key as string);
	};

	const onSelectToken = (option: Option) => {
		setSelectedToken(option);
		setValue('token', option.value);
	};

	const handleSwitchWallet = () => {
		setFromWallet(toWallet);
		setToWallet(fromWallet);
		setValue('from_chain', toWallet.key as string);
		setValue('to_chain', fromWallet.key as string);
	};
	const onSubmit = async (data: TransferForm) => {
		if (!metaverseJs || transfering) return;
		try {
			setTransfering(true);
			const body: Transaction = {
				fromChain: data.from_chain,
				toChain: data.to_chain,
				toUser: data.to_user,
				assetSymbol: data.token,
				assetAmount: data.amount,
				assetType: 'ERC20',
				nonce: (userAccount?.id || '') + Date.now(),
			};
			const res = await metaverseJs.transfer(body);
			if (res?.nonce) {
			}
			toast({
				title: 'Success',
				status: 'success',
				description: 'Transfer successfully',
			});
			dispatch(setModal(null));
			await new Promise((resolve) => setTimeout(resolve, 1000));
			dispatch(fetchUserAssetBalancesRequest({ payload: undefined }));
			handleClose();
		} catch (error) {
			console.log('transfer', error);
		} finally {
			setTransfering(false);
		}
	};
	return (
		<Modal isOpen={modal === 'transfer'} onClose={handleClose} isCentered>
			<ModalOverlay />
			<StyledModalContent>
				<StyledModalHeader>{t('Transfer')}</StyledModalHeader>
				<ModalCloseButton />
				<StyledForm onSubmit={handleSubmit(onSubmit)}>
					<StyledModalBody>
						<TransferSection>
							<LeftColumn>
								<Label>{t('From')}</Label>
								<SelectCustom
									list={WatlletOptions}
									value={fromWallet}
									onSelect={onSelectFromWallet}
									{...register('from_chain', {
										required: t('Please select wallet to transfer'),
										validate: (value) => {
											if (value && value === toWallet.key) {
												return t('From Wallet and To Wallet must be different');
											} else {
												clearErrors();
											}
											return true;
										},
									})}
								/>
								<ErrorMessage>{errors.from_chain?.message}</ErrorMessage>
								<Balance>
									{t('Available')} :{' '}
									{formatCurrency(assetBalanceFromChain?.availableAssetAmount)}{' '}
									{selectedToken?.value || 'USDT'}
								</Balance>
							</LeftColumn>
							<TransferIconWrapper onClick={() => handleSwitchWallet()}>
								<TransferIcon />
							</TransferIconWrapper>
							<RightColumn>
								<Label>{t('Transfer to')}</Label>
								<SelectCustom
									list={WatlletOptions}
									value={toWallet}
									onSelect={onSelectToWallet}
									{...register('to_chain', {
										required: t('Please select wallet to transfer'),
										validate: (value) => {
											if (value && value === fromWallet.key) {
												return t('From Wallet and To Wallet must be different');
											} else {
												clearErrors();
											}
											return true;
										},
									})}
								/>

								<ErrorMessage>{errors.to_chain?.message}</ErrorMessage>
								<Balance>
									{t('Available')} :{' '}
									{formatCurrency(assetBalanceToChain?.availableAssetAmount)}{' '}
									{selectedToken?.value || 'USDT'}
								</Balance>
							</RightColumn>
						</TransferSection>
						<TokenSection>
							<Label>{t('Tokens')}</Label>
							<SelectCustom
								list={supportedAssetsOptions}
								value={selectedToken}
								onSelect={onSelectToken}
								{...register('token', { required: t('Please select a token') })}
							/>
						</TokenSection>
						{errors.token?.message && (
							<ErrorMessage>{errors.token?.message}</ErrorMessage>
						)}
						<TokenSection>
							<Label>{t('Number of Transfers')}</Label>
							<AmountWrapper>
								<AmountInput
									step={'any'}
									placeholder={t('Enter your Number of Transfers')}
									{...register('amount', {
										required: t('Please enter amount'),
										validate: (value) => {
											if (new BigNumber(value).lte(0)) {
												return t('Amount must be greater than 0');
											}
											if (
												new BigNumber(value).gt(
													assetBalanceFromChain?.availableAssetAmount || 0
												)
											) {
												return t('Insufficient balance');
											}
											return true;
										},
									})}
									onChange={(e) => {
										const value = e.target?.value;

										if (validateInputNumber(value)) {
											setValue('amount', value);
										}
									}}
									value={watch('amount')}
								/>
								<StyledTextGradient
									onClick={() => {
										setValue(
											'amount',
											new BigNumber(
												assetBalanceFromChain?.availableAssetAmount || 0
											).toString()
										);
										clearErrors('amount');
									}}
								>
									All
								</StyledTextGradient>
							</AmountWrapper>
							{errors.amount?.message && (
								<ErrorMessage>{errors.amount?.message}</ErrorMessage>
							)}
							<Description>
								{t('Can be transferred')}{' '}
								{formatCurrency(assetBalanceFromChain?.availableAssetAmount)}{' '}
								{selectedToken?.value || 'USDT'}
							</Description>
						</TokenSection>
						<PrimaryButton type="submit" style={{ borderRadius: '4px' }}>
							{transfering ? <Spinner /> : t('Transfer')}
						</PrimaryButton>
					</StyledModalBody>
				</StyledForm>
			</StyledModalContent>
		</Modal>
	);
};

export default TransferModal;
