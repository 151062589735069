import {
	Box,
	Collapse,
	Flex,
	Grid,
	GridItem,
	Show,
	Tag,
	TagLabel,
	Text,
	chakra,
	useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BuySellTable from '../UI/BuySellTable';
import styled from '@emotion/styled';
import { ColumnsBuySellTable, DataBuySellTable } from './interface';
import Pagination from '../UI/Pagination';
import BuySellButton from '../UI/BuySellButton';
import TradeItemExpand from './TradeItemExpand';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { AdOrderEntity } from 'skychain-p2p-trading-js/lib/entities/ad-order.entity';
import { convertToDataTableAds } from '../../utils/ads';
import {
	AdOrderType,
	QueryAdOrder,
} from 'skychain-p2p-trading-js/lib/dto/ad-order.dto';
import BigNumber from 'bignumber.js';
import LoadingUI from '../UI/LoadingUI';
import { formatCurrency } from '../../utils';
const TitleHasTagWrapper = chakra(Flex, {
	baseStyle: {
		alignItems: 'center',
		gap: '10px',
	},
});

const Title = chakra(Text, {
	baseStyle: {
		fontSize: '14px',
		fontWeight: 400,
	},
});

const GradientTag = styled(TagLabel)`
	font-size: 14px;
	font-weight: 400;
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
`;

const PrimaryTag = styled(TagLabel)`
	font-size: 14px;
	font-weight: 400;
	color: #03a66d;
`;

const PrimaryText = styled(Text)`
	color: #ffffff;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
`;

const SecondaryText = styled(Text)`
	color: #737283;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
`;

const TagCustom = chakra(Tag, {
	baseStyle: {
		borderRadius: '3px',
	},
});

const PaymentCard = chakra(Flex, {
	baseStyle: {
		w: '117px',
		h: '26px',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '3px',
	},
});

const CashPaymentText = chakra(Text, {
	baseStyle: {
		fontSize: { base: '12px', lg: '14px' },
		fontWeight: 400,
		color: '#03a66d',
		lineHeight: '14px',
	},
});

const BankPaymentText = styled(Text)`
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
`;

export const Rate = styled(Text)`
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: #737283;
`;

const PAGE_SIZE = 10;
const columns: ColumnsBuySellTable[] = [
	{
		key: 'name',
		title: 'Advertisers',
		isNumeric: false,
		align: 'start',
		render: (data: any) => (
			<Flex direction={'column'} gap="10px">
				<Flex alignItems={'center'} gap={'8px'}>
					<img src={data['avatar']} alt="avatar" />
					<Text>{data['name']}</Text>
					<img src={'/images/tick.svg'} alt="tick" />
				</Flex>

				{/* <Rate>{`${data['order']} orders | ${data['completion']} completion`}</Rate> */}
			</Flex>
		),
	},
	{
		key: 'price',
		title: (
			<TitleHasTagWrapper>
				<Title>Price</Title>
				<TagCustom size={'md'} variant="subtle" background={'#FEF6D84D'}>
					<GradientTag>Lowest to highest</GradientTag>
				</TagCustom>
			</TitleHasTagWrapper>
		),
		isNumeric: false,
		align: 'start',
		render: (data: any) => (
			<Flex direction={'column'} gap={'5px'}>
				<PrimaryText>{data['price']}</PrimaryText>
				<SecondaryText>{data['fiatSymbol']}</SecondaryText>
			</Flex>
		),
	},
	{
		key: 'limit_available',
		title: 'Limit / Available',
		isNumeric: false,
		align: 'start',
		render: (data: any) => (
			<Flex direction={'column'} gap={'5px'}>
				<Flex alignItems={'flex-end'} gap={{ base: '10px' }}>
					<SecondaryText>Available:</SecondaryText>
					<PrimaryText>
						{formatCurrency(data['available'])} {data['assetSymbol']}
					</PrimaryText>
				</Flex>
				<Flex alignItems={'flex-end'} gap={{ base: '10px' }}>
					<SecondaryText>Limit:</SecondaryText>
					<PrimaryText>{data['limit']}</PrimaryText>
				</Flex>
			</Flex>
		),
	},
	{
		key: 'payment',
		title: 'Payment',
		isNumeric: false,
		align: 'start',
		render: (data: any) => (
			<PaymentCard
				background={data['paymentType'] === 'cash' ? '#CBFFD64D' : '#FEF6D84D'}
			>
				{data['paymentType'] === 'cash' ? (
					<CashPaymentText>{data['payment']}</CashPaymentText>
				) : (
					<BankPaymentText>{data['payment']}</BankPaymentText>
				)}
			</PaymentCard>
		),
	},
	{
		key: 'trade',
		title: (
			<TitleHasTagWrapper justifyContent={{ base: 'center' }}>
				<Title>Trade</Title>
				<TagCustom size={'md'} variant="subtle" background={'#CBFFD64D'}>
					<PrimaryTag>0 Fee</PrimaryTag>
				</TagCustom>
			</TitleHasTagWrapper>
		),
		isNumeric: true,
		align: 'center',
		render: (data: DataBuySellTable) => (
			<BuySellButton isBuy={data.type === AdOrderType.SELL}>
				{data.type === AdOrderType.BUY ? 'Sell' : 'Buy'} {data.assetSymbol}
			</BuySellButton>
		),
	},
];

export default function TradeData({
	filterParams,
}: {
	filterParams: QueryAdOrder;
}) {
	const p2pTradingClient = useSelector(
		(state: RootState) => state.system.p2pTradingClient
	);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const [ads, setAds] = useState<DataBuySellTable[]>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRecord, setTotalRecord] = useState(1);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (p2pTradingClient) {
			getAllAds(currentPage, filterParams);
		}
	}, [p2pTradingClient, currentPage, JSON.stringify(filterParams)]);

	const getAllAds = async (currentPage: number, filterParams: QueryAdOrder) => {
		if (!p2pTradingClient) return;
		try {
			setLoading(true);
			const res = await p2pTradingClient.getAllAdOrders(filterParams, {
				page: currentPage,
				pageSize: PAGE_SIZE,
			});
			if (res?.data?.length > 0) {
				const ids = res.data.map((ad: AdOrderEntity) => ad.user);
				const paymentMethodIds = res.data.map(
					(ad: AdOrderEntity) => ad.paymentMethod
				);
				const users = await getUsers(ids);
				const paymentMethods = await getPaymentMethods(
					paymentMethodIds.toString()
				);
				setAds(
					res.data.map((ad: AdOrderEntity) =>
						convertToDataTableAds(ad, users?.data, paymentMethods)
					)
				);
				setCurrentPage(res.meta.page || 1);
				setTotalRecord(res.meta.totalRecord || 1);
			} else {
				setAds([]);
				setCurrentPage(1);
				setTotalRecord(0);
			}
		} catch (error) {
			console.log('getAllAds', error);
		} finally {
			setLoading(false);
		}
	};
	const getUsers = async (ids: string[] | string) => {
		if (!userIdentity) return;
		try {
			const res = await userIdentity.getUsers({ ids });
			return res || [];
		} catch (error) {
			console.log('getUser', error);
		}
	};

	const getPaymentMethods = async (ids: string) => {
		try {
			const res = await p2pTradingClient.filterPublicMethods(ids);
			return res;
		} catch (errors: any) {
			console.log('getPaymentMethods', errors);
		}
	};

	return (
		<div
			style={{
				position: 'relative',
			}}
		>
			{loading && <LoadingUI />}
			<Show above="sm">
				<BuySellTable
					dataTable={ads}
					columns={columns}
					isBorder
					isCollapse
					loading={loading}
				/>
			</Show>
			<Show below="sm">
				{ads.map((data) => (
					<BuySellBlock key={data.key} data={data} />
				))}
			</Show>

			<Flex justifyContent={'center'} py={'20px'}>
				<Pagination
					handleChangePage={(page) => {
						setCurrentPage(page);
					}}
					page={currentPage}
					pagination={{
						total: totalRecord,
						pageSize: PAGE_SIZE,
					}}
				/>
			</Flex>
		</div>
	);
}

const BuySellBlock = ({ data }: { data: DataBuySellTable }) => {
	const { isOpen, onToggle } = useDisclosure();
	return (
		<Box py="20px" borderBottom={'1px solid #FFFFFF1E'}>
			<Collapse in={!isOpen} animateOpacity>
				<Grid templateColumns="repeat(2, 1fr)" gap={1} w={'100%'}>
					<GridItem w={'100%'}>
						<Flex alignItems={'center'} gap={'8px'}>
							<img src={data['avatar']} alt="avatar" />
							<Text>{data['name']}</Text>
							<img src={'/images/tick.svg'} alt="tick" />
						</Flex>
						<Flex my="10px">
							<Box w={'46px'}>
								<SecondaryText>Price:</SecondaryText>
							</Box>
							<Box ml="5px">
								<PrimaryText>
									{data['price']} {data['fiatSymbol']}
								</PrimaryText>
							</Box>
						</Flex>
						<Flex my="10px">
							<Box w={'46px'}>
								<SecondaryText>Amount:</SecondaryText>
							</Box>
							<Box ml="5px">
								<PrimaryText>{formatCurrency(data['available'])}</PrimaryText>
							</Box>
						</Flex>
						<Flex my="10px">
							<Box w={'46px'}>
								<SecondaryText>Limit:</SecondaryText>
							</Box>
							<Box ml="5px">
								<PrimaryText>{data['limit']}</PrimaryText>
							</Box>
						</Flex>
						<PaymentCard
							background={
								data['paymentType'] === 'cash' ? '#CBFFD64D' : '#FEF6D84D'
							}
							mt={{ base: '15px' }}
						>
							{data['paymentType'] === 'cash' ? (
								<CashPaymentText>{data['payment']}</CashPaymentText>
							) : (
								<BankPaymentText>{data['payment']}</BankPaymentText>
							)}
						</PaymentCard>
					</GridItem>

					<GridItem w={'100%'}>
						<Flex
							flexDirection={'column'}
							justifyContent={'space-between'}
							alignItems={'flex-end'}
							h={'100%'}
						>
							{/* <Rate textAlign={'right'}>
								{data['order']} orders | {data['completion']} completion
							</Rate> */}
							<div />
							<BuySellButton
								fontSize={'16px'}
								w={{ base: '100%' }}
								maxW={'148px'}
								h={{ base: '38px' }}
								onClick={onToggle}
								isBuy={data.type === AdOrderType.SELL}
							>
								{data.type === AdOrderType.BUY ? 'Sell' : 'Buy'}{' '}
								{data.assetSymbol}
							</BuySellButton>
						</Flex>
					</GridItem>
				</Grid>
			</Collapse>

			<Collapse in={isOpen} animateOpacity>
				<TradeItemExpand data={data} onToggleCollapse={onToggle} />
			</Collapse>
		</Box>
	);
};
