import {
	Button,
	Flex,
	Img,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	chakra,
} from '@chakra-ui/react';
import { useRef, useEffect, useState } from 'react';
import DropdownIcon from '../Icons/DropdownIcon';

const ValueOption = chakra(Text, {
	baseStyle: {
		color: '#737283',
		fontSize: '14px',
		fontWeight: 400,
		flex: 'none',
	},
});

interface PropsType {
	value: Option | undefined;
	list: Option[];
	onSelect: (option: Option) => void;
	isBorder?: boolean;
	isUnStyle?: boolean;
	fontSize?: string;
	disabled?: boolean;
	w?: string;
}

export default function SelectCustom({
	list,
	value,
	onSelect,
	isBorder = true,
	isUnStyle,
	fontSize = '16px',
	disabled,
	w,
}: PropsType) {
	const buttonRef = useRef<any>();
	const [widthOptions, setWidthOptions] = useState(0);

	useEffect(() => {
		if (buttonRef.current) {
			setWidthOptions(buttonRef?.current?.clientWidth);
		}
	}, []);

	return (
		<Menu>
			<MenuButton
				as={Button}
				// leftIcon={
				// 	<Img
				// 		src={`/images/icons/${list[0].icon}.svg`}
				// 		alt={list[0].icon}
				// 		mr={'5px'}
				// 	/>
				// }
				rightIcon={<DropdownIcon />}
				ref={buttonRef}
				variant={'outline'}
				// padding={'0 10px'}
				w={isUnStyle ? 'auto' : '100%'}
				border={isBorder ? '1px solid #FFFFFF14' : 'none'}
				// display={'flex'}
				// justifyContent={'space-between'}
				// gap={'15px'}
				// w={width}
				fontWeight={400}
				fontSize={fontSize}
				isDisabled={disabled}
			>
				{isUnStyle ? (
					value?.value
				) : (
					<Flex alignItems={'center'} gap={'5px'} pr={'20px'}>
						{value?.icon && (
							<Img
								src={`/images/icons/${list[0].icon}.svg`}
								alt={value?.icon}
								mr={'5px'}
								style={{ width: 16, height: 16, objectFit: 'contain' }}
							/>
						)}
						<ValueOption>{value?.value}</ValueOption>
					</Flex>
				)}
			</MenuButton>
			<MenuList w={w || widthOptions + 2} minW={0} padding={0}>
				{list.map((option) => (
					<MenuItem
						key={option.key}
						padding={'5px 10px'}
						onClick={() => onSelect(option)}
					>
						<Flex alignItems={'center'} gap={'5px'} pr={'20px'}>
							{option.icon && (
								<Img
									src={`/images/icons/${option.icon}.svg`}
									alt={option.icon}
									mr={'5px'}
									style={{ width: 16, height: 16, objectFit: 'contain' }}
								/>
							)}
							<ValueOption>{option.value}</ValueOption>
						</Flex>
					</MenuItem>
				))}
			</MenuList>
		</Menu>
	);
}
