import { Box, Flex, Text } from '@chakra-ui/layout';
import {
	Button,
	Checkbox,
	Input,
	InputGroup,
	InputRightElement,
	Spinner,
} from '@chakra-ui/react';
import { chakra } from '@chakra-ui/system';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import React, { useEffect, useRef, useState } from 'react';
import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Container from '../../layouts/Container';
import { setIsExecuteCaptcha } from '../../redux/reducers/auth.slice';
import { RootState } from '../../redux/store';
import { ROUTES } from '../../routes/config';
import Hcaptcha from '../UI/Hcaptcha';
import ViewOffIcon from '../Icons/ViewOffIcon';
import ViewIcon from '../Icons/ViewIcon';
import { useTranslation } from 'react-i18next';

// style component atoms
const Wrapper = chakra(Box, {
	baseStyle: {
		minH: '100vh',
		h: { base: 'auto', md: '100vh' },
		w: '100%',
		background: {
			base: 'url(/images/homepage/intro-bg-mobile.png)',
			md: 'url(/images/homepage/intro-bg.png)',
		},
		bgSize: { md: 'cover' },
		bgRepeat: { md: 'no-repeat' },
		color: 'white',
		position: { base: 'relative' },
	},
});

const Title = chakra(Box, {
	baseStyle: {
		fontSize: { base: '24px', md: '38px' },
		lineHeight: { base: '40px', md: '48px' },
		mb: { base: '13px', md: '44px' },
		textAlign: { base: 'center', md: 'left' },
	},
});

const Subtitle = chakra(Box, {
	baseStyle: {
		fontSize: { base: '16px', md: '18px' },
		lineHeight: { base: '20px' },
		mb: { base: '25px' },
		textAlign: { base: 'center', md: 'left' },
	},
});

const ErrorMessage = chakra(Text, {
	baseStyle: {
		color: '#CF304A',
		fontSize: '14px',
		mt: '5px',
		mb: '5px',
	},
});
const ButtonAuth = chakra(Button, {
	baseStyle: {
		background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
		borderRadius: '36px',
		py: '14px',
		fontSize: '16px',
		color: 'black',
		fontWeight: 500,
		maxW: { base: '100%', md: '384px' },
		w: '100%',
		_hover: {
			background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
		},
	},
});

const Content = chakra(Box, {
	baseStyle: {
		mb: '25px',
		w: '100%',
	},
});

type FormFields = {
	key: number;
	label: string | React.ReactNode;
	name: string;
	type: string;
	placeholder?: string;
	required?: boolean | string;
	validate?: any;
};

// render ui
const Authentication = ({
	title,
	titleButton,
	subtitle,
	formFields,
	onSubmit,
	register,
	forgotPassword,
	// isCaptcha = false,
	// setValue,
	errors,
	loading,
}: {
	title: string;
	titleButton: string | React.ReactNode;
	subtitle: string | React.ReactNode;
	formFields: FormFields[];
	onSubmit: React.FormEventHandler<HTMLFormElement>;
	register: UseFormRegister<FieldValues>;
	forgotPassword?: boolean;
	// isCaptcha?: boolean;
	// setValue?: UseFormSetValue<FieldValues>;
	errors?: any;
	loading?: boolean;
}) => {
	// const [captchaToken, setCaptchaToken] = useState('');
	// const isExecuteCaptcha = useSelector(
	// 	(state: RootState) => state.auth.isExecuteCaptcha
	// );
	const [show, setShow] = React.useState(false);
	const handleClick = () => setShow(!show);
	const { t } = useTranslation();
	// const dispatch = useDispatch();

	// const getCaptchaCode = (code: string) => {
	// 	setCaptchaToken(code);
	// };

	// const closeVerifyCaptcha = () => {
	// 	dispatch(setIsExecuteCaptcha(false));
	// };

	// useEffect(() => {
	// 	if (captchaToken && setValue) {
	// 		setValue('captchaToken', captchaToken);
	// 	}
	// 	return () => {
	// 		setCaptchaToken('');
	// 	};
	// }, [captchaToken, setValue]);

	// useEffect(() => {
	// 	if (isCaptcha) {
	// 		register('captchaToken', { value: '' });
	// 	}
	// }, [isCaptcha]);

	return (
		<Wrapper>
			<Container>
				<Flex
					justifyContent={{ base: 'center', md: 'space-between' }}
					alignItems={'center'}
					height={'100%'}
					flexDirection={{ base: 'column', md: 'row' }}
					pt={{ base: '70px', md: 0 }}
				>
					<Flex
						flexDirection={'column'}
						alignItems={{ base: 'center', md: 'flex-start' }}
						data-aos="fade-right"
						data-aos-duration="1000"
						w={{ base: '100%', md: 'auto' }}
					>
						<form style={{ width: '100%' }} onSubmit={onSubmit}>
							<Title>{title}</Title>
							<Subtitle>{subtitle}</Subtitle>
							<Content>
								{formFields.map((field) => (
									<Flex direction={'column'} key={field.key} w={'100%'}>
										{field.type === 'checkbox' && (
											<Checkbox
												colorScheme={'yellow'}
												{...register(field.name, {
													required: field.required,
												})}
											>
												{field.label}
											</Checkbox>
										)}

										{(field.type === 'email' ||
											field.type === 'text' ||
											field.type === 'password') && (
											<>
												<Text color={'#888797'} mb={'13px'}>
													{field.label}
												</Text>
												<InputGroup size="md">
													<Input
														pr="4.5rem"
														type={
															field.type === 'password'
																? show
																	? 'text'
																	: 'password'
																: field.type
														}
														{...register(field.name, {
															required: field.required,
															validate: field.validate,
														})}
														w={{ base: '100%', md: '386px' }}
														py={'14px'}
														background={'white'}
														borderRadius={'36px'}
														_placeholder={{
															color: '#BAB9D0',
														}}
														color="black"
														focusBorderColor="#FFBF1A"
														placeholder={field.placeholder}
														mb={'16px'}
													/>
													{field.type === 'password' && (
														<InputRightElement width="4.5rem">
															<Button onClick={handleClick}>
																{show ? <ViewIcon /> : <ViewOffIcon />}
															</Button>
														</InputRightElement>
													)}
												</InputGroup>
											</>
										)}
										{errors?.[field.name] && (
											<ErrorMessage>
												{errors?.[field.name]?.message}
											</ErrorMessage>
										)}
									</Flex>
								))}

								{forgotPassword && (
									<Link
										style={{ color: '#FFBF1A', marginTop: '-14px' }}
										to={ROUTES.FORGOT_PASSWORD}
									>
										{t('Forgot password')}
									</Link>
								)}
								{/* {isCaptcha && (
									<Box mt={{ base: '10px' }}>
										<Hcaptcha
											getCaptchaCode={getCaptchaCode}
											isExecuteCaptcha={isExecuteCaptcha}
											closeVerifyCaptcha={closeVerifyCaptcha}
										/>
									</Box>
								)} */}
							</Content>
							<ButtonAuth type="submit">
								{loading ? <Spinner /> : titleButton}
							</ButtonAuth>
						</form>
					</Flex>
					<Box data-aos="fade-left" data-aos-duration="1000">
						<img src="/images/homepage/Astronaut.png" alt="" />
					</Box>
				</Flex>
			</Container>
		</Wrapper>
	);
};

export default Authentication;
