import { useRef, useState, useEffect } from 'react';
import AccountMenu from './AccountMenu';
import { Box, Flex, Show } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/config';
import NavigateMobile from '../../components/UI/NavigateMobile';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { changeStatusSecurityDialog } from '../../redux/reducers/system.slice';
import SecurityVerificationDialog from '../../components/Dialog/SecurityVerificationDialog';
import { UserAuthEntity } from 'user-identity-js/lib/entities/user.entity';
import { USER } from '../../constants';

const navigateTitles = [
	{ route: ROUTES.ACCOUNT.MAIN, title: 'Account' },
	{ route: ROUTES.ACCOUNT.ACCOUNT_LIST, title: 'Account List' },
	{ route: ROUTES.ACCOUNT.SAFETY_RECORD, title: 'Safety Record' },
	{ route: ROUTES.ACCOUNT.AD_MANAGEMENT, title: 'Ad Management' },
	{ route: ROUTES.ACCOUNT.API_MANAGEMENT, title: 'API Management' },
	// { route: ROUTES.ACCOUNT.BLACKLIST, title: 'Blacklist' },
	{ route: ROUTES.ACCOUNT.P2P_SETTINGS, title: 'P2P settings' },
	{
		route: ROUTES.ACCOUNT.PERSONAL_VERIFICATION,
		title: 'Personal Verification',
	},
];

export default function AccountLayout() {
	const { isSecurityDialog } = useAppSelector((state) => state.system);
	const userProfile = useAppSelector((state) => state.user.userProfile);
	const dispatch = useAppDispatch();
	const menuRef = useRef<any>();
	const [bodywidth, setBodyWidth] = useState(0);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const user = localStorage.getItem(USER);
		const jsonUser: UserAuthEntity | null = user ? JSON.parse(user) : null;
		if (!jsonUser || !jsonUser?.idToken) {
			navigate(ROUTES.LOGIN);
		}
	}, []);

	useEffect(() => {
		if (menuRef.current) {
			setBodyWidth(menuRef.current?.clientWidth);
		}
	}, [menuRef.current]);

	useEffect(() => {
		if (
			[ROUTES.ACCOUNT.ACCOUNT_LIST].includes(location.pathname) &&
			userProfile
		) {
			if (!userProfile.isEnableTwoFactor) {
				dispatch(changeStatusSecurityDialog(true));
			} else {
				dispatch(changeStatusSecurityDialog(false));
			}
		}
	}, [location.pathname, userProfile]);

	const handleBack = () => {
		if (location.pathname === ROUTES.ACCOUNT.MAIN) {
			navigate(ROUTES.HOME);
		} else navigate(ROUTES.ACCOUNT.MAIN);
	};

	return (
		<>
			<SecurityVerificationDialog isOpen={isSecurityDialog} />
			<Flex h={'100%'}>
				<Show above="sm">
					<Box ref={menuRef}>
						<AccountMenu />
					</Box>

					<Box w={{ base: `calc(100% - ${bodywidth}px)` }} p={{ base: '20px' }}>
						<Outlet />
					</Box>
				</Show>

				<Show below="md">
					<Box w="100%">
						<NavigateMobile
							title={
								navigateTitles.find(
									(title) => title.route === location.pathname
								)?.title || ''
							}
							handleBack={handleBack}
						/>
						{location.pathname === ROUTES.ACCOUNT.MAIN ? (
							<AccountMenu />
						) : (
							<Outlet />
						)}
					</Box>
				</Show>
			</Flex>
		</>
	);
}
