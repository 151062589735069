import React from 'react';
import Container from '../../layouts/Container';
import { Box, Flex, List, ListItem, Text } from '@chakra-ui/react';
import BigNumber from 'bignumber.js';
import { formatCurrency } from '../../utils';

const Statistical = ({ cryptoMarkets }: { cryptoMarkets: any[] }) => {
	const [data, setData] = React.useState<any[]>([]);
	const updateData = () => {
		try {
			const newData = cryptoMarkets?.slice(0, 5)?.map((item: any) => {
				return {
					key: item?.id,
					name: `${item?.symbol}/USDT`,
					lastPrice: `${formatCurrency(item?.current_price)}`,
					_24hChange: item?.price_change_percentage_24h,
					_24hVol: item?.total_volume,
				};
			});
			setData(newData);
		} catch (error) {}
	};
	React.useEffect(() => {
		if (cryptoMarkets) {
			updateData();
		}
	}, [cryptoMarkets]);

	return (
		<Box my={'50px'}>
			<Container>
				<List
					display={'flex'}
					justifyContent={'space-between'}
					overflowX={'auto'}
					overflowY={'hidden'}
				>
					{data.map((token, index) => (
						<ListItem
							key={index + 1}
							mr={{ base: '20px', md: '0' }}
							py={2}
							data-aos="fade-up"
						>
							<Flex alignItems={'flex-end'}>
								<Text
									fontSize={{ base: '14px', md: '20px' }}
									textTransform={{ base: 'uppercase' }}
								>
									{token.name}
								</Text>
								<Text
									fontSize={{ base: '12px', md: '16px' }}
									ml={'8px'}
									lineHeight={'20px'}
									color={
										new BigNumber(token._24hChange).isGreaterThan(0)
											? '#21FF0E'
											: '#FF0E39'
									}
								>
									{new BigNumber(token._24hChange).isGreaterThan(0) && '+'}
									{new BigNumber(token._24hChange).toFixed(2)}%
								</Text>
							</Flex>
							<Text fontSize={{ base: '20px', md: '30px' }} lineHeight={'48px'}>
								{token.lastPrice}
							</Text>
							<Text
								lineHeight={'20px'}
								fontSize={{ base: '14px', md: '16px' }}
								color={'#737283'}
							>
								${token.lastPrice}
							</Text>
						</ListItem>
					))}
				</List>
			</Container>
		</Box>
	);
};

export default Statistical;
