import { useState, useEffect } from 'react';
import Intro from '../components/Home/Intro';
import Statistical from '../components/Home/Statistical';
import AdsCarousel from '../components/Home/AdsCarousel';
import PopularCryptocurrencies from '../components/Home/PopularCryptocurrencies';
import BeginnersGuide from '../components/Home/BeginnersGuide';
import StartYourJourney from '../components/Home/StartYourJourney';
import EasyToInvest from '../components/Home/EasyToInvest';
import SafeAndSecure from '../components/Home/SafeAndSecure';
import WhyChooseMetaniex from '../components/Home/WhyChooseMetaniex';
import OurCertifications from '../components/Home/OurCertifications';
import AtMetaniex from '../components/Home/AtMetaniex';
import RegisterNow from '../components/Home/RegisterNow';
import { Box, Flex, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { coingeckoService } from '../service/coingecko.service';
import { COINGEKO_DATA } from '../constants/cryptos';
import { CloseIcon, WarningIcon } from '@chakra-ui/icons';

export default function HomePage() {
	const [cryptoMarkets, setCryptoMarkets] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const fetchData = async () => {
		try {
			const params = {
				vs_currency: 'usd',
				order: 'market_cap_desc',
				page: 1,
				per_page: 10,
				locale: 'en',
			};
			const data = await coingeckoService.getMarkets(params);
			if (data?.status === 200 && data?.data) {
				setCryptoMarkets(data?.data);
				localStorage.setItem(COINGEKO_DATA, JSON.stringify(data.data));
			} else {
				handleFetchError();
			}
		} catch (error) {
			handleFetchError();
		}
	};
	const handleFetchError = () => {
		const coingekoData = localStorage.getItem(COINGEKO_DATA);
		if (coingekoData) {
			setCryptoMarkets(JSON.parse(coingekoData));
		}
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
		fetchData();
		const interval = setInterval(() => {
			fetchData();
		}, 30000);
		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<Box overflowX={'hidden'}>
			<Modal isCentered isOpen={isOpen} onClose={() => setIsOpen(false)}>
				<ModalOverlay />
				<ModalContent
					style={{
						color: '#fff',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
					height={'20vh'}
				>
					<CloseIcon
						position={'absolute'}
						top={'10px'}
						right={'10px'}
						onClick={() => setIsOpen(false)}
						style={{
							fontSize: '10px',
						}}
						cursor={'pointer'}
					/>
					<Flex alignItems={'center'} gap={'10px'}>
						<WarningIcon style={{ color: 'yellow', fontSize: '24px' }} />
						<Box style={{ fontSize: '24px' }}>
							Service is under maintenance{' '}
						</Box>
					</Flex>
				</ModalContent>
			</Modal>
			<Intro />
			<Statistical cryptoMarkets={cryptoMarkets} />
			<AdsCarousel />
			<PopularCryptocurrencies cryptoMarkets={cryptoMarkets} />
			<BeginnersGuide />
			<StartYourJourney />
			<EasyToInvest />
			<SafeAndSecure />
			<WhyChooseMetaniex />
			<OurCertifications />
			<AtMetaniex />
			<RegisterNow />
		</Box>
	);
}
