import React from 'react';
import { Box, Button, chakra, Image } from '@chakra-ui/react';
import Container from '../../layouts/Container';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';

const ButtonAuth = chakra(Button, {
	baseStyle: {
		background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
		borderRadius: '36px',
		py: '30px',
		px: '40px',
		fontSize: { base: '20px', md: '26px' },
		color: 'black',
		fontWeight: 500,
		_hover: {
			background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
		},
	},
});

const EmptBox = chakra(Box, {
	baseStyle: {
		height: {
			base: '100px',
			md: '400px',
		},
	},
});
const RegisterNow = () => {
	const account = useSelector((state: RootState) => state.auth.account);
	const { t } = useTranslation();
	return (
		<>
			{account && account?.id ? (
				<EmptBox />
			) : (
				<Box my={'118px'}>
					<Container>
						<Box textAlign={'center'}>
							<Box
								fontSize={{ base: '20px', md: '30px' }}
								color={'#FFBF1A'}
								mb={'10px'}
								data-aos="fade-up"
								data-aos-duration={'1500'}
							>
								{t('User has not been registered. Please register now')}
							</Box>
							{/* <Box
								fontSize={{ base: '16px', md: '26px' }}
								color="#BDBCC7"
								mb={'10px'}
								data-aos="fade-up"
								data-aos-duration={'1500'}
							>
								Register your account immediately and start trading.
							</Box> */}

							<ButtonAuth data-aos="fade-up" data-aos-duration={'1500'}>
								<Box>{t('Register Now')}</Box>
								<Image src="/images/homepage/next-icon.svg" ml={'16px'} />
							</ButtonAuth>
						</Box>
					</Container>
				</Box>
			)}
		</>
	);
};

export default RegisterNow;
