import { Box, Flex, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Container from '../../layouts/Container';

const Wrapper = styled(Box)`
	margin-top: 118px;
	padding-block: 100px;
`;

const listJourney = [
	{
		id: 1,
		title: 'SOC 1 Type 2 Exam SOC 2 Type 2 Exam',
		description:
			'We were the first crypto exchange and custodian in the world to obtain our SOC 1 Type 2 & SOC 2 Type 2 certifications. This exam was conducted by Deloitte',
		link: 'https://www.aicpa-cima.com/home',
	},
	{
		id: 2,
		title: 'ISOIEC270012013',
		description:
			'Validates that Krypto8 operates an Information Security Management System (ISMS) that conforms to the requirements of ISO/IEC 27001:2013',
		link: 'https://www.iso.org/standard/27001',
	},
	{
		id: 3,
		title: 'PCI DSS ROC AOC & Attested SAQ',
		description:
			'Evidence of our commitment to maintaining compliance with an information security standard that protects cardholder data both when we accept debit cards on our platform',
		link: 'https://www.pcidssguide.com/',
	},
];

const OurCertifications = () => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'Home.OurCertifications',
	});
	return (
		<Wrapper>
			<Container>
				<Box
					fontSize={{ base: '20px', md: '30px' }}
					textAlign={'center'}
					data-aos="fade-up"
					data-aos-duration={'1500'}
				>
					{t('Our Certifications')}
				</Box>
				<Box
					maxW={'889px'}
					mx={'auto'}
					textAlign={'center'}
					color={'#737283'}
					fontWeight={400}
					fontSize={{ base: '16px', md: '18px' }}
					mb={'20px'}
					data-aos="fade-up"
					data-aos-duration={'1500'}
				>
					{t(
						'Krypto8 is one the safest cryptocurrency exchanges where you can buy, sell, store, and accrue staking rewards on cypto and other cryptocurrencies'
					)}
				</Box>
				<Flex
					flexDir={{ base: 'column', md: 'row' }}
					justifyContent={'space-between'}
				>
					{listJourney.map((journey, index) => (
						<Link
							to={journey.link}
							target={'_blank'}
							key={journey.id}
							style={{ minHeight: '475px' }}
						>
							<Box
								key={journey.id}
								marginBottom={{ base: '60px', md: 0 }}
								bg="linear-gradient(184deg, rgba(255, 255, 255, 0) -21.23%, rgba(255, 255, 255, 0.096) 78.13%)"
								paddingY={'40px'}
								height={'100%'}
								px={'28px'}
								display={'flex'}
								flexDir={'column'}
								justifyContent={'center'}
								alignItems={'center'}
								borderRadius={'20px'}
								data-aos="fade-up"
								data-aos-duration={`1${index + 1}00`}
							>
								<Image
									src={`/images/homepage/certifications-${journey.id}.png`}
								/>
								<Box
									textAlign={'center'}
									my={{ base: '10px', md: '20px' }}
									fontSize={{ base: '20px', md: '24px' }}
									maxW={'328px'}
								>
									{t(`title${index + 1}`)}
								</Box>
								<Box maxW={'328px'} color={'#737283'} textAlign={'center'}>
									{t(`description${index + 1}`)}
								</Box>
							</Box>
						</Link>
					))}
				</Flex>
			</Container>
		</Wrapper>
	);
};

export default OurCertifications;
