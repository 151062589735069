import {
	Box,
	Button,
	Flex,
	Hide,
	Text,
	chakra,
	useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ArrowStepLongIcon from '../../Icons/ArrowStepLongIcon';
import BasicInfo from './BasicInfo';
import UploadIdPhoto from './UploadIdPhoto';
import Submitted from './Submitted';
import {
	CountryOrRegion,
	IdentityCertificateType,
} from 'user-identity-js/lib/entities/kyc.entity';
import { useAppSelector } from '../../../redux/store';
import { UserIdentityClient } from 'user-identity-js';

export const ConnectButton = chakra(Button, {
	baseStyle: {
		background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
		borderRadius: '36px',
		color: '#181A20',
		w: {
			base: '100%',
			lg: '300px',
		},
		h: {
			base: '48px',
		},
	},
});

const initDataSubmit = {
	firstName: '',
	lastName: '',
	countryOrRegion: '',
	idCertificateType: IdentityCertificateType.PASSPORT,
	idNumber: '',
	idDocumentBack: '',
	idDocumentFront: '',
	idDocumentSelfie: '',
};

export default function Body() {
	const toast = useToast();
	const [activeStep, setActiveStep] = useState(1);
	const [dataSubmit, setDataSubmit] = useState(initDataSubmit);
	const userIdentity = useAppSelector((state) => state.system.userIdentity);
	const [countryList, setCountryList] = useState<Option[]>([]);

	useEffect(() => {
		if (userIdentity) {
			getCountryList(userIdentity);
		}
	}, [userIdentity]);

	const handleNextStep = (newData: any, type?: 'next' | 'prev') => {
		if (type === 'prev') {
			return setActiveStep(activeStep - 1);
		}
		setDataSubmit({ ...dataSubmit, ...newData });
		setActiveStep(activeStep + 1);
		if (activeStep === 2) {
			uploadKYCData({ ...dataSubmit, ...newData });
		}
	};

	const steps = [
		{
			number: 1,
			content: 'Basic information',
			component: (
				<BasicInfo
					handleNextStep={handleNextStep}
					data={dataSubmit}
					countryList={countryList}
				/>
			),
		},
		{
			number: 2,
			content: 'Upload ID photo',
			component: <UploadIdPhoto handleNextStep={handleNextStep} />,
		},
		{
			number: 3,
			content: 'Submitted',
			component: <Submitted />,
		},
	];

	const uploadKYCData = async (data: any) => {
		if (!userIdentity) return;
		try {
			const res = await userIdentity.submitKYCProfile(data);
			if (res?.submittedAt) {
				toast({
					title: 'Success',
					description: 'Your KYC data has been submitted',
					status: 'success',
				});
			}
		} catch (error: any) {
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Something went wrong',
			});
		}
	};

	const getCountryList = async (userIdentity: UserIdentityClient) => {
		try {
			const res = await userIdentity?.getKYCMasterData();
			const filterdList = res?.supportedCountryOrRegions.filter(
				(item) => !!item
			) as CountryOrRegion[];
			const countryOptions = filterdList.map((item) => ({
				key: item.code,
				value: item.name,
			}));
			setCountryList(countryOptions);
		} catch (error: any) {
			console.log(error);
		}
	};

	return (
		<>
			<Hide below="lg">
				<Flex
					alignItems={'center'}
					gap={'10px'}
					justifyContent={'center'}
					w={'100%'}
				>
					{steps.map((step, index) => (
						<React.Fragment key={step.number}>
							<StepItem
								number={step.number}
								content={step.content}
								isActive={step.number === activeStep}
							/>

							{index < steps.length - 1 && <ArrowStepLongIcon />}
						</React.Fragment>
					))}
				</Flex>
			</Hide>
			<Hide above="md">
				<Flex alignItems={'center'} gap="5px">
					{steps
						.filter((step) => step.number === activeStep)
						.map((step) => (
							<React.Fragment key={step.number}>
								<StepItem
									number={step.number}
									content={step.content}
									isActive={step.number === activeStep}
								/>

								{step.number !== steps[steps.length - 1].number && (
									<ArrowStepLongIcon />
								)}
							</React.Fragment>
						))}
				</Flex>
			</Hide>
			<Box mt={{ base: '20px' }}>
				{steps.find((step) => step.number === activeStep)?.component}
			</Box>
		</>
	);
}

const StepItem = ({
	number,
	content,
	isActive,
}: {
	number: number;
	content: string;
	isActive: boolean;
}) => {
	return (
		<Flex gap={'8px'} alignItems={'center'}>
			<Flex
				w={{ base: '23px' }}
				h={{ base: '23px' }}
				border={'1px solid'}
				borderColor={isActive ? '#FFFFFF' : '#a09fb0'}
				borderRadius={'100px'}
				alignItems={'center'}
				justifyContent={'center'}
			>
				<Text
					fontSize={{ base: '14px' }}
					color={isActive ? '#FFFFFF' : '#a09fb0'}
				>
					{number}
				</Text>
			</Flex>
			<Text
				fontSize={{ base: '14px' }}
				color={isActive ? '#FFFFFF' : '#a09fb0'}
				whiteSpace={'pre'}
			>
				{content}
			</Text>
		</Flex>
	);
};
