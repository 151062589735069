import { Box, chakra } from '@chakra-ui/react';

const Container = chakra(Box, {
	baseStyle: {
		maxW: 1248,
		mx: 'auto',
		px: { base: '18px', xl: 0 },
		height: '100%',
		position: 'relative',
	},
});

export default Container;
