import AmountInput from '../../UI/AmountInput';
import { InputLabel } from '../../UI/InputLabel';
import LineBox from '../../UI/LineBox';
import { Box, Flex, Spinner, Text, useToast } from '@chakra-ui/react';
import { ErrorMessage } from '../../Wallets/TransferModal/styles';
import { useForm } from 'react-hook-form';
import { ConnectButton } from '../IDVerification/Body';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { PaymentMethod } from 'skychain-p2p-trading-js/lib/dto/payment-method.dto';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes/config';
import { fetchPaymentMethodsRequest } from '../../../redux/actions/user.action';
import { useState } from 'react';

const AddPaymentMethod = () => {
	const toast = useToast();
	const dispatch = useDispatch();
	const p2pTradingClient = useSelector(
		(state: RootState) => state.system.p2pTradingClient
	);
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
		watch,
	} = useForm<PaymentMethod>();
	const [loading, setLoading] = useState(false);

	const onSubmit = async (data: PaymentMethod) => {
		if (!p2pTradingClient || loading) return;
		try {
			setLoading(true);
			const res = await p2pTradingClient.createPaymentMethod(data);
			if (res?.id) {
				toast({
					title: 'Success',
					description: 'Payment method added successfully',
					status: 'success',
					isClosable: true,
				});
				dispatch(fetchPaymentMethodsRequest({ payload: undefined }));
				reset();
				navigate(ROUTES.ACCOUNT.P2P_SETTINGS);
			}
		} catch (error: any) {
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Something went wrong',
				isClosable: true,
			});
		} finally {
			setLoading(false);
		}
	};
	return (
		<Box p={18}>
			<Text fontSize={'20px'} my={'30px'} fontWeight={'500'}>
				Add Bank Transfer
			</Text>

			<LineBox mb={'30px'} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Flex
					direction={'column'}
					gap={{ base: '20px' }}
					maxW={{ lg: '458px' }}
					mx={'auto'}
				>
					<Box>
						<InputLabel mb={{ base: '10px' }}>Full name</InputLabel>
						<AmountInput
							placeholder="Enter your Full name..."
							placeholderColor="#959595"
							textColor="#ffffff"
							register={register('fullName', {
								required: 'This field is required',
							})}
						/>
						{errors.fullName?.message && (
							<ErrorMessage>{errors.fullName.message}</ErrorMessage>
						)}
					</Box>

					<Box>
						<InputLabel mb={{ base: '10px' }}>Bank Account Number</InputLabel>
						<AmountInput
							placeholder="Enter your Bank Account Number..."
							placeholderColor="#959595"
							textColor="#ffffff"
							register={register('bankAccountNumber', {
								required: 'This field is required',
							})}
							value={watch('bankAccountNumber')}
							name={'bankAccountNumber'}
							setValue={setValue}
						/>
						{errors.bankAccountNumber?.message && (
							<ErrorMessage>{errors.bankAccountNumber.message}</ErrorMessage>
						)}
					</Box>

					<Box>
						<InputLabel mb={{ base: '10px' }} textColor="#959595">
							Bank Name
						</InputLabel>
						<AmountInput
							placeHolder={'Enter your Bank Name...'}
							register={register('bankName', {
								required: 'This field is required',
							})}
							expandW={'7.5rem'}
							placeholderColor="#959595"
							textColor="#ffffff"
						/>
						{errors.bankName?.message && (
							<ErrorMessage>{errors.bankName.message}</ErrorMessage>
						)}
					</Box>

					<Box>
						<InputLabel mb={{ base: '10px' }}>
							Account Opening Branch (Optional)
						</InputLabel>
						<AmountInput
							placeHolder={'Enter your Bank Branch Information...'}
							register={register('bankBranch')}
							expandW={'7.5rem'}
							placeholderColor="#959595"
							textColor="#ffffff"
						/>
						{errors.bankBranch?.message && (
							<ErrorMessage>{errors.bankBranch.message}</ErrorMessage>
						)}
					</Box>
				</Flex>
				<Text
					textAlign={'center'}
					color={'#9b9aad'}
					fontSize={{ base: '14px', lg: '16px' }}
					// maxW={'600px'}
					// mx={'auto'}
					my={{ base: '20px' }}
					whiteSpace={{ lg: 'pre' }}
					lineHeight={{ base: '28px' }}
				>
					{`Tips: During trade, details of the added payment method will be shown to the buyer to make due payment, whereas sellers will see the buyer’s real name.
Please ensure that the information is correct, real, and matches your KYC information on Krypto8`}
				</Text>

				<Flex justifyContent={'center'}>
					<ConnectButton mx={'auto'} type="submit">
						{loading ? <Spinner /> : 'Confirm'}
					</ConnectButton>
				</Flex>
			</form>
		</Box>
	);
};

export default AddPaymentMethod;
