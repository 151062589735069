import { Box, Flex, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Container from '../../layouts/Container';

const Wrapper = styled(Box)`
	margin-top: 118px;
	background-color: #091229;
`;

const AtMetaniex = () => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'Home.AtMetaniex',
	});
	return (
		<Wrapper py={'100px'}>
			<Container>
				<Box
					textAlign={'center'}
					fontSize={{ base: '20px', md: '30px' }}
					mb={'10px'}
					data-aos="fade-up"
					data-aos-duration={'1500'}
				>
					{t('At Krypto8!')}
				</Box>
				<Box
					fontSize={{ base: '16px', md: '24px' }}
					color="#BDBCC7"
					fontWeight={300}
					lineHeight={'37px'}
					fontStyle="normal"
					data-aos="fade-up"
					data-aos-duration={'1500'}
				>
					<Trans>“{t('description')}”.</Trans>
					<Text my={'60px'}>
						<Trans>{t('description2')}</Trans>
					</Text>
					<Flex
						justifyContent={'flex-end'}
						data-aos="fade-up"
						data-aos-duration={'1500'}
					>
						<Image src="/images/homepage/Signature.svg" />
					</Flex>
				</Box>
			</Container>
		</Wrapper>
	);
};

export default AtMetaniex;
