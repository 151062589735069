import { Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const InputLabel = styled(Text)`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #9d9da5;
	/* margin-bottom: 10px; */
`;
