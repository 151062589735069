import { Box, chakra, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import LineBox from '../../UI/LineBox';
import Checked from './icons/Checked';
import { useAppSelector } from '../../../redux/store';
import Unverified from '../../Icons/Unverified';

const SecondaryText = chakra(Text, {
	baseStyle: {
		fontSize: 14,
		color: '#737283',
	},
});

const PrimaryText = chakra(Text, {
	baseStyle: {
		fontSize: 16,
		color: '#FFFFFF',
	},
});

const Avatar = chakra(Image, {
	baseStyle: {
		width: {
			base: '76px',
			lg: '110px',
		},
	},
});

interface Props {
	isVerified?: boolean;
}
const CurrenFeature = ({ isVerified }: Props) => {
	const userProfile = useAppSelector((state) => state.user.userProfile);

	return (
		<Box>
			<Text fontSize={'20px'} my={'30px'} fontWeight={'500'}>
				Current Features
			</Text>
			<LineBox />
			<Flex
				flexDir={{ base: 'column', md: 'row' }}
				alignItems={{ base: 'flex-start', md: 'center' }}
				py={'30px'}
				w={'100%'}
			>
				<Flex
					w={{ base: '100%', md: '30%' }}
					gap={'10px'}
					alignItems={{ base: 'flex-start', md: 'center' }}
					justifyContent={{ base: '', lg: 'flex-start' }}
					mb={{ base: '30px', md: '0' }}
				>
					<Box borderRadius={'100px'} overflow={'hidden'}>
						<Avatar
							src={
								userProfile?.avatar ||
								'/images/account-list/personal-avatar.png'
							}
							alt="personal avatar"
						/>
					</Box>
					<Flex direction={'column'} gap={'10px'}>
						<Flex alignItems={'center'} gap="10px">
							<SecondaryText>
								{userProfile?.firstName || '' + ' ' + userProfile?.lastName}
							</SecondaryText>
						</Flex>
						<PrimaryText>{userProfile?.email}</PrimaryText>
						<Flex alignItems={'center'}>
							<SecondaryText mr={'12px'}>ID: {userProfile?.id}</SecondaryText>
							{isVerified ? <Checked /> : <Unverified />}
						</Flex>
					</Flex>
				</Flex>
			</Flex>
			<LineBox />
		</Box>
	);
};

export default CurrenFeature;
