export default function SingleArrowRight(props: any) {
	return (
		<svg
			{...props}
			width={6}
			height={12}
			viewBox="0 0 6 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.217339 11.2852C-0.0724465 10.9988 -0.0724465 10.5345 0.217339 10.2481L4.51591 6L0.217339 1.75188C-0.0724469 1.46549 -0.0724469 1.00117 0.217339 0.714788C0.507125 0.428403 0.97696 0.428403 1.26675 0.714788L5.56532 4.96291C6.14489 5.53568 6.14489 6.46432 5.56532 7.03709L1.26675 11.2852C0.976961 11.5716 0.507125 11.5716 0.217339 11.2852Z"
				fill={props.color || '#737283'}
			/>
		</svg>
	);
}
