export default function ViewOffIcon(props: any) {
	return (
		<svg
			{...props}
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Frame" clipPath="url(#clip0_634_2209)">
				<g id="Group">
					<g id="Group_2">
						<g id="Group_3">
							<path
								id="Vector"
								d="M11.8174 8.06863L15.253 11.4571L15.2693 11.2796C15.2693 9.49914 13.8026 8.05249 11.9973 8.05249L11.8174 8.06863Z"
								fill="#BAB9D0"
							/>
							<path
								id="Vector_2"
								d="M11.9975 5.90148C15.0077 5.90148 17.4509 8.31121 17.4509 11.2801C17.4509 11.974 17.3093 12.6355 17.0639 13.2431L20.2541 16.3895C21.9008 15.0342 23.1989 13.2809 24.0005 11.2801C22.1084 6.55799 17.4566 3.2124 11.998 3.2124C10.4709 3.2124 9.00973 3.48116 7.65186 3.96522L10.0077 6.28318C10.6232 6.0467 11.294 5.90148 11.9975 5.90148Z"
								fill="#BAB9D0"
							/>
							<path
								id="Vector_3"
								d="M1.78579 2.28401C1.40184 2.66269 1.40184 3.27685 1.78579 3.65552L3.5772 5.42236L4.07362 5.91197C2.27403 7.29962 0.850716 9.14461 0 11.2795C1.88701 16.0017 6.54397 19.3473 11.9974 19.3473C13.6881 19.3473 15.3021 19.0246 16.7802 18.4381L17.2439 18.8955L19.7311 21.3526C20.1135 21.7303 20.7342 21.7313 21.1176 21.3546C21.502 20.977 21.5026 20.3633 21.1191 19.9851L3.17638 2.28401C2.79243 1.90533 2.16973 1.90533 1.78579 2.28401ZM7.12219 8.91317L8.80726 10.5751C8.75818 10.8066 8.72546 11.0375 8.72546 11.2795C8.72546 13.06 10.1922 14.5066 11.9974 14.5066C12.2428 14.5066 12.4775 14.4744 12.7065 14.426L14.3916 16.0879C13.6662 16.4429 12.8594 16.6582 11.9974 16.6582C8.98722 16.6582 6.54397 14.2485 6.54397 11.2795C6.54397 10.4299 6.76227 9.63372 7.12219 8.91317Z"
								fill="#BAB9D0"
							/>
						</g>
					</g>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_634_2209">
					<rect width={24} height={24} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
