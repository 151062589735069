import React, { useEffect, useState } from 'react';
import PostAndCloseAds from './PostAndCloseAds';
import { Box, Flex } from '@chakra-ui/react';
import Container from '../../layouts/Container';
import Filter from './Filter';
import AdsData from './AdsData';
import { AdOrderEntity } from 'skychain-p2p-trading-js/lib/entities';
import { useSelector } from 'react-redux';
import { P2PTradingClient } from 'skychain-p2p-trading-js';
import { RootState } from '../../redux/store';
import { PageOptions } from 'user-identity-js/lib/dto';
import { AdOrderStatus, QueryAdOrder } from 'skychain-p2p-trading-js/lib/dto';
import Pagination from '../UI/Pagination';

export default function Body() {
	const [ads, setAds] = React.useState<AdOrderEntity[]>([]);
	const p2pTradingClient = useSelector(
		(state: RootState) => state.system.p2pTradingClient
	);
	const [params, setParams] = React.useState<PageOptions>({
		page: 1,
		pageSize: 10,
	});
	const [totalRecord, setTotalRecord] = React.useState(0);
	const [query, setQuery] = useState<QueryAdOrder>({
		statuses: [AdOrderStatus.OFFLINE, AdOrderStatus.ONLINE],
	});
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		getAds(p2pTradingClient, query, params);
	}, [p2pTradingClient, query, params]);

	const getAds = async (
		p2pTradingClient: P2PTradingClient,
		query?: QueryAdOrder,
		params?: PageOptions
	) => {
		if (!p2pTradingClient) return;
		try {
			setLoading(true);
			const res = await p2pTradingClient.getAdOrders(query, params);
			setAds(res.data);
			setTotalRecord(res.meta.totalRecord || 0);
		} catch (error) {
			console.log('getAds', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box>
			<PostAndCloseAds
				refresh={() => getAds(p2pTradingClient, query, params)}
			/>
			<Container>
				<Filter setQuery={setQuery} query={query} />
				<AdsData
					ads={ads}
					refresh={() => getAds(p2pTradingClient, query, params)}
					loading={loading}
				/>
				<Flex justifyContent={'center'} paddingBottom={'30px'}>
					<Pagination
						handleChangePage={(page) => {
							setParams({
								...params,
								page,
							});
						}}
						page={params.page || 1}
						pagination={{
							total: totalRecord,
							pageSize: params.pageSize || 10,
						}}
					/>
				</Flex>
			</Container>
		</Box>
	);
}
