import { RouterProvider } from 'react-router-dom';
import './App.css';
import { router } from './routes';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { USER } from './constants';
import { useSelector } from 'react-redux';
import { logout, setAccount } from './redux/reducers/auth.slice';
import {
	setRefreshToken,
	setSupportedAssets,
	setSupportedChains,
	setTokenId,
} from './redux/reducers/system.slice';
import { UserAuthEntity } from 'user-identity-js/lib/entities/user.entity';
import MetaverseClient from 'metaverse-js';
import { RootState, useAppDispatch } from './redux/store';
import {
	fetchGameBalanceRequest,
	fetchPaymentMethodsRequest,
	fetchUserAssetBalancesRequest,
	fetchUserProfileRequest,
} from './redux/actions/user.action';
import { i18nInit } from './i18n';
import { resetUser } from './redux/reducers/user.slice';

i18nInit();

function getLibrary(provider: any): Web3Provider {
	const library = new Web3Provider(provider);
	library.pollingInterval = 12_000;
	return library;
}

function App() {
	const dispatch = useAppDispatch();
	const account = useSelector((state: RootState) => state.auth.account);
	const metaverseJs = useSelector(
		(state: RootState) => state.system.metaverseJs
	);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const p2pTradingClient = useSelector(
		(state: RootState) => state.system.p2pTradingClient
	);
	const gameCenterClient = useSelector(
		(state: RootState) => state.system.gameCenterClient
	);

	useEffect(() => {
		userIdentity?.onTokenChange((tokenId, refreshToken) => {
			dispatch(setRefreshToken(refreshToken));
			dispatch(setTokenId(tokenId));
			if (tokenId === '' || refreshToken === '') {
				dispatch(logout());
				dispatch(resetUser());
				return;
			}
			const user = localStorage.getItem(USER);
			const jsonUser: UserAuthEntity | null = user ? JSON.parse(user) : null;
			const newJsonUser = { ...jsonUser, idToken: tokenId, refreshToken };
			localStorage.setItem(USER, JSON.stringify(newJsonUser));
		});
	}, [userIdentity]);

	useEffect(() => {
		AOS.init();
		const user = localStorage.getItem(USER);
		const jsonUser: UserAuthEntity | null = user ? JSON.parse(user) : null;
		if (jsonUser) {
			dispatch(setAccount(jsonUser));
		} else {
			dispatch(setAccount(null));
		}
		jsonUser?.idToken && dispatch(setTokenId(jsonUser.idToken));
	}, []);

	useEffect(() => {
		if (metaverseJs) {
			getSupportedChains(metaverseJs);
			getSupportedToken(metaverseJs);
		}
	}, [metaverseJs]);

	useEffect(() => {
		if (metaverseJs?.tokenId) {
			dispatch(fetchUserAssetBalancesRequest({ payload: undefined }));
		}
	}, [metaverseJs?.tokenId]);

	useEffect(() => {
		if (account?.idToken && gameCenterClient) {
			dispatch(fetchGameBalanceRequest({ payload: undefined }));
		}
	}, [gameCenterClient, account?.idToken]);

	useEffect(() => {
		if (userIdentity && account?.id) {
			dispatch(fetchUserProfileRequest({ userId: account.id }));
		}
	}, [userIdentity, account?.id]);

	useEffect(() => {
		if (p2pTradingClient && account?.idToken) {
			dispatch(fetchPaymentMethodsRequest({ payload: undefined }));
		}
	}, [p2pTradingClient, account?.idToken]);

	const getSupportedToken = async (metaverseJs: MetaverseClient) => {
		try {
			const res = await metaverseJs.getDefaultSupportedAssets();
			dispatch(setSupportedAssets(res));
		} catch (error) {
			console.log('getSupportedToken', error);
		}
	};

	const getSupportedChains = async (metaverseJs: MetaverseClient) => {
		try {
			const res = await metaverseJs.getSupportChain();
			dispatch(setSupportedChains(res));
		} catch (error) {
			console.log('getSupportChain', error);
		}
	};

	return (
		<Web3ReactProvider getLibrary={getLibrary}>
			<RouterProvider router={router} />
		</Web3ReactProvider>
	);
}

export default App;
