import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Authentication from '.';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { ROUTES } from '../../routes/config';
import { useToast } from '@chakra-ui/react';
import { USER } from '../../constants';
import { UserAuthEntity } from 'user-identity-js/lib/entities/user.entity';

const ChangePassword = () => {
	const {
		register,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm();
	const formFields = [
		{
			key: 1,
			label: 'Old password',
			name: 'oldPassword',
			type: 'password',
			placeholder: 'Enter your password...',
			required: 'This field is required',
		},
		{
			key: 1,
			label: 'New password',
			name: 'newPassword',
			type: 'password',
			placeholder: 'Confirm your new password...',
			required: 'This field is required',
		},
		{
			key: 1,
			label: 'Confirm new password',
			name: 'confirmNewPassword',
			type: 'password',
			placeholder: 'Confirm your new new password...',
			required: 'This field is required',
			validate: (value: any) => {
				if (value !== watch('newPassword')) {
					return 'Password does not match';
				}
				return true;
			},
		},
	];
	const navigate = useNavigate();
	const toast = useToast();
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		const user = localStorage.getItem(USER);
		const jsonUser: UserAuthEntity | null = user ? JSON.parse(user) : null;
		if (!jsonUser || !jsonUser?.idToken) {
			navigate(ROUTES.LOGIN);
		}
	}, []);

	const onSubmit = async (value: any) => {
		if (!userIdentity || loading) return;
		try {
			setLoading(true);
			const res = await userIdentity?.changePassword({
				oldPassword: value.oldPassword,
				newPassword: value.newPassword,
			});
			if (res) {
				toast({
					title: 'Success',
					description: 'Change password successfully',
					status: 'success',
				});
				reset();
				navigate(ROUTES.HOME);
			}
		} catch (error: any) {
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Unknown error',
				status: 'error',
			});
		} finally {
			setLoading(false);
		}
	};
	return (
		<Authentication
			title={'Change password'}
			titleButton={'Confirm'}
			subtitle={<div></div>}
			formFields={formFields}
			register={register}
			onSubmit={handleSubmit(onSubmit)}
			errors={errors}
			loading={loading}
		/>
	);
};

export default ChangePassword;
