import {
	Box,
	Flex,
	Modal,
	ModalCloseButton,
	ModalOverlay,
	Spinner,
	useToast,
	Text,
	Input,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import {
	setModal,
	setTwoFactorCode,
} from '../../../redux/reducers/system.slice';
import Steps from '../Steps';
import QuestionIcon from '../../Icons/QuestionIcon';
import SelectCustom from '../../UI/SelectCustom';
import { useEffect, useMemo, useState } from 'react';
import TextGradient from '../../UI/TextGradient';
import MetaverseClient from 'metaverse-js';
import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import { Responsive } from 'metaverse-js/lib/index';
import { format } from 'date-fns';

import {
	ChainItem,
	ChainListWrapper,
	WrapperInput,
	DepositContent,
	DepositHistory,
	Header,
	Label,
	LeftColumn,
	Retangle,
	RightColumn,
	Row,
	StepPanel,
	StyledDepositHistoryDesktop,
	StyledDepositHistoryMobile,
	StyledInput,
	StyledModalBody,
	StyledModalContent,
	StyledModalHeader,
	Title,
	WithDrawAddressHeader,
	MaxBtn,
	CurrencyLabel,
	WithdrawButton,
	StyledForm,
	ErrorMessage,
} from './styles';
import { WITHDRAW_STEPS } from '../../../constants/deposit';
import BigNumber from 'bignumber.js';
import { useForm } from 'react-hook-form';
import { isAddress } from 'ethers';
import { mapAssetsToOptions, mapChainsToOptions } from '../../../utils/asset';
import useWalletAddress from '../../../hooks/useWalletAddress';
import { Asset } from 'metaverse-js/lib/proto/model/asset';
import { fetchUserAssetBalancesRequest } from '../../../redux/actions/user.action';
import Pagination from '../../UI/Pagination';
import LoadingUI from '../../UI/LoadingUI';
import { TRANSLATION, paginationDefault } from '../../../constants';
import { formatCurrency, validateInputNumber } from '../../../utils';
import { validateTronAddress } from '../../../utils/address';
import { useTranslation } from 'react-i18next';

interface WithdrawForm {
	chain: string;
	withdrawAddress: string;
	amount: string;
	selectedToken: string;
	twoFactorCode: string;
}

const WithdrawModal: React.FC = () => {
	const toast = useToast();
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
		clearErrors,
		setError,
		watch,
	} = useForm<WithdrawForm>();
	const { t } = useTranslation(TRANSLATION, {
		keyPrefix: 'Wallet',
	});
	const modal = useSelector((state: RootState) => state.system.modal);
	const chain = useSelector((state: RootState) => state.system.chain);
	const userAccount = useSelector((state: RootState) => state.auth.account);
	const myAssetBalances = useSelector(
		(state: RootState) => state.user.userAssetBalances
	);
	const dispatch = useDispatch();
	const metaverseJs = useSelector(
		(state: RootState) => state.system.metaverseJs
	);
	const supportedChains = useSelector(
		(state: RootState) => state.system.supportedChains
	);
	const supportedAssets = useSelector(
		(state: RootState) => state.system.supportedAssets
	);

	const [selectedToken, setSelectedToken] = useState<Option | undefined>();
	const [selectedChain, setSelectedChain] = useState<Option | undefined>();
	const [transactions, setTransactions] = useState<TransactionHistory[]>([]);
	const [total, setTotal] = useState(0);
	const [paging, setPaging] = useState(paginationDefault);
	const { address: userWalletAddress } = useWalletAddress('SPOT');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [withdrawing, setWithdrawing] = useState<boolean>(false);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const assetBalance = useMemo(() => {
		if (!selectedToken || !myAssetBalances || !chain) return;
		const res = myAssetBalances.find(
			(asset) =>
				asset.assetSymbol === selectedToken.value && asset.chain === chain
		);
		return res;
	}, [myAssetBalances, selectedToken, chain]);

	const withdrawFee = useMemo(() => {
		if (!selectedToken || !supportedAssets) return '-';
		const asset = supportedAssets.find(
			(asset: Asset) => asset.symbol === selectedToken.value
		);
		if (!asset) return '-';
		return asset.transactionFeePercent;
	}, [supportedAssets, selectedToken]);

	const supportedAssetsOptions = useMemo(
		() => mapAssetsToOptions(supportedAssets),
		[supportedAssets]
	);

	const supportedChainOptions = useMemo(
		() => mapChainsToOptions(supportedChains),
		[supportedChains]
	);

	// const selectedTokenInfo = useMemo(() => {
	// 	return supportedAssets.find(
	// 		(token) => token.symbol === selectedToken?.value
	// 	);
	// }, [selectedToken, supportedAssets]);

	useEffect(() => {
		if (supportedAssetsOptions.length > 0 && modal === 'withdraw') {
			setSelectedToken(supportedAssetsOptions[0]);
			setValue('selectedToken', supportedAssetsOptions[0].value);
		}
	}, [supportedAssetsOptions, modal]);

	useEffect(() => {
		if (supportedChainOptions.length > 0 && modal === 'withdraw') {
			const selectedChain =
				supportedChainOptions.find((chain) => chain.key === 'BSC') ||
				supportedChainOptions[0];
			setSelectedChain(selectedChain);
			setValue('chain', selectedChain.value);
		}
	}, [supportedChainOptions, modal]);

	useEffect(() => {
		if (metaverseJs && modal === 'withdraw') {
			getTransactions(metaverseJs, paging);
		}
	}, [selectedChain, metaverseJs, modal, paging]);

	const getTransactions = async (
		metaverseJs: MetaverseClient,
		paging: { page: number; pageSize: number }
	) => {
		setIsLoading(true);
		try {
			const res: Responsive<Transaction[]> =
				await metaverseJs.getMyTransactions({
					type: 'WITHDRAW',
					limit: paging.pageSize,
					offset: paging.pageSize * paging.page - paging.pageSize,
				});
			const transactions: Transaction[] = res.data;
			setTransactions(
				transactions.map((item) => ({
					currency: item.assetSymbol || '',
					time: item.createdAt
						? format(new Date(item.createdAt * 1000), 'yyyy-MM-dd HH:mm a')
						: '',
					amount: item.assetAmount || '',
					address: item.toAccount || '',
					transactionId: item.id || '',
					state: item.state || '',
					onchainHash: item.onchainHash || '',
					chain: item.toChain || '',
				}))
			);
			if (res && res.meta) {
				setTotal(res.meta.total);
			}
		} catch (error) {
			console.log('getTransactions', error);
		} finally {
			setIsLoading(false);
		}
	};

	const onSubmit = async (values: WithdrawForm) => {
		if (
			!chain ||
			!metaverseJs ||
			!userIdentity ||
			!userWalletAddress ||
			withdrawing
		)
			return;
		try {
			setWithdrawing(true);
			const resTwoFaCode = await userIdentity.getTokenTwoFactor({
				tfaCode: values.twoFactorCode,
			});
			if (resTwoFaCode) {
				await dispatch(setTwoFactorCode(resTwoFaCode.data.idToken));
				if (values.chain === 'TRON') {
					const res = await validateTronAddress(values.withdrawAddress);
					if (!res.result) {
						setError('withdrawAddress', {
							message: t('Invalid address'),
						});
						return;
					}
				}
				const txBody: Transaction = {
					assetSymbol: values.selectedToken,
					toAccount: values.withdrawAddress,
					fromChain: chain,
					fromAccount: userWalletAddress || '',
					fromUser: userAccount?.id || '',
					toChain: values.chain || '',
					assetAmount: values.amount,
					assetType: 'ERC20',
					nonce: (userAccount?.id || '') + Date.now(),
				};
				const res = await metaverseJs.withdraw(txBody);
				if (res?.nonce) {
					toast({
						title: t('Success'),
						status: 'success',
						description: t('Withdraw successfully'),
					});
					await new Promise((resolve) => setTimeout(resolve, 1000));
					dispatch(fetchUserAssetBalancesRequest({ payload: undefined }));
					dispatch(setModal(null));
					handleClose();
				}
			}
		} catch (error: any) {
			toast({
				title: 'Error',
				status: 'error',
				description: error?.response?.data?.message || t('Withdraw failed'),
			});
		} finally {
			setWithdrawing(false);
		}
	};

	const handleClose = () => {
		dispatch(setModal(null));
		reset();
	};

	return (
		<Modal isOpen={modal === 'withdraw'} onClose={handleClose} isCentered>
			<ModalOverlay />
			<StyledModalContent>
				<StyledModalHeader>{t('Withdraw')}</StyledModalHeader>
				<ModalCloseButton />
				<StyledModalBody>
					<StepPanel>
						<Steps steps={WITHDRAW_STEPS} />
					</StepPanel>
					<StyledForm onSubmit={handleSubmit(onSubmit)}>
						<DepositContent>
							<LeftColumn>
								<Label>{t('Tokens')}</Label>
								<SelectCustom
									{...register('selectedToken', {
										required: t('Please select token'),
									})}
									list={supportedAssetsOptions}
									value={selectedToken}
									onSelect={(item) => {
										setValue('selectedToken', item.value);
										setSelectedToken(item);
									}}
								/>
								{errors.selectedToken && (
									<ErrorMessage>{errors.selectedToken?.message}</ErrorMessage>
								)}
								<Row>
									<div>{t('Balances')}</div>
									<div>{formatCurrency(assetBalance?.totalAssetAmount)}</div>
								</Row>
								<Row>
									<div>{t('Available')}</div>
									<div>
										{formatCurrency(assetBalance?.availableAssetAmount || 0)}
									</div>
								</Row>
								<Row>
									<div>{t('Freeze')}</div>
									<div>
										{formatCurrency(assetBalance?.lockedAssetAmount || 0)}
									</div>
								</Row>
								<Row>{t('Tips')}</Row>
								<Row>
									<Label>{t('Withdrawable')}:</Label>
									<div>
										{formatCurrency(assetBalance?.availableAssetAmount, 4)}{' '}
										{selectedToken?.value || 'USDT'}
									</div>
								</Row>
								{/* <Row>
									<Label>24h Withdrawal Limit:</Label>
									<div>50000.00 /50000.00 {selectedToken?.value || 'USDT'}</div>
								</Row> */}
								{/* <Warning>
									<WarningIcon />
									<WarningContent>
										Min Limit{' '}
										{
											+new BigNumber(
												selectedTokenInfo?.minPerOneOrder || 0
											).toFixed(4)
										}
										，Max Limit{' '}
										{
											+new BigNumber(
												selectedTokenInfo?.maxPerOneDay || 0
											).toFixed(4)
										}
									</WarningContent>
								</Warning> */}
							</LeftColumn>
							<RightColumn>
								<Label>
									{t('Chain name')} <QuestionIcon />
								</Label>
								<ChainListWrapper
									{...register('chain', {
										required: t('Please select chain'),
									})}
								>
									{supportedChainOptions.map((chain) => (
										<ChainItem
											selected={selectedChain?.key === chain.key}
											key={chain.key}
											onClick={() => {
												setSelectedChain(chain);
												setValue('chain', chain.value);
												clearErrors();
											}}
										>
											{chain.value}
										</ChainItem>
									))}
								</ChainListWrapper>
								{errors.chain && (
									<ErrorMessage>{errors.chain?.message}</ErrorMessage>
								)}
								<WithDrawAddressHeader>
									<Label>{t('Withdraw Address')}</Label>
									{/* <StyledTextGradient>Address List</StyledTextGradient> */}
								</WithDrawAddressHeader>
								<StyledInput
									{...register('withdrawAddress', {
										required: t('Please enter withdraw address'),
										validate: (value) => {
											if (selectedChain?.key === 'BSC' && !isAddress(value)) {
												return t('Invalid address');
											}
											return true;
										},
									})}
									placeholder={t('Please enter withdraw address')}
								/>
								{errors.withdrawAddress && (
									<ErrorMessage>{errors.withdrawAddress?.message}</ErrorMessage>
								)}
								<WithDrawAddressHeader>
									<Label>{t('Amount')}</Label>
								</WithDrawAddressHeader>
								<WrapperInput>
									<StyledInput
										{...register('amount', {
											required: t('Please enter amount'),
											validate: (value) => {
												if (new BigNumber(value).lte(0)) {
													return t('Amount must be greater than 0');
												}
												if (
													new BigNumber(value).gt(
														assetBalance?.availableAssetAmount || 0
													)
												) {
													return t('Insufficient balance');
												}
												return true;
											},
										})}
										step={'any'}
										placeholder={t('Amount')}
										onChange={(e) => {
											const value = e.target?.value;

											if (validateInputNumber(value)) {
												setValue('amount', value);
											}
										}}
										value={watch('amount')}
									/>
									<MaxBtn
										onClick={() => {
											setValue(
												'amount',
												new BigNumber(
													assetBalance?.availableAssetAmount || 0
												).toString()
											);
											clearErrors('amount');
										}}
									>
										{t('All')}
									</MaxBtn>
									<CurrencyLabel>
										{selectedToken?.value || 'USDT'}
									</CurrencyLabel>
								</WrapperInput>
								{errors.amount && (
									<ErrorMessage>{errors.amount?.message}</ErrorMessage>
								)}
								<Text mb={'11px'} color={'#737283'}>
									{t('Google authentication code')}
								</Text>
								<Input
									bg="rgba(255, 255, 255, 0.05)"
									color="#fff"
									mb={'20px'}
									{...register('twoFactorCode', {
										required: t('Two factor code is required'),
									})}
									type="number"
								/>
								{errors?.twoFactorCode?.message && (
									<ErrorMessage>{errors.twoFactorCode.message}</ErrorMessage>
								)}
								<Label>
									{t('Fee')}: {withdrawFee}%
								</Label>
								<WithdrawButton type="submit">
									{withdrawing ? <Spinner /> : t('Withdraw')}
								</WithdrawButton>
							</RightColumn>
						</DepositContent>
					</StyledForm>
					<DepositHistory>
						<Header>
							<Title>{t('Recent')}</Title>
							<TextGradient>{t('All')}</TextGradient>
						</Header>
						<Retangle />
						{!isLoading ? (
							<>
								<StyledDepositHistoryDesktop
									type="Withdraw"
									list={transactions}
								/>
								<StyledDepositHistoryMobile
									type="Withdraw"
									list={transactions}
								/>
							</>
						) : (
							<Box h={'300px'} w={'100%'} position={'relative'}>
								<LoadingUI />
							</Box>
						)}
						<Flex justifyContent={'center'} my={'10px'} w={'100%'}>
							<Pagination
								page={paging.page}
								pagination={{
									total,
									pageSize: paging.pageSize,
								}}
								handleChangePage={(page) => {
									setPaging({ ...paging, page });
								}}
							/>
						</Flex>
					</DepositHistory>
				</StyledModalBody>
			</StyledModalContent>
		</Modal>
	);
};

export default WithdrawModal;
