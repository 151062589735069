import {
	Box,
	Drawer,
	DrawerContent,
	DrawerOverlay,
	Flex,
	ModalCloseButton,
	Show,
	useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { P2PTradingClient } from 'skychain-p2p-trading-js';
import {
	ExecuteOrderStatus,
	ExecuteOrderType,
} from 'skychain-p2p-trading-js/lib/dto/execute-order.dto';
import { AdOrderEntity } from 'skychain-p2p-trading-js/lib/entities/ad-order.entity';
import { ExecuteOrderEntity } from 'skychain-p2p-trading-js/lib/entities/execute-order.entity';
import { UserIdentityClient } from 'user-identity-js';
import { UserEntity } from 'user-identity-js/lib/entities';
import Chat from '../components/BuySellDetail/Chat';
import Information from '../components/BuySellDetail/Information';
import StatusBar from '../components/BuySellDetail/StatusBar';
import StepBuySells from '../components/BuySellDetail/Steps';
import Container from '../layouts/Container';
import { RootState } from '../redux/store';
import { OrderDetailUI, USER } from '../constants';
import { UserAuthEntity } from 'user-identity-js/lib/entities/user.entity';
import { ROUTES } from '../routes/config';

const BuySellDetailPage = () => {
	const { id } = useParams();
	const p2pTradingClient = useSelector(
		(state: RootState) => state.system.p2pTradingClient
	);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const userProfile = useSelector((state: RootState) => state.user.userProfile);
	const navigate = useNavigate();
	const [orderDetail, setOrderDetail] = useState<ExecuteOrderEntity>();
	const [adOrderDetail, setAdOrderDetail] = useState<AdOrderEntity>();
	const [loading, setLoading] = useState(false);
	const [counterpart, setCounterpart] = useState<UserEntity>();
	const [activeStep, setActiveStep] = useState(1);
	const {
		isOpen: isOpenChat,
		onOpen: onOpenChat,
		onClose: onCloseChat,
	} = useDisclosure();
	let getExecuteOrderDetailInterval: any;
	useEffect(() => {
		const user = localStorage.getItem(USER);
		const jsonUser: UserAuthEntity | null = user ? JSON.parse(user) : null;
		if (!jsonUser || !jsonUser?.idToken) {
			navigate(ROUTES.LOGIN);
		}
	}, []);

	useEffect(() => {
		if (id && p2pTradingClient) {
			getExecuteOrderDetail(p2pTradingClient, id);
		}
	}, [id, p2pTradingClient]);

	useEffect(() => {
		if (
			orderDetail &&
			orderDetail.status !== ExecuteOrderStatus.CONFIRM_SUCCESS &&
			orderDetail.status !== ExecuteOrderStatus.CANCEL
		) {
			getExecuteOrderDetailInterval = setInterval(() => {
				getExecuteOrderDetail(p2pTradingClient, id as string);
			}, 3000);
		}
		if (orderDetail?.adOrderId && p2pTradingClient) {
			getAdOrderDetail(p2pTradingClient, orderDetail.adOrderId);
		}
		return () => {
			clearInterval(getExecuteOrderDetailInterval);
		};
	}, [orderDetail, id, p2pTradingClient]);

	useEffect(() => {
		if (orderDetail && userIdentity && userProfile) {
			getCounterPartInfo(userIdentity, orderDetail, userProfile);
		}
	}, [orderDetail, userIdentity, userProfile]);

	const getCounterPartInfo = async (
		userIdentity: UserIdentityClient,
		orderDetail: ExecuteOrderEntity,
		userProfile: UserEntity
	) => {
		const couterparty =
			orderDetail.user === userProfile.id
				? orderDetail.targetUser || ''
				: orderDetail.user || '';
		if (!couterparty) return;
		try {
			const res = await userIdentity.getUser(couterparty);
			setCounterpart(res);
		} catch (error: any) {
			console.log('getUserInfo', error);
		}
	};

	const getAdOrderDetail = async (
		p2pTradingClient: P2PTradingClient,
		orderId: string
	) => {
		try {
			const res = await p2pTradingClient.getAdOrder(orderId);
			setAdOrderDetail(res);
		} catch (error: any) {
			console.log('getAdOrderDetail', error);
		} finally {
			setLoading(false);
		}
	};

	const getActiveStep = (status: ExecuteOrderStatus) => {
		switch (status) {
			case ExecuteOrderStatus.WAITING:
				return 1;
			case ExecuteOrderStatus.CONFIRM_PAYMENT:
				return 2;
			case ExecuteOrderStatus.CONFIRM_SUCCESS:
				return 3;
			default:
				return 1;
		}
	};
	const getExecuteOrderDetail = async (
		p2pTradingClient: P2PTradingClient,
		orderId: string
	) => {
		try {
			const res = await p2pTradingClient.getExecuteOrder(orderId);
			setActiveStep(getActiveStep(res.status));
			if (
				!orderDetail ||
				(orderDetail && res.status !== orderDetail.status) ||
				(orderDetail && res.updatedAt !== orderDetail.updatedAt)
			) {
				setOrderDetail(res);
			}
		} catch (error: any) {
			console.log('getExecuteOrderDetail', error);
		}
	};

	const refresh = () => {
		if (id && p2pTradingClient) {
			getExecuteOrderDetail(p2pTradingClient, id);
		}
	};
	const getUIType = (
		userId: string,
		createdAdUser: string,
		orderType: ExecuteOrderType
	) => {
		if (
			(userId !== createdAdUser && orderType === ExecuteOrderType.BUY) ||
			(userId === createdAdUser && orderType === ExecuteOrderType.SELL)
		) {
			return OrderDetailUI.BUY;
		}
		return OrderDetailUI.SELL;
	};
	const UIType = useMemo(() => {
		return getUIType(
			userProfile?.id || '',
			adOrderDetail?.user || '',
			orderDetail?.type as ExecuteOrderType
		);
	}, [userProfile?.id, adOrderDetail?.user, orderDetail?.type]);

	return (
		<>
			{loading ? (
				<div>Loading....</div>
			) : (
				orderDetail &&
				adOrderDetail && (
					<div>
						<StatusBar
							id={id as string}
							createdAt={orderDetail.createdAt}
							assetSymbol={adOrderDetail.assetSymbol}
							counterpart={
								counterpart?.email ||
								(counterpart?.firstName || '')
									.concat(' ')
									.concat(counterpart?.lastName || ' ')
							}
							uiType={UIType}
							timeLimit={adOrderDetail.timeLimit}
							status={orderDetail.status}
							amount={orderDetail.amount}
							onOpenChat={onOpenChat}
						/>
						<Container py={'30px'}>
							<Flex
								flexDir={{ base: 'column', md: 'row' }}
								justifyContent={'space-between'}
							>
								<Box>
									<StepBuySells stepNumberActive={activeStep} uiType={UIType} />
									<Information
										uiType={UIType}
										executeOrder={orderDetail}
										adOrderDetail={adOrderDetail}
										counterpart={
											counterpart?.email ||
											(counterpart?.firstName || '')
												.concat(' ')
												.concat(counterpart?.lastName || ' ')
										}
										refresh={refresh}
									/>
								</Box>
								<Box display={{ base: 'none', md: 'block' }}>
									<Chat
										counterpart={
											counterpart?.email ||
											(counterpart?.firstName || '')
												.concat(' ')
												.concat(counterpart?.lastName || ' ')
										}
										status={adOrderDetail.status}
										groupId={orderDetail.id}
									/>
								</Box>

								<Show below="md">
									<Drawer
										placement={'bottom'}
										onClose={onCloseChat}
										isOpen={isOpenChat}
									>
										<DrawerOverlay />
										<DrawerContent bg={'transparent'}>
											<ModalCloseButton />
											<Chat
												counterpart={
													counterpart?.email ||
													(counterpart?.firstName || '')
														.concat(' ')
														.concat(counterpart?.lastName || ' ')
												}
												status={adOrderDetail.status}
												groupId={orderDetail.id}
											/>
										</DrawerContent>
									</Drawer>
								</Show>
							</Flex>
						</Container>
					</div>
				)
			)}
		</>
	);
};

export default BuySellDetailPage;
