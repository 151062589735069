export default function ViewIcon(props: any) {
	return (
		<svg
			{...props}
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Frame">
				<g id="Group 2328">
					<path
						id="Vector"
						d="M12 14.7736C13.8141 14.7736 15.2847 13.3066 15.2847 11.4969C15.2847 9.68723 13.8141 8.22021 12 8.22021C10.1859 8.22021 8.71533 9.68723 8.71533 11.4969C8.71533 13.3066 10.1859 14.7736 12 14.7736Z"
						fill="black"
					/>
					<g id="Group">
						<path
							id="Vector_2"
							d="M12 14.7736C13.8141 14.7736 15.2847 13.3066 15.2847 11.4969C15.2847 9.68723 13.8141 8.22021 12 8.22021C10.1859 8.22021 8.71533 9.68723 8.71533 11.4969C8.71533 13.3066 10.1859 14.7736 12 14.7736Z"
							fill="#BAB9D0"
						/>
						<path
							id="Vector_3"
							d="M11.9972 3.34009C10.4701 3.34009 9.00901 3.61191 7.65117 4.1015C5.12157 5.16687 4.07404 6.07057 4.07404 6.07057C2.27398 7.47457 0.850698 9.34011 0 11.4999C1.88697 16.276 6.54383 19.6597 11.9972 19.6597C13.6878 19.6597 15.3018 19.3333 16.7798 18.7402C16.7798 18.7402 19.0609 17.7131 20.2537 16.6676C21.9004 15.2968 23.1984 13.5236 24 11.4999C22.1074 6.72387 17.4557 3.34009 11.9972 3.34009ZM15.877 15.3646C14.8412 16.3978 13.4644 16.9665 11.9997 16.9665C10.5351 16.9665 9.15829 16.3973 8.12253 15.3646C7.08676 14.3314 6.51673 12.958 6.51673 11.4969C6.51673 10.0357 7.08727 8.66233 8.12253 7.6291C8.65472 7.0982 9.2769 6.69072 9.95429 6.41889L9.96093 6.42552C9.97218 6.42093 9.98343 6.41685 9.99467 6.41226L9.99672 6.4143C10.6307 6.16441 11.3126 6.03283 12.0151 6.03283C12.5176 6.03283 13.0105 6.10015 13.4823 6.22969C14.3795 6.47907 15.2016 6.9554 15.878 7.63012C15.8816 7.63369 15.8851 7.63777 15.8887 7.64134C15.9199 7.67244 15.9506 7.70406 15.9807 7.73568C15.9961 7.752 16.0114 7.76832 16.0268 7.78515C16.0446 7.80453 16.0625 7.8234 16.0799 7.84278C16.0978 7.86267 16.1157 7.88256 16.1331 7.90296C16.1469 7.91877 16.1607 7.93458 16.1745 7.9509C16.1939 7.97334 16.2134 7.99629 16.2323 8.01924C16.2425 8.03148 16.2527 8.04423 16.2629 8.05647C16.2844 8.08299 16.3059 8.109 16.3268 8.13603C16.3289 8.13858 16.3309 8.14163 16.3335 8.14469C17.0789 9.09939 17.4833 10.2673 17.4833 11.4969C17.4828 12.958 16.9127 14.3314 15.877 15.3646Z"
							fill="#BAB9D0"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}
