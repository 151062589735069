import {
	Flex,
	Table,
	TableContainer,
	TableProps,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	useToast,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { HTMLAttributes } from 'react';
import { format } from 'date-fns';
import { shortenAddress } from '../../utils/address';
import NotFound from '../NotFound/NotFound';
import { formatTransactionState } from '../../lib';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../../utils';
import { SCAN_URL_CONFIG } from '../../config';
import { TRANSLATION } from '../../constants';
import { useTranslation } from 'react-i18next';
import CopyIcon from '../Icons/CopyIcon';
const StyledTh = styled(Th)`
	font-size: 14px;
	line-height: 20px;
	color: #737283;
`;
const StyledTd = styled(Td)`
	font-size: 16px;
`;
const Status = styled.div<{ status: TransactionState }>(
	(props) => `
	text-transform: capitalize;
     color: ${
				props.status === 'COMPLETED'
					? '#21FF0E'
					: props.status === 'FAILED' ||
					  props.status === 'REJECTED' ||
					  props.status === 'INVALID'
					? '#FF0000'
					: props.status === 'PROCESSING'
					? '#FFA500'
					: '#737283'
			}
`
);
interface TransactionHistoryDesktopProps
	extends HTMLAttributes<HTMLDivElement> {
	list: TransactionHistory[];
	tableProps?: TableProps;
	type?: 'Deposit' | 'Withdraw';
}
const TransactionHistoryDesktop: React.FC<TransactionHistoryDesktopProps> = ({
	list,
	tableProps,
	type = 'Deposit',
	...props
}: TransactionHistoryDesktopProps) => {
	const toast = useToast();
	const { t } = useTranslation(TRANSLATION, {
		keyPrefix: 'Wallet',
	});
	const handleCopy = (text: string | undefined) => {
		if (!text) return;
		navigator.clipboard.writeText(text);
		toast({
			title: 'Copied!',
			status: 'info',
			duration: 2000,
			isClosable: true,
			position: 'top',
		});
	};
	return (
		<TableContainer {...props} style={{ margin: 'auto', width: '100%' }}>
			{list.length === 0 ? (
				<div style={{ width: '100%' }}>
					<NotFound />
				</div>
			) : (
				<Table w={'100%'} overflowX={'auto'} {...tableProps}>
					<Thead>
						<Tr>
							<StyledTh>{t('Token')}</StyledTh>
							<StyledTh>{t('Network')}</StyledTh>
							<StyledTh>{t('Time')}</StyledTh>
							<StyledTh>{t('Amount')}</StyledTh>
							<StyledTh>{t(`${type} Address`)}</StyledTh>
							<StyledTh w={'12%'}>{t('Blockchain Transaction Hash')}</StyledTh>
							<StyledTh isNumeric>{t('Status')}</StyledTh>
						</Tr>
					</Thead>
					<Tbody>
						{list.map((item: TransactionHistory, index: any) => (
							<Tr key={index}>
								<StyledTd>{item.currency}</StyledTd>
								<StyledTd>{item.chain}</StyledTd>
								<StyledTd>{item.time}</StyledTd>
								<StyledTd>{formatCurrency(item.amount)}</StyledTd>
								<StyledTd>
									<Flex gap={'10px'}>
										{shortenAddress(item.address)}{' '}
										<CopyIcon
											style={{ cursor: 'pointer' }}
											onClick={() => handleCopy(item.address)}
										/>
									</Flex>
								</StyledTd>
								<StyledTd w={'12%'}>
									{item.onchainHash ? (
										<Link
											style={{
												color: 'blue',
											}}
											//@ts-ignore
											to={`${SCAN_URL_CONFIG[item.chain || '']}${
												item.onchainHash || ''
											}`}
											target="_blank"
										>
											{shortenAddress(item.onchainHash || '')}
										</Link>
									) : (
										''
									)}
								</StyledTd>
								<StyledTd isNumeric>
									<Status status={item.state as TransactionState}>
										{formatTransactionState(item.state)}
									</Status>
								</StyledTd>
							</Tr>
						))}
					</Tbody>
				</Table>
			)}
		</TableContainer>
	);
};

export default TransactionHistoryDesktop;
