import { Box } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import DepositModal from '../../components/Wallets/DepositModal/DepositModal';
import TransferModal from '../../components/Wallets/TransferModal/TransferModal';
import WithdrawModal from '../../components/Wallets/WithdrawModal/WithdrawModal';
import TradeHistoryModal from '../../components/Wallets/TradeHistoryModal';
import { ROUTES } from '../../routes/config';
import Footer from './Footer';
import TopNav from './TopNav';
import { LayoutWrapper } from './styles';
import { useSelector } from 'react-redux';

export default function MainLayout() {
	const topNavRef = useRef<any>();
	const footerRef = useRef<any>();
	const [footerHeight, setFooterHeight] = useState(0);
	const [ptBody, setPtBody] = useState(0);
	const location = useLocation();
	const navigate = useNavigate();
	const account = useSelector((state: any) => state.auth.account);

	useEffect(() => {
		if (!account && location.pathname !== ROUTES.RESET_PASSWORD) {
			navigate(ROUTES.LOGIN);
		}
	}, [account]);

	useEffect(() => {
		if (footerRef.current) {
			setFooterHeight(footerRef.current?.clientHeight || 0);
		}
		if (topNavRef.current) {
			setPtBody(topNavRef.current?.clientHeight || 0);
		}
	}, []);

	return (
		<>
			<LayoutWrapper>
				<Box
					display={{
						base:
							[
								ROUTES.HOME,
								ROUTES.LOGIN,
								ROUTES.REGISTER,
								ROUTES.P2P_BUY_SELL,
								ROUTES.P2P_ADS,
								ROUTES.P2P_ORDERS,
								ROUTES.ABOUT_US,
								ROUTES.PRIVACY_POLICY,
								ROUTES.RISH_DISCLAIMER,
								ROUTES.USER_AGREEMENT,
							].includes(location.pathname) ||
							location.pathname.includes(ROUTES.P2P_ORDERS)
								? 'block'
								: 'none',
						lg: 'block',
					}}
				>
					<TopNav ref={topNavRef} />
				</Box>

				<Box
					minH={footerHeight ? `calc(100vh - ${footerHeight}px)` : 'auto'}
					pt={{ lg: `${ptBody + 32}px` }}
					bg={'#050713'}
				>
					<Outlet />
				</Box>

				<div ref={footerRef}>
					<Footer />
				</div>
			</LayoutWrapper>
			<DepositModal />
			<WithdrawModal />
			<TransferModal />
			<TradeHistoryModal />
		</>
	);
}
