import { chakra } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const NotFoundWrapper = styled.div`
	margin: auto;
	width: fit-content;
`;
export const NotFoundTitle = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
	color: #ffffff;
	margin-top: 20px;
`;

export const StyledImage = chakra('img', {
	baseStyle: {
		width: {
			base: '150px',
			lg: '200px',
		},
		height: {
			base: '150px',
			lg: '100%',
		},
		objectFit: 'contain',
	},
});
