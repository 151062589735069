import {
	Box,
	Button,
	Flex,
	Spinner,
	Text,
	chakra,
	useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import AmountInput from '../UI/AmountInput';
import BuySellButton from '../UI/BuySellButton';
import TextGradient from '../UI/TextGradient';
import { DataBuySellTable } from './interface';
import { AdOrderType } from 'skychain-p2p-trading-js/lib/dto/ad-order.dto';
import { useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { ExecuteOrderType } from 'skychain-p2p-trading-js/lib/dto/execute-order.dto';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/config';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectCustom from '../UI/SelectCustom';
import { formatCurrency } from '../../utils';

const Label = chakra(Text, {
	baseStyle: {
		color: '#737283',
		fontSize: 14,
	},
});

const Content = chakra(Text, {
	baseStyle: {
		color: '#ffffff',
		fontSize: 14,
	},
});

const FormItem = chakra(Flex, {
	baseStyle: {
		pb: '20px',
		flexDirection: 'column',
		gap: '10px',
	},
});

const ErrorMessage = chakra(Text, {
	baseStyle: {
		fontSize: '12px',
		color: '#CF304A',
	},
});

type FormData = {
	pay: string;
	receive: string;
};

const MIN_AMOUNT = 10;
const MAX_AMOUNT = 200;

export default function TradeItemExpand({
	data,
	onToggleCollapse,
}: {
	data: DataBuySellTable;
	onToggleCollapse: () => void;
}) {
	const toast = useToast();
	const myPaymentMethods = useSelector(
		(state: RootState) => state.user.paymentMethods
	);

	const navigate = useNavigate();
	const p2pTradingClient = useSelector(
		(state: RootState) => state.system.p2pTradingClient
	);
	const [loading, setLoading] = useState(false);

	const validationSchema = useMemo(() => {
		return yup.object({
			pay: yup
				.number()
				.typeError('Amount must be a number')
				.min(
					new BigNumber(data.minAmount || 0)
						.multipliedBy(data.price)
						.toNumber() || MIN_AMOUNT,
					`Amount is greater or equal than ${formatCurrency(
						new BigNumber(data.minAmount || 0)
							.multipliedBy(data.price)
							.toNumber() || MIN_AMOUNT
					)}`
				)
				.max(
					new BigNumber(data.maxAmount || 0)
						.multipliedBy(data.price)
						.toNumber() || MAX_AMOUNT,
					`Amount is less or equal than ${formatCurrency(
						new BigNumber(data.maxAmount || 0)
							.multipliedBy(data.price)
							.toNumber() || MAX_AMOUNT
					)}`
				)
				.required('Amount is required'),
		});
	}, [JSON.stringify(data)]);

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm<FormData>({
		resolver: yupResolver(validationSchema),
	});

	const paymentMethodList = useMemo(() => {
		if (!myPaymentMethods) return [];
		return myPaymentMethods.map((item) => ({
			value: item.bankName,
			key: item.id,
		}));
	}, [myPaymentMethods]);
	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<any>(
		paymentMethodList.length > 0 ? paymentMethodList[0] : undefined
	);

	useEffect(() => {
		if (watch('pay') || watch('pay') === '') {
			setValue(
				'receive',
				new BigNumber(watch('pay') || 0).dividedBy(data['price']).toString()
			);
		}
	}, [watch('pay')]);

	const onSubmit = handleSubmit((values) => {
		executeOrder(
			data.key as string,
			data.type === AdOrderType.BUY
				? ExecuteOrderType.SELL
				: ExecuteOrderType.BUY,
			new BigNumber(values['receive']).toNumber(),
			data.type === AdOrderType.SELL
				? data.paymentMethod
				: defaultPaymentMethod.key
		);
	});

	const executeOrder = async (
		adOrderId: string,
		type: ExecuteOrderType,
		amount: number,
		paymentMethod: string
	) => {
		if (!p2pTradingClient || loading) return;
		const body = {
			adOrderId,
			type,
			amount,
			paymentMethod,
		};
		try {
			setLoading(true);
			const res = await p2pTradingClient.executeOrder(body);
			if (res.id) {
				navigate(ROUTES.BUY_SELL_DETAIL.replace(':id', res.id));
			}
		} catch (error: any) {
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Something went wrong',
			});
			console.log('executeOrder', error);
		} finally {
			setLoading(false);
		}
	};
	return (
		<Flex w={'100%'} p={'16px 0'} direction={{ base: 'column', lg: 'row' }}>
			<Box w={{ base: '100%', lg: '60%' }} pr={{ base: '0px', lg: '60px' }}>
				<Flex
					gap="10px"
					alignItems={'center'}
					justifyContent={{ base: 'space-between', lg: 'flex-start' }}
				>
					<Flex alignItems={'center'} gap={'8px'}>
						<img src={data['avatar']} alt="avatar" />
						<Text>{data['name']}</Text>
						<img src={'/images/tick.svg'} alt="tick" />
					</Flex>

					{/* <Rate>{`${data['order']} orders | ${data['completion']} completion`}</Rate> */}
				</Flex>

				<Flex
					w="100%"
					direction={{ base: 'column', lg: 'row' }}
					justifyContent={'space-between'}
					py={{ base: '0px', lg: '20px' }}
				>
					<Box w={{ base: '100%', lg: '40%' }}>
						<Flex justifyContent={'space-between'}>
							<Label>Price:</Label>
							<Content fontSize={{ base: '14px', lg: '16px' }}>
								{data['price']} {data['fiatSymbol']}
							</Content>
						</Flex>

						<Flex justifyContent={'space-between'}>
							<Label>Payment time limit:</Label>
							<Content>{data['paymentTimeLimit']}</Content>
						</Flex>
					</Box>

					<Box w={{ base: '100%', lg: '50%' }}>
						<Flex justifyContent={'space-between'}>
							<Label>Available:</Label>
							<Content>
								{formatCurrency(data['available'])} {data['assetSymbol']}
							</Content>
						</Flex>

						<Flex justifyContent={'space-between'}>
							<Label>{`${
								data.type === AdOrderType.BUY ? 'Buyer' : 'Seller'
							}’s payment method:`}</Label>
							<Content>{data['paymentMethod']}</Content>
						</Flex>
					</Box>
				</Flex>

				<Box>
					<Label>Terms & Conditions:</Label>
					{data['termsAndConditions']?.map((termAndCondition: string) => (
						<Content key={termAndCondition}>{termAndCondition}</Content>
					))}
				</Box>
			</Box>

			<Box
				w={{ base: '100%', lg: '40%' }}
				pl={{ base: '0px', lg: '60px' }}
				borderLeft={{ base: 'none', lg: '1px solid #FFFFFF1E' }}
			>
				<form onSubmit={onSubmit}>
					<FormItem>
						<Label>
							I want to {data.type === AdOrderType.BUY ? 'sell' : 'pay'}{' '}
						</Label>
						<AmountInput
							placeHolder={`${new BigNumber(data?.minAmount || MIN_AMOUNT)
								.multipliedBy(data.price)
								.toFormat(2)} - ${new BigNumber(data?.maxAmount || MAX_AMOUNT)
								.multipliedBy(data.price)
								.toFormat(2)}`}
							// type="number"
							step={'any'}
							register={register('pay')}
							name={'pay'}
							setValue={setValue}
							value={watch('pay')}
							expandW={'7.5rem'}
							expand={
								<Flex
									alignItems={'center'}
									gap={'10px'}
									justifyContent={'flex-end'}
									w={'100%'}
									px={{ base: '16px' }}
								>
									<Box
										cursor={'pointer'}
										onClick={() => {
											setValue(
												'pay',
												new BigNumber(data['available'])
													.multipliedBy(data['price'])
													.toString()
											);
										}}
									>
										<TextGradient>All</TextGradient>
									</Box>
									<Label>{data.fiatSymbol}</Label>
								</Flex>
							}
						/>
						{errors?.pay && errors?.pay?.message && (
							<ErrorMessage>{errors?.pay?.message}</ErrorMessage>
						)}
					</FormItem>

					<FormItem>
						<Label>I will receive</Label>
						<AmountInput
							placeHolder={''}
							isReadOnly
							register={register('receive')}
							expandW={'5rem'}
							expand={
								<Flex
									alignItems={'center'}
									gap={'10px'}
									justifyContent={'flex-end'}
									w={'100%'}
									px={{ base: '16px' }}
								>
									<Label>{data['assetSymbol']}</Label>
								</Flex>
							}
						/>
					</FormItem>
					{data.type === AdOrderType.BUY && (
						<FormItem w={'100%'}>
							<Label>Payment Method</Label>
							{myPaymentMethods?.length > 0 ? (
								<SelectCustom
									list={myPaymentMethods.map((item) => ({
										value: item.bankName,
										key: item.id,
									}))}
									value={defaultPaymentMethod}
									onSelect={(item) => setDefaultPaymentMethod(item)}
									w="inherit"
								/>
							) : (
								<Link
									style={{
										color: '#FFBF1A',
									}}
									to={ROUTES.ACCOUNT.ADD_PAYMENT_METHOD}
								>
									+ Add payment method
								</Link>
							)}
						</FormItem>
					)}

					<Flex justifyContent={'space-between'}>
						<Button
							fontSize={'16px'}
							w={{ base: '40%', lg: '30%' }}
							h={{ base: '38px' }}
							lineHeight={'24px'}
							bg={'#484756'}
							fontWeight={400}
							color={'white'}
							onClick={onToggleCollapse}
							// type="submit"
						>
							Cancel
						</Button>
						<BuySellButton
							type="submit"
							isBuy={data.type === AdOrderType.SELL}
							w={{ base: 'calc(60% - 10px)', lg: 'calc(70% - 20px)' }}
						>
							<>
								{loading ? (
									<Spinner />
								) : (
									<>
										{data.type === AdOrderType.BUY ? 'Sell' : 'Buy'}{' '}
										{data.assetSymbol}
									</>
								)}
							</>
						</BuySellButton>
					</Flex>
				</form>
			</Box>
		</Flex>
	);
}
