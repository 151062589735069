import { Box, Flex, Image } from '@chakra-ui/react';
import React from 'react';
import Container from '../../layouts/Container';
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
} from '@chakra-ui/react';
import PlusIcon from '../Icons/PlusIcon';
import MinusIcon from '../Icons/MinusIcon';
import { useTranslation } from 'react-i18next';

const questionList = [
	{
		key: 1,
		title: 'Industry leading security',
		description:
			'In the cryptocurrency industry, security is an extremely important factor, especially for cryptocurrency exchanges. The primary goal of leading exchanges is to ensure the safety and security of user data and assets. To achieve this, top exchanges implement state-of-the-art security measures and utilize advanced technologies. Key technologies include data encryption, two-factor authentication (2FA), cold wallet security, and continuous monitoring.',
	},
	{
		key: 2,
		title: 'Crypto Security',
		description:
			'Crypto security refers to the processes and measures implemented to protect cryptocurrency assets and personal information within the cryptocurrency ecosystem. Security in this field encompasses data encryption, user authentication, private key management, network monitoring, and defense against cyber attacks. Crypto security measures are designed to ensure the integrity, confidentiality, and privacy of cryptocurrency transactions, assets, and user information, providing a reliable foundation for participation in the cryptocurrency ecosystem.',
	},
	{
		key: 3,
		title: 'Account Security',
		description:
			'Account security involves the processes and measures implemented to protect the information and privacy rights of users within their accounts. This includes using strong passwords, implementing two-factor authentication, encrypting data, keeping security software up to date, and maintaining continuous monitoring to prevent unauthorized access and suspicious activities. Account security is a crucial aspect to ensure the safety and protection of user assets and personal information in the digital environment.',
	},
];

const WhyChooseMetaniex = () => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'Home.WhyChooseMetaniex',
	});
	return (
		<Box mt={'156px'}>
			<Container>
				<Box textAlign={'center'} data-aos="fade-up" data-aos-duration={'1500'}>
					<Box fontSize={'30px'} mb={'8px'}>
						{t('Why choose Krypto8?')}
					</Box>
					<Box maxW={'791px'} mx="auto" color="#737283">
						{t('descriptionSection')}
					</Box>
				</Box>

				<Flex
					mt={'30px'}
					alignItems={'center'}
					justifyContent={'space-between'}
					flexDir={{ base: 'column', md: 'row' }}
				>
					<Box
						w={{ base: '100%', md: '40%' }}
						data-aos="fade-up"
						data-aos-duration={'1500'}
					>
						<Image src="/images/homepage/whyChooseKrypto8.png" />
					</Box>
					<Box
						w={{ base: '100%', md: '60%' }}
						data-aos="fade-up"
						data-aos-duration={'1500'}
					>
						<Accordion allowToggle w={'100%'}>
							{questionList.map((question) => (
								<AccordionItem
									py={'14px'}
									key={question.key}
									// mb={'10px'}
									border={0}
								>
									{({ isExpanded }) => (
										<>
											<h2>
												<AccordionButton
													borderWidth={'1px'}
													borderRadius={'6px'}
													p={'15px 30px'}
												>
													<Box as="span" flex="1" textAlign="left">
														{t(`title${question.key}`)}
													</Box>
													{isExpanded ? <MinusIcon /> : <PlusIcon />}
												</AccordionButton>
											</h2>
											<AccordionPanel color={'#737283'} p={'10px 30px'}>
												{t(`description${question.key}`)}
											</AccordionPanel>
										</>
									)}
								</AccordionItem>
							))}
						</Accordion>
						{/* <Box maxW={'719px'} mx={'auto'} color="#737283" mt={'20px'}>
							Krypto8 is one the safest cryptocurrency exchanges where you can
							buy, sell, store, and accrue staking rewards on cypto and other
							cryptocurrencies
						</Box> */}
					</Box>
				</Flex>
			</Container>
		</Box>
	);
};

export default WhyChooseMetaniex;
