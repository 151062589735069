import { useRef, useState, useEffect } from 'react';
import WalletMenu from './WalletMenu';
import { Box, Flex, Show } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/config';
import NavigateMobile from '../../components/UI/NavigateMobile';
import { TRANSLATION, USER } from '../../constants';
import { UserAuthEntity } from 'user-identity-js/lib/entities/user.entity';
import { useTranslation } from 'react-i18next';

export default function WalletLayout() {
	const menuRef = useRef<any>();
	const [bodywidth, setBodyWidth] = useState(0);
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation(TRANSLATION, {
		keyPrefix: 'Wallet',
	});

	const navigateTitles = [
		{ route: ROUTES.WALLET.MAIN, title: t('Wallet') },
		{ route: ROUTES.WALLET.ASSET_OVERVIEW, title: t('Asset Overview') },
		{ route: ROUTES.WALLET.SPOT, title: t('Spot Account') },
		{ route: ROUTES.WALLET.P2P, title: t('P2P Account') },
		{ route: ROUTES.WALLET.FUTURES_ACCOUNT, title: t('Futures Account') },
		{ route: ROUTES.WALLET.MARGIN_ACCOUNT, title: t('Margin Account') },
		{ route: ROUTES.WALLET.ASSET_AUTDIT, title: t('Asset Audit') },
	];

	useEffect(() => {
		const user = localStorage.getItem(USER);
		const jsonUser: UserAuthEntity | null = user ? JSON.parse(user) : null;
		if (!jsonUser || !jsonUser?.idToken) {
			navigate(ROUTES.LOGIN);
		}
	}, []);

	useEffect(() => {
		if (menuRef.current) {
			setBodyWidth(menuRef.current?.clientWidth);
		}
	}, [menuRef.current]);

	const handleBack = () => {
		if (location.pathname === ROUTES.WALLET.MAIN) {
			navigate(ROUTES.HOME);
		} else navigate(ROUTES.WALLET.MAIN);
	};

	return (
		<Flex h={'100%'}>
			<Show above="sm">
				<Box ref={menuRef}>
					<WalletMenu />
				</Box>

				<Box w={{ base: `calc(100% - ${bodywidth}px)` }} p={{ base: '20px' }}>
					<Outlet />
				</Box>
			</Show>

			<Show below="md">
				<Box w="100%">
					<NavigateMobile
						title={
							navigateTitles.find((title) => title.route === location.pathname)
								?.title
								? t(
										`${
											navigateTitles.find(
												(title) => title.route === location.pathname
											)?.title
										}`
								  )
								: ''
						}
						handleBack={handleBack}
					/>
					{location.pathname === ROUTES.WALLET.MAIN ? (
						<WalletMenu />
					) : (
						<Outlet />
					)}
				</Box>
			</Show>
		</Flex>
	);
}
