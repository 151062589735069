import {
	Box,
	Button,
	Flex,
	Hide,
	Modal,
	ModalCloseButton,
	ModalOverlay,
	Text,
	chakra,
	useToast,
} from '@chakra-ui/react';
import {
	StyledModalBody,
	StyledModalContent,
	StyledModalHeader,
} from '../../Wallets/DepositModal/styles';
import ArrowStepIcon from '../../Icons/ArrowStepIcon';
import React, { useEffect, useState } from 'react';
import SetTypeAndPriceStep from './SetTypeAndPriceStep';
import SetAmountAndPaymentView from './SetAmountAndPaymentView';
import styled from '@emotion/styled';
import SetRemarksAndAutoResponseView from './SetRemarksAndAutoResponseView';
import ArrowStepLongIcon from '../../Icons/ArrowStepLongIcon';
import { AdOrder } from 'skychain-p2p-trading-js/lib/dto/ad-order.dto';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import BigNumber from 'bignumber.js';
import { formatCurrency } from '../../../utils';

export const Label = styled(Text)`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #737283;
	/* margin-bottom: 10px; */
`;

export const NextButton = chakra(Button, {
	baseStyle: {
		w: '100%',
		padding: '12px 20px',
		background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
		borderRadius: '4px',
		color: '#181A20',
		fontSize: {
			base: '16px',
			lg: '18px',
		},
	},
});

export const PreviousButton = chakra(Button, {
	baseStyle: {
		w: '100%',
		padding: '12px 20px',
		background: '#484756',
		borderRadius: '4px',
		color: '#ffffff',
		fontSize: {
			base: '16px',
			lg: '18px',
		},
	},
});

export default function PostAdModal({
	isOpen,
	onClose,
	refresh,
}: {
	isOpen: boolean;
	onClose: () => void;
	refresh?: () => void;
}) {
	const p2pTradingClient = useSelector(
		(state: RootState) => state.system.p2pTradingClient
	);
	const toast = useToast();
	const [postAdData, setPostAdData] = useState<any>();
	const [loading, setLoading] = useState(false);

	const handleNextStep = (data: any) => {
		setPostAdData({ ...postAdData, ...data });
		if (activeStep === steps.length) {
			createNewAd({
				...postAdData,
				...data,
				maxAmount: new BigNumber(postAdData.maxAmount)
					.dividedBy(postAdData.priceValue)
					.toNumber(),
				minAmount: new BigNumber(postAdData.minAmount)
					.dividedBy(postAdData.priceValue)
					.toNumber(),
				priceValue: new BigNumber(postAdData.priceValue).toNumber(),
				totalAmount: new BigNumber(postAdData.totalAmount).toNumber(),
			});
		} else {
			setActiveStep(activeStep + 1);
		}
	};
	const createNewAd = async (data: AdOrder) => {
		if (!p2pTradingClient || loading) return;
		try {
			setLoading(true);
			const res = await p2pTradingClient.createAdOrder(data);
			if (res?.id) {
				toast({
					title: 'Success',
					description: 'Create Ad successfully',
					status: 'success',
				});
				onClose();
				refresh && refresh();
			}
		} catch (error: any) {
			console.log('createNewAd', error);
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Create Ad failed',
			});
		} finally {
			setLoading(false);
		}
	};
	const steps = [
		{
			number: 1,
			content: 'Set Type & Price',
			component: (
				<SetTypeAndPriceStep
					open={isOpen}
					onNextStep={handleNextStep}
					data={postAdData}
				/>
			),
		},
		{
			number: 2,
			content: 'Set Total Amount\n & Payment Method',
			component: (
				<SetAmountAndPaymentView
					open={isOpen}
					onPrevStep={() => setActiveStep(activeStep - 1)}
					onNextStep={handleNextStep}
					data={postAdData}
				/>
			),
		},
		{
			number: 3,
			content: 'Set Remarks &\n Automatic Response',
			component: (
				<SetRemarksAndAutoResponseView
					open={isOpen}
					onNextStep={handleNextStep}
					onPrevStep={() => setActiveStep(activeStep - 1)}
				/>
			),
		},
	];
	const [activeStep, setActiveStep] = useState(steps[0].number);

	useEffect(() => {
		if (isOpen) {
			setActiveStep(1);
		}
	}, [isOpen]);

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<StyledModalContent>
					<StyledModalHeader>Post an Ad</StyledModalHeader>
					<ModalCloseButton />
					<StyledModalBody maxW={'548px'} w={'90vw'}>
						<Hide below="lg">
							<Flex alignItems={'center'} gap={'10px'}>
								{steps.map((step, index) => (
									<React.Fragment key={step.number}>
										<StepItem
											number={step.number}
											content={step.content}
											isActive={step.number === activeStep}
										/>

										{index < steps.length - 1 && <ArrowStepIcon />}
									</React.Fragment>
								))}
							</Flex>
						</Hide>
						<Hide above="md">
							<Flex alignItems={'center'} gap="5px">
								{steps
									.filter((step) => step.number === activeStep)
									.map((step) => (
										<React.Fragment key={step.number}>
											<StepItem
												number={step.number}
												content={step.content}
												isActive={step.number === activeStep}
											/>

											{step.number !== steps[steps.length - 1].number && (
												<ArrowStepLongIcon />
											)}
										</React.Fragment>
									))}
							</Flex>
						</Hide>
						<Box>
							{steps.find((step) => step.number === activeStep)?.component}
						</Box>
					</StyledModalBody>
				</StyledModalContent>
			</Modal>
		</>
	);
}

const StepItem = ({
	number,
	content,
	isActive,
}: {
	number: number;
	content: string;
	isActive: boolean;
}) => {
	return (
		<Flex gap={'8px'} alignItems={'center'}>
			<Flex
				w={{ base: '23px' }}
				h={{ base: '23px' }}
				border={'1px solid'}
				borderColor={isActive ? '#FFFFFF' : '#737283'}
				borderRadius={'100px'}
				alignItems={'center'}
				justifyContent={'center'}
			>
				<Text
					fontSize={{ base: '12px' }}
					color={isActive ? '#FFFFFF' : '#737283'}
				>
					{number}
				</Text>
			</Flex>
			<Text
				fontSize={{ base: '12px' }}
				color={isActive ? '#FFFFFF' : '#737283'}
				whiteSpace={'pre'}
			>
				{content}
			</Text>
		</Flex>
	);
};
