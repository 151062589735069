export const config = {
	base_url: process.env.REACT_APP_BASE_URL || '',
	coingecko_url: process.env.REACT_APP_COINGECKO_URL || '',
	binance_url: process.env.REACT_APP_BINANCE_URL || '',
};

export const SCAN_URL_CONFIG: {
	[key: string]: string;
} = {
	BSC: (process.env.REACT_APP_BSC_SCAN || '') + 'tx/',
	ETH: (process.env.REACT_APP_ETH_SCAN || '') + 'tx/',
	TRON: (process.env.REACT_APP_TRON_SCAN || '') + '#/transaction/',
};
