import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function useGoogleRecaptcha(action: (value: any) => void) {
	const { executeRecaptcha } = useGoogleReCaptcha();

	const handleReCaptchaVerify = useCallback(
		async (value: any) => {
			if (!executeRecaptcha) {
				console.log('Execute recaptcha not yet available');
				return;
			}

			const token = await executeRecaptcha();

			action({ ...value, captchaToken: token });
		},
		[executeRecaptcha]
	);
	return {
		handleReCaptchaVerify,
	};
}
