import {
	Box,
	Modal,
	ModalCloseButton,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { PaymentMethodEntity } from 'skychain-p2p-trading-js/lib/entities';
import PrimaryButton from '../../UI/PrimaryButton';
import {
	StyledModalBody,
	StyledModalContent,
	StyledModalHeader,
} from '../../Wallets/DepositModal/styles';

const AddPaymentModal = ({
	isOpen,
	onClose,
	listPayment,
	selectedPayments,
	onAddPayments,
}: {
	isOpen: boolean;
	onClose: () => void;
	listPayment: PaymentMethodEntity[];
	selectedPayments: PaymentMethodEntity[];
	onAddPayments: (payments: PaymentMethodEntity[]) => void;
}) => {
	const [selected, setSelected] = useState<PaymentMethodEntity[]>([]);

	const handleSelectedPayment = (payment: PaymentMethodEntity) => {
		if (selected.includes(payment)) {
			setSelected(selected.filter((item) => item.id !== payment.id));
		} else {
			setSelected([payment]);
		}
	};

	const onSubmit = () => {
		onAddPayments(selected);
		setSelected([]);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<StyledModalContent>
				<StyledModalHeader>Add Payment Method</StyledModalHeader>
				<ModalCloseButton />
				<StyledModalBody w={{ base: 'auto', md: '731px' }}>
					{listPayment
						.filter((payment) => !selectedPayments.includes(payment))
						.map((payment) => {
							return (
								<Box
									onClick={() => handleSelectedPayment(payment)}
									key={payment.id}
									p={'20px'}
									cursor={'pointer'}
									border={
										selected.includes(payment)
											? '1px solid #FFBF1A'
											: '1px solid rgba(255, 255, 255, 0.08)'
									}
								>
									<Text color="#737283">{payment.bankName}</Text>
									<Text>{payment.fullName}</Text>
									<Text color="#737283">{payment.bankAccountNumber}</Text>
								</Box>
							);
						})}
					<PrimaryButton onClick={onSubmit} borderRadius={'4px'}>
						Save
					</PrimaryButton>
				</StyledModalBody>
			</StyledModalContent>
		</Modal>
	);
};

export default AddPaymentModal;
