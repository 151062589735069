import React from 'react';

export default function CloseIcon(props: any) {
	return (
		<svg
			{...props}
			width={17}
			height={16}
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.5 4L4.5 12"
				stroke="#555361"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.5 4L12.5 12"
				stroke="#555361"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
