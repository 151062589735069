export default function EditIcon(props: any) {
	return (
		<svg
			{...props}
			width={21}
			height={21}
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.5 1.67188C12.5369 1.6727 14.5109 2.37768 16.0875 3.66738C17.6642 4.95707 18.7464 6.75217 19.151 8.74851C19.5555 10.7449 19.2575 12.8197 18.3074 14.6214C17.3572 16.4232 15.8134 17.841 13.9375 18.6348C11.78 19.5464 9.34875 19.5637 7.17853 18.6828C5.00831 17.8019 3.27692 16.095 2.36523 13.9375C1.45355 11.78 1.43626 9.34875 2.31717 7.17853C3.19808 5.00831 4.90503 3.27692 7.0625 2.36523C8.14997 1.90504 9.31917 1.66921 10.5 1.67188ZM10.5 0.5C4.97734 0.5 0.5 4.97734 0.5 10.5C0.5 16.0227 4.97734 20.5 10.5 20.5C16.0227 20.5 20.5 16.0227 20.5 10.5C20.5 4.97734 16.0227 0.5 10.5 0.5Z"
				fill="#737283"
			/>
			<path
				d="M14.1825 9.59473L15.0158 8.7614C15.0559 8.72132 15.0878 8.67372 15.1095 8.62132C15.1312 8.56893 15.1424 8.51276 15.1424 8.45604C15.1424 8.39932 15.1312 8.34316 15.1095 8.29076C15.0878 8.23836 15.0559 8.19076 15.0158 8.15068L13.3271 6.45961C13.287 6.41948 13.2394 6.38764 13.187 6.36591C13.1346 6.34419 13.0785 6.33301 13.0217 6.33301C12.965 6.33301 12.9089 6.34419 12.8565 6.36591C12.8041 6.38764 12.7565 6.41948 12.7164 6.45961L11.8831 7.29295L14.1825 9.59473Z"
				fill="#737283"
			/>
			<path
				d="M11.3251 7.85107L7.34535 11.8293C7.29928 11.8758 7.26818 11.935 7.25607 11.9993L6.81649 14.2758C6.80609 14.3285 6.80884 14.383 6.82448 14.4344C6.84012 14.4858 6.86819 14.5326 6.90619 14.5706C6.94419 14.6086 6.99097 14.6367 7.04239 14.6523C7.0938 14.668 7.14828 14.6707 7.20101 14.6603L9.4769 14.2201C9.5412 14.208 9.60039 14.1769 9.64684 14.1308L13.6266 10.1511L11.3251 7.85107Z"
				fill="#737283"
			/>
		</svg>
	);
}
