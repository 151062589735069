import BigNumber from 'bignumber.js';

export const getEnv = (
	variable: string,
	defaultValue?: number | string
): any => {
	return process.env[variable] || defaultValue;
};

export const formatCurrency = (
	value: number | string | undefined,
	decimal?: number
) => {
	if (!value) return '0';
	const result = new BigNumber(value).toNumber();
	return new Intl.NumberFormat('en', {
		maximumFractionDigits: decimal || 4,
	}).format(result);
};

export const convertStatusToCapitalized = (status: string): string => {
	if (!status) return '';
	const newText = status.split('_').join(' ');
	return newText.charAt(0).toUpperCase() + newText.slice(1).toLowerCase();
};

export const validateInputNumber = (value: string) => {
	const regexDecimal = /^\d*\.?\d+$/;
	const regexInteger = /^[0-9]+$/;

	if (
		value.match(regexDecimal) ||
		(value.slice(0, -1).match(regexInteger) &&
			value.substring(value.length - 1, value.length) === '.') ||
		value === ''
	) {
		return true;
	}

	return false;
};
