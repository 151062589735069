import { ModalBody, ModalContent, ModalHeader } from '@chakra-ui/react';
import styled from '@emotion/styled';
import TextGradient from '../../UI/TextGradient';

export const StyledModalHeader = styled(ModalHeader)`
	background-color: #091229;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	letter-spacing: 0.2px;
	color: #d1d4dc;
`;
export const StyledModalContent = styled(ModalContent)`
	background-color: #070c1e;
	@media screen and (max-width: 567px) {
		width: 100%;
		height: 100%;
	}
`;
export const StyledModalBody = styled(ModalBody)`
	display: flex;
	flex-direction: column;
	gap: 30px;
	max-height: 80vh;
	overflow-y: auto;
	padding: 30px;

	@media screen and (max-width: 567px) {
		width: 100%;
		max-height: calc(100vh - 53px);
		padding: 10px;
	}
`;
export const TransferSection = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 20px;
`;

export const LeftColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	flex: 1;
`;
export const RightColumn = styled(LeftColumn)``;
export const Label = styled.div`
	font-size: 16px;
	line-height: 16px;
	color: #737283;
`;
export const Balance = styled.div`
	font-size: 14px;
	line-height: 16px;
	color: #737283;
`;
export const TokenSection = styled(TransferSection)`
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
`;
export const AmountWrapper = styled.div`
	position: relative;
	width: 100%;
`;
export const AmountInput = styled.input`
	background: rgba(255, 255, 255, 0.05);
	border: 1px solid rgba(255, 255, 255, 0.08);
	border-radius: 4px;
	width: 100%;
	height: 48px;
	padding: 0 16px 0 10px;
	font-size: 16px;
	line-height: 16px;
	color: #737283;
	&:focus {
		outline: none;
	}
`;

export const StyledTextGradient = styled(TextGradient)`
	cursor: pointer;
	position: absolute;
	top: 12px;
	right: 14px;
`;
export const Description = styled(Balance)``;

export const StyledForm = styled.form``;
export const ErrorMessage = styled.div`
	font-size: 12px;
	line-height: 12px;
	color: #cf304a;
`;

export const TransferIconWrapper = styled.div`
	margin-top: 40px;
	cursor: pointer;
	&:hover {
		filter: brightness(1.2);
	}
`;
