import { Box, Flex, Text } from '@chakra-ui/react';
import { fromUnixTime } from 'date-fns';
import format from 'date-fns/format';
import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import React, { memo, useEffect, useState } from 'react';
import { formatTransactionState } from '../../../lib';
import TableUI from '../../UI/TableUI';
import { Status } from './WithDrawHistory';
import { convertStatusToCapitalized, formatCurrency } from '../../../utils';

const columns = [
	{
		key: 'coin',
		title: 'Coin',
	},
	{
		key: 'time',
		title: 'Time',
	},
	{
		key: 'type',
		title: 'Type',
		render: (value: string) => (
			<Text textTransform={'capitalize'}>
				{convertStatusToCapitalized(value)}
			</Text>
		),
	},
	{
		key: 'amount',
		title: 'Amount',
		render: (amount: string) => <>{formatCurrency(amount)}</>,
	},
	{
		key: 'fromWallet',
		title: 'From Wallet',
	},
	{
		key: 'toWallet',
		title: 'To Wallet',
	},
	{
		key: 'status',
		title: 'Status',
		isNumeric: true,
		render: (status: TransactionState) => (
			<Status status={status}>{formatTransactionState(status)}</Status>
		),
	},
] as Columns[];

interface DataTable {
	[key: string]: any;
	coin: string;
	time: string;
	amount: string;
	type: string;
	status: string;
}

const OtherHistory = ({ transactions }: { transactions: Transaction[] }) => {
	const [dataTable, setDataTable] = useState<DataTable[]>([]);
	useEffect(() => {
		const newData = transactions.map((item) => ({
			coin: item.assetSymbol || '',
			time: item.createdAt
				? format(fromUnixTime(item.createdAt), 'yyyy-MM-dd HH:mm a')
				: '',
			amount: item.assetAmount || '',
			status: item.state || '',
			type: item.type || '',
			fromWallet: item.fromChain || '',
			toWallet: item.toChain || '',
		}));
		setDataTable(newData);
	}, [transactions]);

	return (
		<>
			<Box display={{ base: 'none', md: 'block' }}>
				<TableUI isBorder={true} columns={columns} dataTable={dataTable} />
			</Box>

			<Box display={{ base: 'block', md: 'none' }} w={'100%'} overflow={'auto'}>
				{dataTable.map((data, index) => {
					return (
						<Flex
							justifyContent={'space-between'}
							key={index}
							borderTop={'1px solid #FFFFFF1E'}
							p={'20px'}
						>
							<Box>
								{columns.slice(0, 3).map((col) => (
									<Flex key={col.key} alignItems={'center'} mb={'10px'}>
										<>
											<Box w={'76px'} color={'#737283'} fontSize={'12px'}>
												{col.title}:{' '}
											</Box>
											<Box fontSize={'14px'} w={'90px'}>
												{(col.render && col.render(data[col.key])) ||
													data[col.key]}
											</Box>
										</>
									</Flex>
								))}
							</Box>
							<Box>
								{columns.slice(3, columns.length).map((col) => (
									<Flex key={col.key} mb={'10px'}>
										<Box w={'90px'} color={'#737283'} fontSize={'12px'}>
											{col.title}:
										</Box>
										<Box fontSize={'14px'} w={'90px'}>
											{(col.render && col.render(data[col.key])) ||
												data[col.key]}
										</Box>
									</Flex>
								))}
							</Box>
						</Flex>
					);
				})}
			</Box>
		</>
	);
};

export default memo(OtherHistory);
