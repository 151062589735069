import { Button } from '@chakra-ui/react';
import React from 'react';

interface PropsType {
	w?:
		| {
				base: string | number;
				lg?: string | number;
		  }
		| string
		| number;

	h?:
		| {
				base: string | number;
				lg?: string | number;
		  }
		| string
		| number;
	fontSize?:
		| {
				base: string | number;
				lg?: string | number;
		  }
		| string
		| number;
	maxW?:
		| {
				base: string | number;
				lg?: string | number;
		  }
		| string
		| number;
	isBuy?: boolean;
	children: React.ReactNode;
	type?: 'button' | 'submit' | 'reset';
	onClick?: () => void;
}

export default function BuySellButton({
	w = { base: '148px' },
	h = { base: '38px' },
	fontSize = '16px',
	children,
	isBuy = true,
	type = 'button',
	...props
}: PropsType) {
	return (
		<Button
			fontSize={fontSize}
			w={w}
			h={h}
			lineHeight={'24px'}
			bg={isBuy ? '#03A66D' : '#CF304A'}
			fontWeight={400}
			color={'white'}
			type={type}
			variant={'outline'}
			{...props}
		>
			{children}
		</Button>
	);
}
