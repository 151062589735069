import React, { useState, useEffect } from 'react';
import {
	Box,
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { setModal } from '../../../redux/reducers/system.slice';
import DepositHistory from './DepositHistory';
import TabsUI from '../../UI/TabsUI';
import DropdownIcon from '../../Icons/DropdownIcon';
import WithdrawHistory from './WithDrawHistory';
import OtherHistory from './OtherHistory';
import MetaverseClient from 'metaverse-js';
import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import NotFound from '../../NotFound/NotFound';
import { Asset } from 'metaverse-js/lib/proto/model/asset';
import Pagination from '../../UI/Pagination';
import { TransactionQueryRequest } from 'metaverse-js/lib/proto/service/transaction';

const paginationDefault = {
	page: 1,
	pageSize: 10,
};

export default function TradeHistoryModal() {
	const tabs = ['Deposit History', 'Withdraw History', 'Other History'];
	const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);
	const [typeTransaction, setTypeTransaction] = useState<TransactionType>();
	const [isLoading, setIsLoading] = useState(true);
	const [assetSelected, setAssetSelected] = useState<Asset>();
	const [transactions, setTransactions] = useState<Transaction[]>([]);
	const [total, setTotal] = useState(0);
	const [paging, setPaging] = useState(paginationDefault);

	const { metaverseJs, modal, supportedAssets, chain } = useAppSelector(
		(state) => ({
			metaverseJs: state.system.metaverseJs,
			modal: state.system.modal,
			supportedAssets: state.system.supportedAssets,
			chain: state.system.chain,
		})
	);
	const dispatch = useAppDispatch();

	const handleClose = () => {
		dispatch(setModal(null));
	};

	const handleSelectedTab = (tab: string) => {
		setSelectedTab(tab);
		switch (tab) {
			case tabs[0]:
				setTypeTransaction('DEPOSIT');
				setPaging(paginationDefault);
				break;
			case tabs[1]:
				setTypeTransaction('WITHDRAW');
				setPaging(paginationDefault);
				break;
			case tabs[2]:
				setTypeTransaction('INTERNAL_TRANSFER');
				setPaging(paginationDefault);
				break;
			default:
				break;
		}
	};

	const getTransactions = async (
		metaverseJs: MetaverseClient,
		query: TransactionQueryRequest
	) => {
		setIsLoading(false);
		try {
			const res = await metaverseJs?.getMyTransactions({
				...query,
			});
			setIsLoading(true);
			if (res && res.meta) {
				setTotal(res.meta.total);
			}

			if (res && res['data']) {
				setTransactions(res['data']);
			}
		} catch (error) {
			setIsLoading(true);
			console.log(error);
		}
	};

	useEffect(() => {
		const query = {
			type: typeTransaction,
			chain,
			assetSymbol: assetSelected?.symbol,
			limit: paging.pageSize,
			offset: paging.pageSize * paging.page - paging.pageSize,
		} as TransactionQueryRequest;
		if (metaverseJs && modal === 'tradeHistory' && typeTransaction && chain) {
			getTransactions(metaverseJs, query);
		}
	}, [metaverseJs, modal, typeTransaction, chain, assetSelected, paging]);

	return (
		<>
			<Modal onClose={handleClose} isOpen={modal === 'tradeHistory'} isCentered>
				<ModalOverlay />

				<ModalContent
					h={{ base: '100vh', md: 'auto' }}
					minW={{ base: 'auto', md: '1250px' }}
				>
					<ModalHeader
						bg={'#091229'}
						fontSize={'18px'}
						position={'sticky'}
						top={0}
					>
						Trade History
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody bg={'#070C1E'} p={{ base: '8px', md: '20px' }}>
						<Flex
							alignItems={{ base: 'start', md: 'center' }}
							mt={'30px'}
							flexDir={{ base: 'column', md: 'row' }}
						>
							<TabsUI
								tabs={tabs}
								selectedTab={selectedTab}
								onSelectedTab={handleSelectedTab}
							/>
							<Box pb={'20px'}>
								<Menu>
									{({ isOpen }) => (
										<>
											<MenuButton
												isActive={isOpen}
												as={Button}
												rightIcon={<DropdownIcon />}
												color={'#737283'}
												border={'1px solid rgba(255, 255, 255, 0.08)'}
												bg={'transparent'}
											>
												{assetSelected?.name || 'All coins'}
											</MenuButton>
											<MenuList>
												{supportedAssets.map((asset: Asset) => (
													<MenuItem
														key={asset.id}
														onClick={() => setAssetSelected(asset)}
													>
														{asset.name}
													</MenuItem>
												))}
											</MenuList>
										</>
									)}
								</Menu>
							</Box>
						</Flex>
						<Flex
							flexDir={'column'}
							justifyContent={'space-between'}
							h={{ base: 'auto', md: '70vh' }}
							overflow={'auto'}
						>
							{selectedTab === tabs[0] &&
								(transactions.length > 0 ? (
									isLoading ? (
										<DepositHistory transactions={transactions} />
									) : (
										<Loading />
									)
								) : (
									<NotFound />
								))}

							{selectedTab === tabs[1] &&
								(transactions.length > 0 ? (
									isLoading ? (
										<WithdrawHistory transactions={transactions} />
									) : (
										<Loading />
									)
								) : (
									<NotFound />
								))}

							{selectedTab === tabs[2] &&
								(transactions.length > 0 ? (
									isLoading ? (
										<OtherHistory transactions={transactions} />
									) : (
										<Loading />
									)
								) : (
									<NotFound />
								))}
							<Flex justifyContent={'center'} my={'10px'}>
								<Pagination
									page={paging.page}
									pagination={{
										total,
										pageSize: paging.pageSize,
									}}
									handleChangePage={(page) => {
										setPaging({ ...paging, page });
									}}
								/>
							</Flex>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

const Loading = () => (
	<Button
		h={'100%'}
		isLoading
		colorScheme="white"
		border={'transparent'}
		variant={'outline'}
	>
		Click me
	</Button>
);
