import { Box, BoxProps, Spinner } from '@chakra-ui/react';
interface Props extends BoxProps {
	w?: string;
	h?: string;
}
const LoadingUI = ({ w = '100%', h = '100%', ...props }: Props) => (
	<Box
		{...props}
		w={w}
		h={h}
		position={'absolute'}
		display={'flex'}
		alignItems={'center'}
		justifyContent={'center'}
		zIndex={1030}
	>
		<Spinner />
	</Box>
);

export default LoadingUI;
