import { NotFoundTitle, NotFoundWrapper, StyledImage } from './styles';

interface NotFoundProps extends React.HTMLAttributes<HTMLDivElement> {
	children?: React.ReactNode;
	text?: string;
}

const NotFound: React.FC<NotFoundProps> = ({
	children,
	text = 'No Data...',
}: NotFoundProps) => {
	return (
		<NotFoundWrapper>
			<StyledImage src="/images/icons/not-found.svg" alt="Not Found" />
			{text && <NotFoundTitle>{text}</NotFoundTitle>}
			{children}
		</NotFoundWrapper>
	);
};

export default NotFound;
