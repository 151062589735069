import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import { fromUnixTime } from 'date-fns';
import format from 'date-fns/format';
import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import { memo, useEffect, useState } from 'react';
import { formatTransactionState } from '../../../lib';
import { STATE_TRANSACTION } from '../../../lib/constants';
import { shortenAddress } from '../../../lib/shortenAddress';
import TableUI from '../../UI/TableUI';
import { Status } from './WithDrawHistory';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../../../utils';
import { SCAN_URL_CONFIG } from '../../../config';
import CopyIcon from '../../Icons/CopyIcon';

interface DataTable {
	[key: string]: any;
	currency: string;
	time: string;
	amount: string;
	deposit_address: string;
	blockchain_transaction_id: string;
	status: string;
	onchainHash: string;
}

const DepositHistory = ({ transactions }: { transactions: Transaction[] }) => {
	const [dataTable, setDataTable] = useState<DataTable[]>([]);
	const toast = useToast();

	const columns = [
		{
			key: 'currency',
			title: 'Currency',
		},
		{
			key: 'chain',
			title: 'Network',
		},
		{
			key: 'time',
			title: 'Time',
		},
		{
			key: 'amount',
			title: 'Amount',
			render: (amount: string) => <>{formatCurrency(amount)}</>,
		},
		{
			key: 'deposit_address',
			title: 'Deposit Address',
			render: (deposit_address: string) => (
				<Flex alignItems={'center'} gap={'10px'}>
					{shortenAddress(deposit_address)}
					<CopyIcon
						style={{ cursor: 'pointer' }}
						onClick={() => handleCopy(deposit_address)}
					/>
				</Flex>
			),
		},
		{
			key: 'fromWallet',
			title: 'From Wallet',
		},
		{
			key: 'toWallet',
			title: 'To Wallet',
		},
		{
			key: 'onchainHash',
			title: 'Blockchain Transaction Hash',
			render: (onchainHash: string, record: any) => (
				<>
					{onchainHash && (
						<Link
							style={{ color: 'blue' }}
							to={`${SCAN_URL_CONFIG[record.chain]}${onchainHash}`}
							target="_blank"
						>
							{shortenAddress(onchainHash)}
						</Link>
					)}
				</>
			),
		},
		{
			key: 'status',
			title: 'Status',
			isNumeric: true,
			render: (status: TransactionState) => (
				<Status status={status}>{formatTransactionState(status)}</Status>
			),
		},
	] as Columns[];

	useEffect(() => {
		const newData = transactions.map((item) => ({
			currency: item.assetSymbol || '',
			time: item.createdAt
				? format(fromUnixTime(item.createdAt), 'yyyy-MM-dd HH:mm a')
				: '',
			amount: item.assetAmount || '',
			deposit_address: item.fromAccount || '',
			blockchain_transaction_id: shortenAddress(item.id) || '',
			status: item.state || '',
			onchainHash: item.onchainHash || '',
			fromWallet: item.fromChain || '',
			toWallet: item.toChain || '',
			chain: item.fromChain || '',
		}));
		setDataTable(newData);
	}, [transactions]);

	const handleCopy = (text: string | undefined) => {
		if (!text) return;
		navigator.clipboard.writeText(text);
		toast({
			title: 'Copied!',
			status: 'info',
			duration: 2000,
			isClosable: true,
			position: 'top',
		});
	};
	return (
		<>
			<Box display={{ base: 'none', md: 'block' }}>
				<TableUI isBorder={true} columns={columns} dataTable={dataTable} />
			</Box>

			<Box display={{ base: 'block', md: 'none' }} w={'100%'} overflow={'auto'}>
				{dataTable.map((data, index) => {
					return (
						<Flex
							justifyContent={'space-between'}
							key={index}
							borderTop={'1px solid #FFFFFF1E'}
							p={'20px'}
						>
							<Box>
								{columns.slice(0, 4).map((col) => (
									<Flex key={col.key} alignItems={'center'} mb={'10px'}>
										<>
											<Box w={'76px'} color={'#737283'} fontSize={'12px'}>
												{col.title}:{' '}
											</Box>
											<Box fontSize={'14px'} w={'90px'}>
												{data[col.key]}
											</Box>
										</>
									</Flex>
								))}
							</Box>
							<Box>
								{columns.slice(4, columns.length).map((col) => (
									<Flex key={col.key} mb={'10px'} alignItems={'flex-start'}>
										<Box
											w={'70px'}
											color={'#737283'}
											fontSize={'12px'}
											wordBreak={'break-word'}
										>
											{col.title}:
										</Box>
										<Box fontSize={'14px'} w={'90px'}>
											{col.key === 'status' ? (
												<Text
													color={`${
														(STATE_TRANSACTION[data[col.key]] &&
															STATE_TRANSACTION[data[col.key]].color) ||
														''
													}`}
												>
													{(STATE_TRANSACTION[data[col.key]] &&
														STATE_TRANSACTION[data[col.key]].title) ||
														''}
												</Text>
											) : col.key === 'onchainHash' ? (
												<Link
													style={{ color: 'blue' }}
													to={`${SCAN_URL_CONFIG[data.chain || '']}${
														data[col.key]
													}`}
													target="_blank"
												>
													{shortenAddress(data[col.key])}
												</Link>
											) : col.key === 'deposit_address' ? (
												<Flex gap={'10px'} flexWrap={'wrap'}>
													<span style={{ flex: 1 }}>
														{shortenAddress(data[col.key])}{' '}
													</span>
													<CopyIcon
														style={{ cursor: 'pointer' }}
														onClick={() => handleCopy(data[col.key])}
													/>
												</Flex>
											) : (
												data[col.key]
											)}
										</Box>
									</Flex>
								))}
							</Box>
						</Flex>
					);
				})}
			</Box>
		</>
	);
};

export default memo(DepositHistory);
