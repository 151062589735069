//shorten address
export const shortenAddress = (address: string) => {
	return `${address.slice(0, 4)}...${address.slice(-4)}`;
};

export const validateTronAddress = async (address: string) => {
	try {
		const res = fetch('https://api.shasta.trongrid.io/wallet/validateaddress', {
			method: 'POST',
			body: JSON.stringify({ address, visible: true }),
		});
		return (await res).json();
	} catch (error: any) {
		console.log('validateTronAddress', error);
	}
};
