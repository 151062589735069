import { Box, Flex, Show } from '@chakra-ui/react';
import styled from '@emotion/styled';
import OutlineButton from '../UI/OutlineButton';
import { setChain, setModal } from '../../redux/reducers/system.slice';
import { useAppDispatch } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import { TRANSLATION } from '../../constants';

const Wrapper = styled(Box)`
	height: 210px;
	width: auto;
	background: url('/images/wallets/banner.svg') no-repeat center center;
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	display: flex;
	align-items: center;
	position: relative;
	padding: 40px;
	@media screen and (max-width: 567px) {
		padding: 20px;
		height: fit-content;
		background-size: auto 100%;
	}
`;
const Image = styled.img`
	position: absolute;
	right: 5%;
	@media screen and (max-width: 567px) {
		position: relative;
		height: 74px;
		top: 0;
		right: 0;
	}
`;
const ContentWrapper = styled(Box)`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
`;
const Overview = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 14px;
	@media screen and (max-width: 567px) {
		gap: 7px;
	}
`;
const Title = styled(Box)`
	display: flex;
	font-weight: 500;
	font-size: 20px;
	line-height: 1;
	color: #ffffff;
	gap: 10px;
	@media screen and (max-width: 567px) {
		font-size: 16px;
	}
`;
const Valuation = styled(Box)`
	font-size: 18px;
	line-height: 1;
	color: #737283;
	@media screen and (max-width: 567px) {
		font-size: 14px;
	}
`;
const Balance = styled(Box)``;

const PNL = styled(Box)`
	color: #737283;
	font-size: 14px;
	line-height: 1;
`;
const PNLValue = styled.span`
	color: #ffffff;
	font-size: 14px;
	line-height: 1;
`;

const ActionGroup = styled(Box)`
	display: flex;
	align-items: center;
	margin-left: 50px;
	flex-wrap: wrap;
	gap: 16px;
	@media screen and (max-width: 567px) {
		gap: 4px;
		margin-left: 0;
		margin-top: 20px;
	}
`;
const RightColumnWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 16px;
	@media screen and (max-width: 567px) {
		gap: 8px;
		margin-left: 0;
	}
`;

interface BannerProps {
	title?: string;
	valuation?: Valuation;
	actions: BannerAction[];
	isPayment?: boolean;
	yesterdayPNL?: number | string;
	chain?: Chain;
}

const Banner: React.FC<BannerProps> = ({
	title = '',
	valuation,
	actions = [],
	isPayment = false,
	yesterdayPNL,
	chain = 'SPOT',
}: BannerProps) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation(TRANSLATION, {
		keyPrefix: 'Wallet',
	});

	const openModal = (modal: Modal) => {
		dispatch(setModal(modal));
	};

	const renderActions = (actions: BannerAction[]) => {
		dispatch(setChain(chain));
		const result: React.ReactNode[] = [];
		actions.forEach((action) => {
			if (action === 'deposit') {
				result.push(
					<OutlineButton key={action} onClick={() => openModal('deposit')}>
						{t('Deposit')}
					</OutlineButton>
				);
			}
			if (action === 'withdraw') {
				result.push(
					<OutlineButton key={action} onClick={() => openModal('withdraw')}>
						{t('Withdraw')}
					</OutlineButton>
				);
			}
			if (action === 'transfer') {
				result.push(
					<OutlineButton key={action} onClick={() => openModal('transfer')}>
						{t('Transfer')}
					</OutlineButton>
				);
			}
			if (action === 'trade-history') {
				result.push(
					<OutlineButton key={action} onClick={() => openModal('tradeHistory')}>
						{t('Trade History')}
					</OutlineButton>
				);
			}
		});
		return result;
	};

	return (
		<Wrapper>
			<Show above={'lg'}>
				<Image src="/images/wallets/wallet.svg" />
			</Show>
			<ContentWrapper>
				<Flex justifyContent={'space-between'} w={{ base: '100%', lg: 'auto' }}>
					<Overview>
						<Title>
							{t(title)}
							{/* <ShowIcon /> */}
						</Title>
						{valuation && (
							<>
								<Valuation>{t('Valuation')}</Valuation>
								<Balance>
									{valuation?.balance} {valuation?.currency}{' '}
									{/* <ValueUSD>≈ 0.00USD</ValueUSD> */}
								</Balance>
							</>
						)}
						{yesterdayPNL && (
							<PNL>
								{t("Yesterday's")} PNL: <PNLValue>{yesterdayPNL}</PNLValue>{' '}
								{'>'}
							</PNL>
						)}
					</Overview>

					<Show below="md">
						<Image src="/images/wallets/wallet.svg" />
					</Show>
				</Flex>
				<RightColumnWrapper>
					{<ActionGroup>{renderActions(actions)}</ActionGroup>}
				</RightColumnWrapper>
			</ContentWrapper>
		</Wrapper>
	);
};

export default Banner;
