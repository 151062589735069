export default function AccountIcon(props: any) {
	return (
		<svg
			{...props}
			width={16}
			height={16}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_398_6881)">
				<path
					d="M8 8.5C7.40666 8.5 6.82664 8.32405 6.33329 7.99441C5.83994 7.66477 5.45543 7.19623 5.22836 6.64805C5.0013 6.09987 4.94189 5.49667 5.05765 4.91473C5.1734 4.33279 5.45912 3.79824 5.87868 3.37868C6.29824 2.95912 6.83279 2.6734 7.41473 2.55765C7.99667 2.44189 8.59987 2.5013 9.14805 2.72836C9.69623 2.95543 10.1648 3.33994 10.4944 3.83329C10.8241 4.32664 11 4.90666 11 5.5C11 6.29565 10.6839 7.05871 10.1213 7.62132C9.55871 8.18393 8.79565 8.5 8 8.5ZM8 3.5C7.60444 3.5 7.21776 3.6173 6.88886 3.83706C6.55996 4.05682 6.30362 4.36918 6.15224 4.73463C6.00087 5.10009 5.96126 5.50222 6.03843 5.89018C6.1156 6.27814 6.30608 6.63451 6.58579 6.91422C6.86549 7.19392 7.22186 7.3844 7.60982 7.46157C7.99778 7.53874 8.39992 7.49914 8.76537 7.34776C9.13082 7.19639 9.44318 6.94004 9.66294 6.61114C9.8827 6.28224 10 5.89556 10 5.5C10 4.96957 9.78929 4.46086 9.41421 4.08579C9.03914 3.71072 8.53043 3.5 8 3.5Z"
					fill="url(#paint0_linear_398_6881)"
				/>
				<path
					d="M8.00001 15.5C6.89123 15.4993 5.7964 15.2527 4.79436 14.778C3.79231 14.3034 2.90797 13.6125 2.20501 12.755L1.94501 12.435L2.20501 12.12C2.90857 11.2637 3.79317 10.5739 4.79518 10.1003C5.79719 9.62673 6.89171 9.3811 8.00001 9.3811C9.1083 9.3811 10.2028 9.62673 11.2048 10.1003C12.2068 10.5739 13.0914 11.2637 13.795 12.12L14.055 12.435L13.795 12.755C13.092 13.6125 12.2077 14.3034 11.2057 14.778C10.2036 15.2527 9.10879 15.4993 8.00001 15.5ZM3.25501 12.44C3.86313 13.091 4.59857 13.6101 5.41569 13.965C6.23281 14.3198 7.11415 14.503 8.00501 14.503C8.89586 14.503 9.7772 14.3198 10.5943 13.965C11.4114 13.6101 12.1469 13.091 12.755 12.44C12.1469 11.789 11.4114 11.27 10.5943 10.9151C9.7772 10.5602 8.89586 10.3771 8.00501 10.3771C7.11415 10.3771 6.23281 10.5602 5.41569 10.9151C4.59857 11.27 3.86313 11.789 3.25501 12.44Z"
					fill="url(#paint1_linear_398_6881)"
				/>
				<path
					d="M8 15.5C6.29637 15.5011 4.64312 14.9222 3.31235 13.8586C1.98157 12.7949 1.05258 11.3099 0.678269 9.64785C0.30396 7.98586 0.506638 6.24594 1.25295 4.71448C1.99926 3.18302 3.24472 1.95127 4.78436 1.22197C6.32399 0.492673 8.06604 0.309278 9.72379 0.701974C11.3815 1.09467 12.8562 2.04005 13.9051 3.38253C14.9539 4.725 15.5145 6.38457 15.4945 8.08808C15.4745 9.79159 14.8751 11.4375 13.795 12.755C13.092 13.6125 12.2077 14.3034 11.2057 14.778C10.2036 15.2527 9.10878 15.4993 8 15.5ZM8 1.50001C6.71442 1.50001 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.37409 8.00722 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29972 14.4972 10.4874 14.0052C11.6752 13.5133 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8.00001C14.5 6.2761 13.8152 4.6228 12.5962 3.40381C11.3772 2.18482 9.72391 1.50001 8 1.50001Z"
					fill="url(#paint2_linear_398_6881)"
				/>
				<path
					d="M2.59 12.44C2.59 12.44 7.625 18.065 12.75 13L13.41 12.44C13.41 12.44 9.13 8 4.785 10.665L2.59 12.44Z"
					fill="url(#paint3_linear_398_6881)"
				/>
				<path
					d="M8 8C9.38071 8 10.5 6.88071 10.5 5.5C10.5 4.11929 9.38071 3 8 3C6.61929 3 5.5 4.11929 5.5 5.5C5.5 6.88071 6.61929 8 8 8Z"
					fill="url(#paint4_linear_398_6881)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_398_6881"
					x1={5}
					y1="8.5"
					x2={11}
					y2="8.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFBF1A" />
					<stop offset={1} stopColor="#FCD535" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_398_6881"
					x1="1.94501"
					y1="15.5"
					x2="14.055"
					y2="15.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFBF1A" />
					<stop offset={1} stopColor="#FCD535" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_398_6881"
					x1="0.494995"
					y1="15.5"
					x2="15.495"
					y2="15.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFBF1A" />
					<stop offset={1} stopColor="#FCD535" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_398_6881"
					x1="2.59"
					y1="15.1146"
					x2="13.41"
					y2="15.1146"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFBF1A" />
					<stop offset={1} stopColor="#FCD535" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_398_6881"
					x1="5.5"
					y1={8}
					x2="10.5"
					y2={8}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFBF1A" />
					<stop offset={1} stopColor="#FCD535" />
				</linearGradient>
				<clipPath id="clip0_398_6881">
					<rect width={16} height={16} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
