import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import SelectCustom from '../UI/SelectCustom';
import {
	AdOrderAssetSymbol,
	AdOrderFiatSymbol,
	AdOrderType,
	QueryAdOrder,
} from 'skychain-p2p-trading-js/lib/dto';
import { convertOrderType } from '../../utils/ads';

const Label = styled(Text)`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #737283;
	margin-bottom: 10px;
`;

const cryptos = [
	{ key: '', value: 'All Cryptos' },
	...Object.values(AdOrderAssetSymbol).map((item) => ({
		key: item,
		value: item,
	})),
];
const types = [
	{ key: '', value: 'All Types' },
	...Object.values(AdOrderType).map((item) => ({
		key: item,
		value: convertOrderType(item),
	})),
];
const fiatSymbols = [
	{ key: '', value: 'All Fiats' },
	...Object.values(AdOrderFiatSymbol).map((item) => ({
		key: item,
		value: item,
	})),
];
interface Props {
	query?: QueryAdOrder;
	setQuery: (query: QueryAdOrder) => void;
}

export default function Filter({ setQuery, query }: Props) {
	return (
		<Flex
			w={{ base: '100%' }}
			justifyContent={{ base: 'flex-start' }}
			gap={{ base: '10px', lg: '30px' }}
			py={{ base: '20px' }}
			wrap={'wrap'}
		>
			<Box
				w={{ base: 'calc(100% / 4 - 10px)', lg: 'calc(100% / 4 - 20px)' }}
				minW={{ base: '100px', lg: 'auto' }}
			>
				<Label>Cryptos</Label>
				<SelectCustom
					value={
						cryptos.find((item) => item.key === query?.assetSymbol) ||
						cryptos[0]
					}
					list={cryptos}
					onSelect={(option) => {
						const newQuery = Object.assign({}, query);
						if (option.key === '') {
							delete newQuery.assetSymbol;
						} else {
							newQuery.assetSymbol = option.key as string;
						}
						setQuery(newQuery);
					}}
				/>
			</Box>

			<Box
				w={{ base: 'calc(100% / 4 - 10px)', lg: 'calc(100% / 4 - 20px)' }}
				minW={{ base: '100px', lg: 'auto' }}
			>
				<Label>Ad Types</Label>
				<SelectCustom
					value={types.find((item) => item.key === query?.type) || types[0]}
					list={types}
					onSelect={(option) => {
						const newQuery = Object.assign({}, query);
						if (option.key === '') {
							delete newQuery.type;
						} else {
							newQuery.type = option.key as AdOrderType;
						}
						setQuery(newQuery);
					}}
				/>
			</Box>

			<Box
				w={{ base: 'calc(100% / 4 - 10px)', lg: 'calc(100% / 4 - 20px)' }}
				minW={{ base: '100px', lg: 'auto' }}
			>
				<Label>Fiat</Label>
				<SelectCustom
					value={
						fiatSymbols.find((item) => item.key === query?.fiatSymbol) ||
						fiatSymbols[0]
					}
					list={fiatSymbols}
					onSelect={(option) => {
						const newQuery = Object.assign({}, query);
						if (option.key === '') {
							delete newQuery.fiatSymbol;
						} else {
							newQuery.fiatSymbol = option.key as string;
						}
						setQuery(newQuery);
					}}
				/>
			</Box>
		</Flex>
	);
}
