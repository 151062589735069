import {
	Box,
	Flex,
	Img,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverTrigger,
	Select,
	Show,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import PrimaryButton from '../../components/UI/PrimaryButton';
import TransparentButton from '../../components/UI/TransparentButton';
import MobileMenu from './MobileMenu';
import {
	LinkActive,
	LinkItem,
	LogoutBtn,
	NavCenterWrapper,
	NavWrapper,
	Profile,
} from './styles';
import { ROUTES } from '../../routes/config';
import { useLocation, useNavigate } from 'react-router-dom';
import { forwardRef, MouseEventHandler, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccountIcon from '../../components/Icons/AccountIcon';
import { logout } from '../../redux/reducers/auth.slice';
import { LANGUAGES, TELEGRAM_SUPPORT_URL } from '../../constants';
import { useTranslation } from 'react-i18next';
import { resetUser } from '../../redux/reducers/user.slice';

function TopNav(props: any, ref: React.LegacyRef<HTMLDivElement>) {
	const userAccount = useSelector((state: any) => state.auth.account);
	const [language, setLanguage] = useState<LANGUAGES>(
		localStorage.getItem('language') === 'kr'
			? LANGUAGES.KOREAN
			: localStorage.getItem('language') === 'vi'
			? LANGUAGES.VIETNAMESE
			: localStorage.getItem('language') === 'en'
			? LANGUAGES.ENGLISH
			: LANGUAGES.KOREAN
	);
	const { t, i18n } = useTranslation();

	const links = [
		{ title: t('home'), link: ROUTES.HOME },
		{ title: t('buySellCrypto'), link: ROUTES.P2P_BUY_SELL },
		// {
		// 	title: 'BetMeta',
		// 	link: METAGAME_URL,
		// 	externalLink: true,
		// },
		{
			title: t('telegramSupport'),
			link: TELEGRAM_SUPPORT_URL,
			externalLink: true,
		},
		// { title: 'FAQs', link: '' },
	];
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const handleLogout = () => {
		navigate(ROUTES.LOGIN);
		dispatch(logout());
		dispatch(resetUser());
	};
	const languageChangeHandler = (e: any) => {
		const target = e.target as HTMLSelectElement;
		const selectedLang = target.value as LANGUAGES;
		setLanguage(selectedLang);
		let lang = 'kr';

		switch (selectedLang) {
			case LANGUAGES.ENGLISH:
				lang = 'en';
				break;
			case LANGUAGES.VIETNAMESE:
				lang = 'vi';
				break;
			case LANGUAGES.KOREAN:
				lang = 'kr';
				break;
		}
		i18n.changeLanguage(lang);
		localStorage.setItem('language', lang);
	};

	return (
		<Box
			position={{ base: 'fixed' }}
			zIndex={100}
			background={'#050713'}
			width={'100%'}
			top={{ base: 0 }}
		>
			<NavWrapper padding={{ base: '16px 18px', lg: '16px 20px' }} ref={ref}>
				<Flex gap={10} alignItems={'center'} justifyContent={'space-between'}>
					<Box w="210px">
						<Img
							src="/images/logo.svg"
							alt="logo"
							onClick={() => navigate('/')}
							cursor={'pointer'}
						/>
					</Box>

					<Show above="lg">
						<NavCenterWrapper
							alignItems={'center'}
							justifyContent={'space-between'}
						>
							<Flex alignItems={'center'} gap={'40px'}>
								{links.map((link) => {
									if (link.externalLink) {
										return (
											<div
												style={{ cursor: 'pointer' }}
												key={link.title}
												onClick={() => window.open(link.link)}
											>
												{link.title}
											</div>
										);
									}
									if (link.link === location.pathname)
										return (
											<LinkActive key={link.title}>{link.title}</LinkActive>
										);
									return (
										<LinkItem
											key={link.title}
											onClick={() => {
												navigate(link.link);
												// setLinkActive(link.title);
											}}
										>
											{link.title}
										</LinkItem>
									);
								})}
							</Flex>

							<Flex gap={5}>
								{!userAccount ? (
									<>
										<Link to={'/login'}>
											<TransparentButton>{t('login')}</TransparentButton>
										</Link>
										<Link to={'/register'}>
											<PrimaryButton>{t('signup')}</PrimaryButton>
										</Link>
									</>
								) : (
									<>
										<LinkItem
											active={location.pathname.includes(ROUTES.WALLET.MAIN)}
											onClick={() => {
												navigate(ROUTES.WALLET.ASSET_OVERVIEW);
											}}
										>
											{t('balances')}
										</LinkItem>
										<LinkItem
											onClick={() => {
												navigate(ROUTES.P2P_ORDERS);
											}}
											active={location.pathname.includes(ROUTES.P2P_ORDERS)}
										>
											{t('order')}
										</LinkItem>
										<Popover trigger="hover">
											<PopoverTrigger>
												<Profile>
													<AccountIcon />
												</Profile>
											</PopoverTrigger>
											<PopoverContent>
												<PopoverArrow />
												<PopoverCloseButton />
												<PopoverBody>
													<LogoutBtn
														onClick={() =>
															navigate(ROUTES.ACCOUNT.ACCOUNT_LIST)
														}
													>
														Profile
													</LogoutBtn>
													<LogoutBtn onClick={handleLogout}>Logout</LogoutBtn>
												</PopoverBody>
											</PopoverContent>
										</Popover>
									</>
								)}
								{/*<Box*/}
								{/*	display={'flex'}*/}
								{/*	alignItems={'center'}*/}
								{/*	cursor={'pointer'}*/}
								{/*	onClick={() => {*/}
								{/*		setLanguage(*/}
								{/*			language === LANGUAGES.ENGLISH*/}
								{/*				? LANGUAGES.VIETNAMESE*/}
								{/*				: LANGUAGES.ENGLISH*/}
								{/*		);*/}
								{/*		const lang = language === LANGUAGES.ENGLISH ? 'vi' : 'en';*/}
								{/*		i18n.changeLanguage(lang);*/}
								{/*		localStorage.setItem('language', lang);*/}
								{/*	}}*/}
								{/*>*/}
								{/*	{language}*/}
								{/*</Box>*/}
								<Select onChange={(e) => languageChangeHandler(e)}>
									<option
										selected={language === LANGUAGES.ENGLISH}
										value={LANGUAGES.ENGLISH}
									>
										{LANGUAGES.ENGLISH}
									</option>
									<option
										selected={language === LANGUAGES.VIETNAMESE}
										value={LANGUAGES.VIETNAMESE}
									>
										{LANGUAGES.VIETNAMESE}
									</option>
									<option
										selected={language === LANGUAGES.KOREAN}
										value={LANGUAGES.KOREAN}
									>
										{LANGUAGES.KOREAN}
									</option>
								</Select>
							</Flex>
						</NavCenterWrapper>
					</Show>

					<Show below="lg">
						<MobileMenu mainMenu />
					</Show>
				</Flex>
			</NavWrapper>
		</Box>
	);
}

export default forwardRef(TopNav);
