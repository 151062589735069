import {
	Box,
	Drawer,
	DrawerContent,
	DrawerOverlay,
	useDisclosure,
	Flex,
	chakra,
	Button,
	Text,
	Avatar,
	Select,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import MenuIcon from '../../components/Icons/MenuIcon';
import styled from '@emotion/styled';
import { LANGUAGES, METAGAME_URL, TELEGRAM_SUPPORT_URL } from '../../constants';
import CloseCircleIcon from '../../components/Icons/CloseCircleIcon';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/config';
import SingleArrowRight from '../../components/Icons/SingleArrowRight';
import HomeIcon from '../../components/Icons/HomeIcon';
import BuySellCryptoIcon from '../../components/Icons/BuySellCryptoIcon';
import WalletIcon from '../../components/Icons/WalletIcon';
import TelegramIcon from '../../components/Icons/TelegramIcon';
import { logout } from '../../redux/reducers/auth.slice';
import { useTranslation } from 'react-i18next';
import { resetUser } from '../../redux/reducers/user.slice';

const StyledDrawerContent = styled(DrawerContent)`
	background: #0b0c22;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
const Header = styled(Box)`
	background-image: url('/images/bg-slide.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;
const ButtonAuth = chakra(Button, {
	baseStyle: {
		background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
		borderRadius: '36px',
		py: '14px',
		fontSize: '16px',
		color: 'black',
		fontWeight: 500,
		w: { base: '100%', md: '384px' },
		_hover: {
			background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
		},
	},
});
const MenuItem = styled(Box)<{ isActive?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
	padding: 0 30px 0 50px;
	background-color: ${(props) => (props.isActive ? '#091229' : 'transparent')};
	color: ${(props) => (props.isActive ? '#FFFFFF' : '#737283')};
	> svg {
		path: {
			fill: ${(props) => (props.isActive ? '#FFFFFF' : '#737283')};
		}
	}
`;

const ButtonLogin = chakra(Button, {
	baseStyle: {
		background: 'transparent',
		borderRadius: '36px',
		border: '1px solid #FFBF1A',
		py: '14px',
		fontSize: '16px',
		color: '#FCD535 ',
		fontWeight: 500,
		w: { base: '100%', md: '384px' },
		_hover: {
			// background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
		},
	},
});
const SecondaryText = chakra(Text, {
	baseStyle: {
		fontSize: 14,
		color: '#737283',
	},
});

const PrimaryText = chakra(Text, {
	baseStyle: {
		fontSize: 16,
		color: '#FFFFFF',
	},
});
interface PropsType {
	mainMenu?: boolean;
}
export default function MobileMenu({ mainMenu }: PropsType) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef<any>();
	const [language, setLanguage] = useState<LANGUAGES>(
		localStorage.getItem('language') === 'kr'
			? LANGUAGES.KOREAN
			: localStorage.getItem('language') === 'vi'
			? LANGUAGES.VIETNAMESE
			: localStorage.getItem('language') === 'en'
			? LANGUAGES.ENGLISH
			: LANGUAGES.KOREAN
	);
	const { t, i18n } = useTranslation();
	const account = useSelector((state: RootState) => state.auth.account);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const handleLogout = () => {
		navigate(ROUTES.LOGIN);
		dispatch(logout());
		dispatch(resetUser());
		onClose();
	};

	const languageChangeHandler = (e: any) => {
		const target = e.target as HTMLSelectElement;
		const selectedLang = target.value as LANGUAGES;
		setLanguage(selectedLang);
		let lang = 'kr';

		switch (selectedLang) {
			case LANGUAGES.ENGLISH:
				lang = 'en';
				break;
			case LANGUAGES.VIETNAMESE:
				lang = 'vi';
				break;
			case LANGUAGES.KOREAN:
				lang = 'kr';
				break;
		}
		i18n.changeLanguage(lang);
		localStorage.setItem('language', lang);
	};

	return (
		<>
			<Box
				px="0px"
				width={'max-content'}
				cursor={'pointer'}
				display={'flex'}
				alignItems={'center'}
				gap={'10px'}
			>
				{/* {mainMenu && (
					<Flex
						alignItems={'center'}
						w={'fit-content'}
						h={'30px'}
						gap={'2px'}
						mr={'10px'}
						onClick={() => window.open(METAGAME_URL)}
					>
						<img
							src="/images/bet-meta.png"
							style={{
								objectFit: 'contain',
								width: '100%',
								height: '100%',
							}}
						/>
						Betmeta
					</Flex>
				)} */}
				<Box ref={btnRef} onClick={onOpen}>
					<MenuIcon />
				</Box>
			</Box>
			<Drawer
				isOpen={isOpen}
				placement="right"
				onClose={onClose}
				finalFocusRef={btnRef}
				size={'full'}
			>
				<DrawerOverlay />
				<StyledDrawerContent>
					<Box>
						<Header>
							<Box width={'100%'} padding={{ base: '16px 18px' }}>
								<Flex
									gap={10}
									alignItems={'center'}
									justifyContent={'space-between'}
								>
									<Box w="210px">
										<img src="/images/logo.svg" alt="logo" />
									</Box>
									<Box
										px="0px"
										width={'max-content'}
										cursor={'pointer'}
										display={'flex'}
										alignItems={'center'}
										gap={'10px'}
									>
										{/* <Box
											style={{ width: 'fit-content' }}
											onClick={() => window.open(METAGAME_URL)}
										>
											<MetagameIcon />
										</Box> */}
										<Box ref={btnRef} onClick={onClose}>
											<CloseCircleIcon />
										</Box>
									</Box>
								</Flex>
							</Box>
							{!(account && account.id) ? (
								<Flex direction={'column'} padding={50} gap={'20px'}>
									<ButtonLogin
										onClick={() => {
											navigate(ROUTES.LOGIN);
											onClose();
										}}
									>
										{t('login')}
									</ButtonLogin>
									<ButtonAuth
										onClick={() => {
											navigate(ROUTES.REGISTER);
											onClose();
										}}
									>
										<Box>{t('signupWithEmailOrPhone')}</Box>
									</ButtonAuth>
								</Flex>
							) : (
								<Link to={ROUTES.ACCOUNT.ACCOUNT_LIST}>
									<Flex
										padding={'30px'}
										gap={'10px'}
										alignItems={'center'}
										justifyContent={'space-between'}
										onClick={onClose}
									>
										<Flex gap={'10px'} alignItems={'center'}>
											<Avatar
												style={{
													width: '48px',
													height: '48px',
													borderRadius: '100%',
												}}
												src="/images/account-list/personal-avatar.png"
											/>
											<Flex
												style={{ flex: 1 }}
												direction={'column'}
												gap={'10px'}
											>
												<PrimaryText>{account?.email}</PrimaryText>
												<SecondaryText>ID: {account?.id} </SecondaryText>
											</Flex>
										</Flex>
										<SingleArrowRight />
									</Flex>
								</Link>
							)}
						</Header>
						<Flex direction={'column'} background={'#0B0C22'} pb={'50px'}>
							<Link to={ROUTES.HOME} onClick={onClose}>
								<MenuItem isActive={location.pathname === ROUTES.HOME}>
									<Flex alignItems={'center'} gap={'18px'}>
										<HomeIcon
											color={
												location.pathname === ROUTES.HOME ? 'white' : '#737283'
											}
										/>{' '}
										{t('home')}
									</Flex>
									<SingleArrowRight
										color={
											location.pathname === ROUTES.HOME ? 'white' : '#737283'
										}
									/>
								</MenuItem>
							</Link>
							<Link to={ROUTES.P2P_BUY_SELL} onClick={onClose}>
								<MenuItem isActive={location.pathname.includes(ROUTES.P2P)}>
									<Flex alignItems={'center'} gap={'18px'}>
										<BuySellCryptoIcon
											color={
												location.pathname.includes(ROUTES.P2P)
													? 'white'
													: '#737283'
											}
										/>
										{t('buySellCrypto')}
									</Flex>
									<SingleArrowRight
										color={
											location.pathname.includes(ROUTES.P2P)
												? 'white'
												: '#737283'
										}
									/>
								</MenuItem>
							</Link>
							{account && account.id && (
								<Link to={ROUTES.WALLET.ASSET_OVERVIEW} onClick={onClose}>
									<MenuItem
										isActive={location.pathname.includes(ROUTES.WALLET.MAIN)}
									>
										<Flex alignItems={'center'} gap={'18px'}>
											<WalletIcon
												color={
													location.pathname.includes(ROUTES.WALLET.MAIN)
														? 'white'
														: '#B1B2B2'
												}
											/>
											{t('balances')}
										</Flex>
										<SingleArrowRight
											color={
												location.pathname.includes(ROUTES.WALLET.MAIN)
													? 'white'
													: '#737283'
											}
										/>
									</MenuItem>
								</Link>
							)}
							<MenuItem onClick={() => window.open(TELEGRAM_SUPPORT_URL)}>
								<Flex alignItems={'center'} gap={'18px'}>
									<TelegramIcon />
									{t('telegramSupport')}
								</Flex>
								<SingleArrowRight
									color={
										location.pathname.includes(ROUTES.WALLET.MAIN)
											? 'white'
											: '#737283'
									}
								/>
							</MenuItem>

							{/* <Link to={ROUTES.HOME} onClick={onClose}>
							<MenuItem>
								<Flex alignItems={'center'} gap={'18px'}>
									<FAQIcon />
									FAQs
								</Flex>
								<SingleArrowRight />
							</MenuItem>
						</Link> */}
							<Box padding={'0 50px'}>
								<Flex
									alignItems={'center'}
									gap={'20px'}
									borderTop={'1px solid rgba(255, 255, 255, 0.024)'}
									borderBottom={'1px solid rgba(255, 255, 255, 0.024)'}
									height={'64px'}
									display={'flex'}
									justifyContent={'center'}
									color={'#B1B2B2'}
								>
									<Flex alignItems={'center'} gap={2}>
										<Select onChange={(e) => languageChangeHandler(e)}>
											<option
												selected={language === LANGUAGES.ENGLISH}
												value={LANGUAGES.ENGLISH}
											>
												{LANGUAGES.ENGLISH}
											</option>
											<option
												selected={language === LANGUAGES.VIETNAMESE}
												value={LANGUAGES.VIETNAMESE}
											>
												{LANGUAGES.VIETNAMESE}
											</option>
											<option
												selected={language === LANGUAGES.KOREAN}
												value={LANGUAGES.KOREAN}
											>
												{LANGUAGES.KOREAN}
											</option>
										</Select>
									</Flex>
								</Flex>
							</Box>
							<Box p={'20px'} w={'100%'}>
								{account && (
									<Flex
										onClick={handleLogout}
										alignItems={'center'}
										w={'100%'}
										justifyContent={'center'}
										color={'#B1B2B2'}
									>
										{t('logout')}
									</Flex>
								)}
							</Box>
						</Flex>
					</Box>
				</StyledDrawerContent>
			</Drawer>
		</>
	);
}
